import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Tabheader from "../Component/Tabheader";
import { Link } from "react-router-dom";
import flag from "../../src/assets/image/flag.png";
import ball from "../../src/assets/image/cricket-ball.png";
import latestnews from "../../src/assets/image/latestnews.png";
import latestnews2 from "../../src/assets/image/latestnews2.png";
import video1 from "../../src/assets/image/video1.png";
import playername from "../../src/assets/image/Ellipse 2.png";
import dateFormat from "dateformat";
import OwlCarousel from "react-owl-carousel";
import axios from "axios";
import Rightnewssection from "./Rightnewssection";
export default function Cricketlivescore() {
  const [usersvideo, setUservideo] = useState([]);
  const [UserNews, setUserNews] = useState([]);
  const [users, setUsers] = useState([]);
  const [user, setUsers1] = useState([]);
  const [usersrank, setUsersrank] = useState([]);
  useEffect(() => {
    playGame();
    playGame2();
    playGameMatch();
  }, []);

  const playGame = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/video-link`
      );
      const result = response.data.data;
      setUservideo(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getVideoId = (url) => {
    const match = url.match(
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    return match && match[1];
  };

  const getThumbnailUrl = (url) => {
    const videoId = getVideoId(url);
    return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : "";
  };
  const playGame2 = async () => {
    try {
      const response = await axios(
        // (`${process.env.REACT_APP_API_URL}/home.php`)
        `${process.env.REACT_APP_API_URL}/news-list`,
        {}
      );

      // console.warn(response.data.dtat);
      const res = response.data.data;
      setUserNews(res);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const playGame2www = async () => {
    try {
      const response = await axios(
        // (`${process.env.REACT_APP_API_URL}/home.php`)
        `${process.env.REACT_APP_API_URL}/news-list`,
        {}
      );

      // console.warn(response.data.dtat);
      const res = response.data.data;
      setUserNews(res);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getNewsId = (url) => {
    const match = url.match(
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    return match && match[1];
  };
  const getThumbnailUrlnews = (url) => {
    const videoId = getNewsId(url);
    return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : "";
  };

  const playGameMatch = async () => {
    try {
      const response = await axios.post(
        `https://apis.sportstiger.com/Prod/home-matches-config`,
        {}
      );
      const result = response.data.result.series;
      const results = response.data.result.matches;
      const l_name = response.data.result.series.map(
        (series) => series.l_sname
      );

      // console.log(result);
      // console.warn(results);
      // console.log(l_name);
      setUsers(result);
      setUsers1(results);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  return (
    <>
      <Tabheader />
      <section className="cricketlivescore" id="cricketlivescore">
        <div className="cricketscore">
          <Container>
            <div className="d-flex border-bottom-custom">
              <ul className="d-flex matcheslist">
                <li>Matches</li>
                <li>Current and Future Fixtures</li>
                <li>Matches By Day</li>
                <li>Series Archive</li>
              </ul>
            </div>
            <div className="Sectab">
              <ul>
                <li className="activetab">
                  <Link to="/">Cricket</Link>
                </li>
                <li>
                  <Link to="/">Football</Link>
                </li>
                <li>
                  <Link to="/">Kabaddi</Link>
                </li>
              </ul>
            </div>
            <div className="d-flex border-bottom-custom">
              <ul className="d-flex matcheslist">
                <li>All</li>
                <li>International</li>
                <li>T20 League</li>
                <li>Domestic</li>
              </ul>
            </div>
          </Container>
        </div>
        <div>
          <Container>
            <Row>
              <Col lg={12} md={12}>
              <div className="tabsnew">
                    <ul>
                      <li className="activetabnew">Live</li>
                      <li>Upcoming</li>
                      <li>Result</li>
                    </ul>
                  </div>
              </Col>
              <Col md={8}>
                <div className="leftside">
                  
                  <div className="matchtwo">
                    <div className="haedingview d-flex justify-content-between">
                      <h3>CSA 4-Day Series Division 2</h3>
                      <button className="viewall">View All</button>
                    </div>
                    <Row>
                      {user.slice(1, 3).map((set, setIndex) => (
                        <Col md={6}>
                          <div className="card">
                            <div className="cardlive">
                              <div className="matchlist">
                                <div className="d-flex justify-content-start">
                                  <h4>
                                    {" "}
                                    <span
                                      className={
                                        set.status === "live" ? "livered" : ""
                                      }
                                    ></span>
                                    <span
                                      className="mx-1"
                                      style={{
                                        color:
                                          set.status === "live"
                                            ? "red"
                                            : set.status === "upcoming"
                                            ? "green"
                                            : set.status === "completed"
                                            ? "blue"
                                            : "black",
                                      }}
                                    >
                                      {set.status.toUpperCase()}
                                    </span>
                                  </h4>
                                  <div className="matchdetails">
                                    <h6>{set.l_name.toUpperCase()}</h6>
                                    {/* <h6>Border vs Mpumalanga, 6th Match</h6> */}
                                    <p className="dateandtime">
                                      {/* Thursday, 16 Nov | 01:30 PM */}
                                      {new Date(set.strt_time_ts * 1000).toLocaleDateString("en-US", {
                                            weekday: "long",
                                            day: "numeric",
                                            month: "short",
                                          })}{" "}
                                          |{" "}
                                          {new Date(set.strt_time_ts * 1000).toLocaleTimeString("en-US", {
                                            hour: "2-digit",
                                            minute: "2-digit",
                                          })}
                                    </p>
                                  </div>
                                </div>

                                <div className="matchone">
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex matchgame">
                                      <div className="counteryflag mr-2">
                                        <img
                                          src={set.t1_flag}
                                          className="img-fluid"
                                          style={{
                                            height: "30px",
                                            width: "30px",
                                            borderRadius: "50%",
                                          }}
                                        />
                                      </div>
                                      <p>{set.t1_sname}</p>
                                    </div>
                                    <div className="over">
                                      <p>
                                        <strong>{set.t1_1fscr}</strong>
                                        <strong className="mx-1">
                                          {set.t1_2fscr}
                                        </strong>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="matchone">
                                  <div className="d-flex justify-content-between ">
                                    <div className="d-flex matchgame ">
                                      <div className="counteryflag mr-2 mt-3">
                                        <img
                                          src={set.t2_flag}
                                          className="img-fluid"
                                          style={{
                                            height: "30px",
                                            width: "30px",
                                            borderRadius: "50%",
                                          }}
                                        />
                                      </div>
                                      <p className=" mt-3">{set.t2_sname}</p>
                                    </div>
                                    <div className="over mt-3">
                                      <p>
                                        <p>
                                          <strong className="">
                                            {set.t2_1fscr}
                                          </strong>

                                          <strong className="mx-1 ">
                                            {set.t2_2fscr}
                                          </strong>
                                        </p>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex margin-top-10">
                                  <div className="details">
                                    <small>Breack</small>
                                  </div>
                                </div>
                              </div>
                              <div className="bgdashboard d-flex justify-content-between">
                              <button className="buttonnew">
                                <Link to="/Llclivescrore" className="text-secondary">
                                  LIVE UPDATES
                                
                                </Link>
                                </button>
                                <button className="buttonnew">
                                  
                                  <Link to="/Llclivescrore" className="text-secondary">
                                  SCOREBOARD
                                
                                </Link>
                                </button>
                              </div>
                            </div>
                          </div>

                          {/* </Col>
                                            <Col md={6}> */}
                          {/* <div className="card">
                            <div className="cardlive">
                              <div className="matchlist">
                                <div className="d-flex justify-content-start">
                                  <h4>
                                    <span className="livered"></span>Live
                                  </h4>
                                  <div className="matchdetails">
                                    <h6>Border vs Mpumalanga, 6th Match</h6>
                                    <h6>Border vs Mpumalanga, 6th Match</h6>
                                    <p className="dateandtime">
                                      Thursday, 16 Nov | 01:30 PM
                                    </p>
                                  </div>
                                </div>

                                <div className="matchone">
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex matchgame">
                                      <div className="counteryflag mr-2">
                                        <img src={flag} className="img-fluid" />
                                      </div>
                                      <p>Bangladesh</p>
                                    </div>
                                    <div className="over">
                                      <p>
                                        <strong>169/9</strong>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="matchone">
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex matchgame">
                                      <div className="counteryflag mr-2">
                                        <img src={flag} className="img-fluid" />
                                      </div>
                                      <p>Bangladesh</p>
                                    </div>
                                    <div className="over">
                                      <p>
                                        <strong>169/9</strong>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex margin-top-10">
                                  <div className="details">
                                    <small>Breack</small>
                                  </div>
                                </div>
                              </div>
                              <div className="bgdashboard d-flex justify-content-between">
                                <button className="buttonnew">
                                  LIVE UPDATES
                                </button>
                                <button className="buttonnew">
                                  SCOREBOARD
                                </button>
                              </div>
                            </div>
                          </div> */}
                        </Col>
                      ))}
                    </Row>
                  </div>
                  <div className="matchtwo">
                    <div className="haedingview d-flex justify-content-between">
                      <h3>CSA 4-Day Series Division 2</h3>
                      <button className="viewall">View All</button>
                    </div>
                    <Row>
                      {user.slice(1, 3).map((set, setIndex) => (
                        <Col md={6}>
                          <div className="card">
                            <div className="cardlive">
                              <div className="matchlist">
                                <div className="d-flex justify-content-start">
                                  <h4>
                                    {" "}
                                    <span
                                      className={
                                        set.status === "live" ? "livered" : ""
                                      }
                                    ></span>
                                    <span
                                      className="mx-1"
                                      style={{
                                        color:
                                          set.status === "live"
                                            ? "red"
                                            : set.status === "upcoming"
                                            ? "green"
                                            : set.status === "completed"
                                            ? "blue"
                                            : "black",
                                      }}
                                    >
                                      {set.status.toUpperCase()}
                                    </span>
                                  </h4>
                                  <div className="matchdetails">
                                    <h6>{set.l_name.toUpperCase()}</h6>
                                    {/* <h6>Border vs Mpumalanga, 6th Match</h6> */}
                                    <p className="dateandtime">
                                      {/* Thursday, 16 Nov | 01:30 PM */}
                                      {new Date(set.strt_time_ts * 1000).toLocaleDateString("en-US", {
                                            weekday: "long",
                                            day: "numeric",
                                            month: "short",
                                          })}{" "}
                                          |{" "}
                                          {new Date(set.strt_time_ts * 1000).toLocaleTimeString("en-US", {
                                            hour: "2-digit",
                                            minute: "2-digit",
                                          })}
                                    </p>
                                  </div>
                                </div>

                                <div className="matchone">
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex matchgame">
                                      <div className="counteryflag mr-2">
                                        <img
                                          src={set.t1_flag}
                                          className="img-fluid"
                                          style={{
                                            height: "30px",
                                            width: "30px",
                                            borderRadius: "50%",
                                          }}
                                        />
                                      </div>
                                      <p>{set.t1_sname}</p>
                                    </div>
                                    <div className="over">
                                      <p>
                                        <strong>{set.t1_1fscr}</strong>
                                        <strong className="mx-1">
                                          {set.t1_2fscr}
                                        </strong>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="matchone">
                                  <div className="d-flex justify-content-between ">
                                    <div className="d-flex matchgame ">
                                      <div className="counteryflag mr-2 mt-3">
                                        <img
                                          src={set.t2_flag}
                                          className="img-fluid"
                                          style={{
                                            height: "30px",
                                            width: "30px",
                                            borderRadius: "50%",
                                          }}
                                        />
                                      </div>
                                      <p className=" mt-3">{set.t2_sname}</p>
                                    </div>
                                    <div className="over mt-3">
                                      <p>
                                        <p>
                                          <strong className="">
                                            {set.t2_1fscr}
                                          </strong>

                                          <strong className="mx-1 ">
                                            {set.t2_2fscr}
                                          </strong>
                                        </p>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex margin-top-10">
                                  <div className="details">
                                    <small>Breack</small>
                                  </div>
                                </div>
                              </div>
                              <div className="bgdashboard d-flex justify-content-between">
                              <button className="buttonnew">
                                <Link to="/Llclivescrore" className="text-secondary">
                                  LIVE UPDATES
                                
                                </Link>
                                </button>
                                <button className="buttonnew">
                                  
                                  <Link to="/Llclivescrore" className="text-secondary">
                                  SCOREBOARD
                                
                                </Link>
                                </button>
                              </div>
                            </div>
                          </div>

                          {/* </Col>
                                            <Col md={6}> */}
                          {/* <div className="card">
                            <div className="cardlive">
                              <div className="matchlist">
                                <div className="d-flex justify-content-start">
                                  <h4>
                                    <span className="livered"></span>Live
                                  </h4>
                                  <div className="matchdetails">
                                    <h6>Border vs Mpumalanga, 6th Match</h6>
                                    <h6>Border vs Mpumalanga, 6th Match</h6>
                                    <p className="dateandtime">
                                      Thursday, 16 Nov | 01:30 PM
                                    </p>
                                  </div>
                                </div>

                                <div className="matchone">
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex matchgame">
                                      <div className="counteryflag mr-2">
                                        <img src={flag} className="img-fluid" />
                                      </div>
                                      <p>Bangladesh</p>
                                    </div>
                                    <div className="over">
                                      <p>
                                        <strong>169/9</strong>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="matchone">
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex matchgame">
                                      <div className="counteryflag mr-2">
                                        <img src={flag} className="img-fluid" />
                                      </div>
                                      <p>Bangladesh</p>
                                    </div>
                                    <div className="over">
                                      <p>
                                        <strong>169/9</strong>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex margin-top-10">
                                  <div className="details">
                                    <small>Breack</small>
                                  </div>
                                </div>
                              </div>
                              <div className="bgdashboard d-flex justify-content-between">
                                <button className="buttonnew">
                                  LIVE UPDATES
                                </button>
                                <button className="buttonnew">
                                  SCOREBOARD
                                </button>
                              </div>
                            </div>
                          </div> */}
                        </Col>
                      ))}
                    </Row>
                  </div>
                  <div className="matchtwo">
                    <div className="haedingview d-flex justify-content-between">
                      <h3>CSA 4-Day Series Division 2</h3>
                      <button className="viewall">View All</button>
                    </div>
                    <Row>
                      {user.slice(1, 3).map((set, setIndex) => (
                        <Col md={6}>
                          <div className="card">
                            <div className="cardlive">
                              <div className="matchlist">
                                <div className="d-flex justify-content-start">
                                  <h4>
                                    {" "}
                                    <span
                                      className={
                                        set.status === "live" ? "livered" : ""
                                      }
                                    ></span>
                                    <span
                                      className="mx-1"
                                      style={{
                                        color:
                                          set.status === "live"
                                            ? "red"
                                            : set.status === "upcoming"
                                            ? "green"
                                            : set.status === "completed"
                                            ? "blue"
                                            : "black",
                                      }}
                                    >
                                      {set.status.toUpperCase()}
                                    </span>
                                  </h4>
                                  <div className="matchdetails">
                                    <h6>{set.l_name.toUpperCase()}</h6>
                                    {/* <h6>Border vs Mpumalanga, 6th Match</h6> */}
                                    <p className="dateandtime">
                                      {/* Thursday, 16 Nov | 01:30 PM */}
                                      {new Date(set.strt_time_ts * 1000).toLocaleDateString("en-US", {
                                            weekday: "long",
                                            day: "numeric",
                                            month: "short",
                                          })}{" "}
                                          |{" "}
                                          {new Date(set.strt_time_ts * 1000).toLocaleTimeString("en-US", {
                                            hour: "2-digit",
                                            minute: "2-digit",
                                          })}
                                    </p>
                                  </div>
                                </div>

                                <div className="matchone">
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex matchgame">
                                      <div className="counteryflag mr-2">
                                        <img
                                          src={set.t1_flag}
                                          className="img-fluid"
                                          style={{
                                            height: "30px",
                                            width: "30px",
                                            borderRadius: "50%",
                                          }}
                                        />
                                      </div>
                                      <p>{set.t1_sname}</p>
                                    </div>
                                    <div className="over">
                                      <p>
                                        <strong>{set.t1_1fscr}</strong>
                                        <strong className="mx-1">
                                          {set.t1_2fscr}
                                        </strong>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="matchone">
                                  <div className="d-flex justify-content-between ">
                                    <div className="d-flex matchgame ">
                                      <div className="counteryflag mr-2 mt-3">
                                        <img
                                          src={set.t2_flag}
                                          className="img-fluid"
                                          style={{
                                            height: "30px",
                                            width: "30px",
                                            borderRadius: "50%",
                                          }}
                                        />
                                      </div>
                                      <p className=" mt-3">{set.t2_sname}</p>
                                    </div>
                                    <div className="over mt-3">
                                      <p>
                                        <p>
                                          <strong className="">
                                            {set.t2_1fscr}
                                          </strong>

                                          <strong className="mx-1 ">
                                            {set.t2_2fscr}
                                          </strong>
                                        </p>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex margin-top-10">
                                  <div className="details">
                                    <small>Breack</small>
                                  </div>
                                </div>
                              </div>
                              <div className="bgdashboard d-flex justify-content-between">
                              <button className="buttonnew">
                                <Link to="/Llclivescrore" className="text-secondary">
                                  LIVE UPDATES
                                
                                </Link>
                                </button>
                                <button className="buttonnew">
                                  
                                  <Link to="/Llclivescrore" className="text-secondary">
                                  SCOREBOARD
                                
                                </Link>
                                </button>
                              </div>
                            </div>
                          </div>

                          {/* </Col>
                                            <Col md={6}> */}
                          {/* <div className="card">
                            <div className="cardlive">
                              <div className="matchlist">
                                <div className="d-flex justify-content-start">
                                  <h4>
                                    <span className="livered"></span>Live
                                  </h4>
                                  <div className="matchdetails">
                                    <h6>Border vs Mpumalanga, 6th Match</h6>
                                    <h6>Border vs Mpumalanga, 6th Match</h6>
                                    <p className="dateandtime">
                                      Thursday, 16 Nov | 01:30 PM
                                    </p>
                                  </div>
                                </div>

                                <div className="matchone">
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex matchgame">
                                      <div className="counteryflag mr-2">
                                        <img src={flag} className="img-fluid" />
                                      </div>
                                      <p>Bangladesh</p>
                                    </div>
                                    <div className="over">
                                      <p>
                                        <strong>169/9</strong>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="matchone">
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex matchgame">
                                      <div className="counteryflag mr-2">
                                        <img src={flag} className="img-fluid" />
                                      </div>
                                      <p>Bangladesh</p>
                                    </div>
                                    <div className="over">
                                      <p>
                                        <strong>169/9</strong>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex margin-top-10">
                                  <div className="details">
                                    <small>Breack</small>
                                  </div>
                                </div>
                              </div>
                              <div className="bgdashboard d-flex justify-content-between">
                                <button className="buttonnew">
                                  LIVE UPDATES
                                </button>
                                <button className="buttonnew">
                                  SCOREBOARD
                                </button>
                              </div>
                            </div>
                          </div> */}
                        </Col>
                      ))}
                    </Row>
                  </div>
                  <div className="matchtwo">
                    <div className="haedingview d-flex justify-content-between">
                      <h3>CSA 4-Day Series Division 2</h3>
                      <button className="viewall">View All</button>
                    </div>
                    <Row>
                      {user.slice(1, 3).map((set, setIndex) => (
                        <Col md={6}>
                          <div className="card">
                            <div className="cardlive">
                              <div className="matchlist">
                                <div className="d-flex justify-content-start">
                                  <h4>
                                    {" "}
                                    <span
                                      className={
                                        set.status === "live" ? "livered" : ""
                                      }
                                    ></span>
                                    <span
                                      className="mx-1"
                                      style={{
                                        color:
                                          set.status === "live"
                                            ? "red"
                                            : set.status === "upcoming"
                                            ? "green"
                                            : set.status === "completed"
                                            ? "blue"
                                            : "black",
                                      }}
                                    >
                                      {set.status.toUpperCase()}
                                    </span>
                                  </h4>
                                  <div className="matchdetails">
                                    <h6>{set.l_name.toUpperCase()}</h6>
                                    {/* <h6>Border vs Mpumalanga, 6th Match</h6> */}
                                    <p className="dateandtime">
                                      {/* Thursday, 16 Nov | 01:30 PM */}
                                      {new Date(set.strt_time_ts * 1000).toLocaleDateString("en-US", {
                                            weekday: "long",
                                            day: "numeric",
                                            month: "short",
                                          })}{" "}
                                          |{" "}
                                          {new Date(set.strt_time_ts * 1000).toLocaleTimeString("en-US", {
                                            hour: "2-digit",
                                            minute: "2-digit",
                                          })}
                                    </p>
                                  </div>
                                </div>

                                <div className="matchone">
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex matchgame">
                                      <div className="counteryflag mr-2">
                                        <img
                                          src={set.t1_flag}
                                          className="img-fluid"
                                          style={{
                                            height: "30px",
                                            width: "30px",
                                            borderRadius: "50%",
                                          }}
                                        />
                                      </div>
                                      <p>{set.t1_sname}</p>
                                    </div>
                                    <div className="over">
                                      <p>
                                        <strong>{set.t1_1fscr}</strong>
                                        <strong className="mx-1">
                                          {set.t1_2fscr}
                                        </strong>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="matchone">
                                  <div className="d-flex justify-content-between ">
                                    <div className="d-flex matchgame ">
                                      <div className="counteryflag mr-2 mt-3">
                                        <img
                                          src={set.t2_flag}
                                          className="img-fluid"
                                          style={{
                                            height: "30px",
                                            width: "30px",
                                            borderRadius: "50%",
                                          }}
                                        />
                                      </div>
                                      <p className=" mt-3">{set.t2_sname}</p>
                                    </div>
                                    <div className="over mt-3">
                                      <p>
                                        <p>
                                          <strong className="">
                                            {set.t2_1fscr}
                                          </strong>

                                          <strong className="mx-1 ">
                                            {set.t2_2fscr}
                                          </strong>
                                        </p>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex margin-top-10">
                                  <div className="details">
                                    <small>Breack</small>
                                  </div>
                                </div>
                              </div>
                              <div className="bgdashboard d-flex justify-content-between">
                              <button className="buttonnew">
                                <Link to="/Llclivescrore" className="text-secondary">
                                  LIVE UPDATES
                                
                                </Link>
                                </button>
                                <button className="buttonnew">
                                  
                                  <Link to="/Llclivescrore" className="text-secondary">
                                  SCOREBOARD
                                
                                </Link>
                                </button>
                              </div>
                            </div>
                          </div>

                          {/* </Col>
                                            <Col md={6}> */}
                          {/* <div className="card">
                            <div className="cardlive">
                              <div className="matchlist">
                                <div className="d-flex justify-content-start">
                                  <h4>
                                    <span className="livered"></span>Live
                                  </h4>
                                  <div className="matchdetails">
                                    <h6>Border vs Mpumalanga, 6th Match</h6>
                                    <h6>Border vs Mpumalanga, 6th Match</h6>
                                    <p className="dateandtime">
                                      Thursday, 16 Nov | 01:30 PM
                                    </p>
                                  </div>
                                </div>

                                <div className="matchone">
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex matchgame">
                                      <div className="counteryflag mr-2">
                                        <img src={flag} className="img-fluid" />
                                      </div>
                                      <p>Bangladesh</p>
                                    </div>
                                    <div className="over">
                                      <p>
                                        <strong>169/9</strong>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="matchone">
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex matchgame">
                                      <div className="counteryflag mr-2">
                                        <img src={flag} className="img-fluid" />
                                      </div>
                                      <p>Bangladesh</p>
                                    </div>
                                    <div className="over">
                                      <p>
                                        <strong>169/9</strong>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex margin-top-10">
                                  <div className="details">
                                    <small>Breack</small>
                                  </div>
                                </div>
                              </div>
                              <div className="bgdashboard d-flex justify-content-between">
                                <button className="buttonnew">
                                  LIVE UPDATES
                                </button>
                                <button className="buttonnew">
                                  SCOREBOARD
                                </button>
                              </div>
                            </div>
                          </div> */}
                        </Col>
                      ))}
                    </Row>
                  </div>
                  {/* <div className="matchtwo">
                    <div className="haedingview d-flex justify-content-between">
                      <h3>CSA 4-Day Series Division 2</h3>
                      <button className="viewall">View All</button>
                    </div>
                    <Row>
                      <Col md={6}>
                        <div className="card">
                          <div className="cardlive">
                            <div className="matchlist">
                              <div className="d-flex justify-content-start">
                                <h4>
                                  <span className="livered"></span>Live
                                </h4>
                                <div className="matchdetails">
                                  <h6>Border vs Mpumalanga, 6th Match</h6>
                                  <h6>Border vs Mpumalanga, 6th Match</h6>
                                  <p className="dateandtime">
                                    Thursday, 16 Nov | 01:30 PM
                                  </p>
                                </div>
                              </div>

                              <div className="matchone">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex matchgame">
                                    <div className="counteryflag mr-2">
                                      <img src={flag} className="img-fluid" />
                                    </div>
                                    <p>Bangladesh</p>
                                  </div>
                                  <div className="over">
                                    <p>
                                      <strong>169/9</strong>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="matchone">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex matchgame">
                                    <div className="counteryflag mr-2">
                                      <img src={flag} className="img-fluid" />
                                    </div>
                                    <p>Bangladesh</p>
                                  </div>
                                  <div className="over">
                                    <p>
                                      <strong>169/9</strong>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex margin-top-10">
                                <div className="details">
                                  <small>Breack</small>
                                </div>
                              </div>
                            </div>
                            <div className="bgdashboard d-flex justify-content-between">
                              <button className="buttonnew">
                                LIVE UPDATES
                              </button>
                              <button className="buttonnew">SCOREBOARD</button>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="card">
                          <div className="cardlive">
                            <div className="matchlist">
                              <div className="d-flex justify-content-start">
                                <h4>
                                  <span className="livered"></span>Live
                                </h4>
                                <div className="matchdetails">
                                  <h6>Border vs Mpumalanga, 6th Match</h6>
                                  <h6>Border vs Mpumalanga, 6th Match</h6>
                                  <p className="dateandtime">
                                    Thursday, 16 Nov | 01:30 PM
                                  </p>
                                </div>
                              </div>

                              <div className="matchone">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex matchgame">
                                    <div className="counteryflag mr-2">
                                      <img src={flag} className="img-fluid" />
                                    </div>
                                    <p>Bangladesh</p>
                                  </div>
                                  <div className="over">
                                    <p>
                                      <strong>169/9</strong>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="matchone">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex matchgame">
                                    <div className="counteryflag mr-2">
                                      <img src={flag} className="img-fluid" />
                                    </div>
                                    <p>Bangladesh</p>
                                  </div>
                                  <div className="over">
                                    <p>
                                      <strong>169/9</strong>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex margin-top-10">
                                <div className="details">
                                  <small>Breack</small>
                                </div>
                              </div>
                            </div>
                            <div className="bgdashboard d-flex justify-content-between">
                              <button className="buttonnew">
                                LIVE UPDATES
                              </button>
                              <button className="buttonnew">SCOREBOARD</button>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="matchtwo">
                    <div className="haedingview d-flex justify-content-between">
                      <h3>CSA 4-Day Series Division 2</h3>
                      <button className="viewall">View All</button>
                    </div>
                    <Row>
                      <Col md={6}>
                        <div className="card">
                          <div className="cardlive">
                            <div className="matchlist">
                              <div className="d-flex justify-content-start">
                                <h4>
                                  <span className="livered"></span>Live
                                </h4>
                                <div className="matchdetails">
                                  <h6>Border vs Mpumalanga, 6th Match</h6>
                                  <h6>Border vs Mpumalanga, 6th Match</h6>
                                  <p className="dateandtime">
                                    Thursday, 16 Nov | 01:30 PM
                                  </p>
                                </div>
                              </div>

                              <div className="matchone">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex matchgame">
                                    <div className="counteryflag mr-2">
                                      <img src={flag} className="img-fluid" />
                                    </div>
                                    <p>Bangladesh</p>
                                  </div>
                                  <div className="over">
                                    <p>
                                      <strong>169/9</strong>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="matchone">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex matchgame">
                                    <div className="counteryflag mr-2">
                                      <img src={flag} className="img-fluid" />
                                    </div>
                                    <p>Bangladesh</p>
                                  </div>
                                  <div className="over">
                                    <p>
                                      <strong>169/9</strong>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex margin-top-10">
                                <div className="details">
                                  <small>Breack</small>
                                </div>
                              </div>
                            </div>
                            <div className="bgdashboard d-flex justify-content-between">
                              <button className="buttonnew">
                                LIVE UPDATES
                              </button>
                              <button className="buttonnew">SCOREBOARD</button>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="card">
                          <div className="cardlive">
                            <div className="matchlist">
                              <div className="d-flex justify-content-start">
                                <h4>
                                  <span className="livered"></span>Live
                                </h4>
                                <div className="matchdetails">
                                  <h6>Border vs Mpumalanga, 6th Match</h6>
                                  <h6>Border vs Mpumalanga, 6th Match</h6>
                                  <p className="dateandtime">
                                    Thursday, 16 Nov | 01:30 PM
                                  </p>
                                </div>
                              </div>

                              <div className="matchone">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex matchgame">
                                    <div className="counteryflag mr-2">
                                      <img src={flag} className="img-fluid" />
                                    </div>
                                    <p>Bangladesh</p>
                                  </div>
                                  <div className="over">
                                    <p>
                                      <strong>169/9</strong>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="matchone">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex matchgame">
                                    <div className="counteryflag mr-2">
                                      <img src={flag} className="img-fluid" />
                                    </div>
                                    <p>Bangladesh</p>
                                  </div>
                                  <div className="over">
                                    <p>
                                      <strong>169/9</strong>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex margin-top-10">
                                <div className="details">
                                  <small>Breack</small>
                                </div>
                              </div>
                            </div>
                            <div className="bgdashboard d-flex justify-content-between">
                              <button className="buttonnew">
                                LIVE UPDATES
                              </button>
                              <button className="buttonnew">SCOREBOARD</button>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="matchtwo">
                    <div className="haedingview d-flex justify-content-between">
                      <h3>CSA 4-Day Series Division 2</h3>
                      <button className="viewall">View All</button>
                    </div>
                    <Row>
                      <Col md={6}>
                        <div className="card">
                          <div className="cardlive">
                            <div className="matchlist">
                              <div className="d-flex justify-content-start">
                                <h4>
                                  <span className="livered"></span>Live
                                </h4>
                                <div className="matchdetails">
                                  <h6>Border vs Mpumalanga, 6th Match</h6>
                                  <h6>Border vs Mpumalanga, 6th Match</h6>
                                  <p className="dateandtime">
                                    Thursday, 16 Nov | 01:30 PM
                                  </p>
                                </div>
                              </div>

                              <div className="matchone">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex matchgame">
                                    <div className="counteryflag mr-2">
                                      <img src={flag} className="img-fluid" />
                                    </div>
                                    <p>Bangladesh</p>
                                  </div>
                                  <div className="over">
                                    <p>
                                      <strong>169/9</strong>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="matchone">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex matchgame">
                                    <div className="counteryflag mr-2">
                                      <img src={flag} className="img-fluid" />
                                    </div>
                                    <p>Bangladesh</p>
                                  </div>
                                  <div className="over">
                                    <p>
                                      <strong>169/9</strong>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex margin-top-10">
                                <div className="details">
                                  <small>Breack</small>
                                </div>
                              </div>
                            </div>
                            <div className="bgdashboard d-flex justify-content-between">
                              <button className="buttonnew">
                                LIVE UPDATES
                              </button>
                              <button className="buttonnew">SCOREBOARD</button>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="card">
                          <div className="cardlive">
                            <div className="matchlist">
                              <div className="d-flex justify-content-start">
                                <h4>
                                  <span className="livered"></span>Live
                                </h4>
                                <div className="matchdetails">
                                  <h6>Border vs Mpumalanga, 6th Match</h6>
                                  <h6>Border vs Mpumalanga, 6th Match</h6>
                                  <p className="dateandtime">
                                    Thursday, 16 Nov | 01:30 PM
                                  </p>
                                </div>
                              </div>

                              <div className="matchone">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex matchgame">
                                    <div className="counteryflag mr-2">
                                      <img src={flag} className="img-fluid" />
                                    </div>
                                    <p>Bangladesh</p>
                                  </div>
                                  <div className="over">
                                    <p>
                                      <strong>169/9</strong>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="matchone">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex matchgame">
                                    <div className="counteryflag mr-2">
                                      <img src={flag} className="img-fluid" />
                                    </div>
                                    <p>Bangladesh</p>
                                  </div>
                                  <div className="over">
                                    <p>
                                      <strong>169/9</strong>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex margin-top-10">
                                <div className="details">
                                  <small>Breack</small>
                                </div>
                              </div>
                            </div>
                            <div className="bgdashboard d-flex justify-content-between">
                              <button className="buttonnew">
                                LIVE UPDATES
                              </button>
                              <button className="buttonnew">SCOREBOARD</button>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="matchtwo">
                    <div className="haedingview d-flex justify-content-between">
                      <h3>CSA 4-Day Series Division 2</h3>
                      <button className="viewall">View All</button>
                    </div>
                    <Row>
                      <Col md={6}>
                        <div className="card">
                          <div className="cardlive">
                            <div className="matchlist">
                              <div className="d-flex justify-content-start">
                                <h4>
                                  <span className="livered"></span>Live
                                </h4>
                                <div className="matchdetails">
                                  <h6>Border vs Mpumalanga, 6th Match</h6>
                                  <h6>Border vs Mpumalanga, 6th Match</h6>
                                  <p className="dateandtime">
                                    Thursday, 16 Nov | 01:30 PM
                                  </p>
                                </div>
                              </div>

                              <div className="matchone">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex matchgame">
                                    <div className="counteryflag mr-2">
                                      <img src={flag} className="img-fluid" />
                                    </div>
                                    <p>Bangladesh</p>
                                  </div>
                                  <div className="over">
                                    <p>
                                      <strong>169/9</strong>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="matchone">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex matchgame">
                                    <div className="counteryflag mr-2">
                                      <img src={flag} className="img-fluid" />
                                    </div>
                                    <p>Bangladesh</p>
                                  </div>
                                  <div className="over">
                                    <p>
                                      <strong>169/9</strong>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex margin-top-10">
                                <div className="details">
                                  <small>Breack</small>
                                </div>
                              </div>
                            </div>
                            <div className="bgdashboard d-flex justify-content-between">
                              <button className="buttonnew">
                                LIVE UPDATES
                              </button>
                              <button className="buttonnew">SCOREBOARD</button>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="card">
                          <div className="cardlive">
                            <div className="matchlist">
                              <div className="d-flex justify-content-start">
                                <h4>
                                  <span className="livered"></span>Live
                                </h4>
                                <div className="matchdetails">
                                  <h6>Border vs Mpumalanga, 6th Match</h6>
                                  <h6>Border vs Mpumalanga, 6th Match</h6>
                                  <p className="dateandtime">
                                    Thursday, 16 Nov | 01:30 PM
                                  </p>
                                </div>
                              </div>

                              <div className="matchone">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex matchgame">
                                    <div className="counteryflag mr-2">
                                      <img src={flag} className="img-fluid" />
                                    </div>
                                    <p>Bangladesh</p>
                                  </div>
                                  <div className="over">
                                    <p>
                                      <strong>169/9</strong>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="matchone">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex matchgame">
                                    <div className="counteryflag mr-2">
                                      <img src={flag} className="img-fluid" />
                                    </div>
                                    <p>Bangladesh</p>
                                  </div>
                                  <div className="over">
                                    <p>
                                      <strong>169/9</strong>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex margin-top-10">
                                <div className="details">
                                  <small>Breack</small>
                                </div>
                              </div>
                            </div>
                            <div className="bgdashboard d-flex justify-content-between">
                              <button className="buttonnew">
                                LIVE UPDATES
                              </button>
                              <button className="buttonnew">SCOREBOARD</button>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="matchtwo">
                    <div className="haedingview d-flex justify-content-between">
                      <h3>CSA 4-Day Series Division 2</h3>
                      <button className="viewall">View All</button>
                    </div>
                    <Row>
                      <Col md={6}>
                        <div className="card">
                          <div className="cardlive">
                            <div className="matchlist">
                              <div className="d-flex justify-content-start">
                                <h4>
                                  <span className="livered"></span>Live
                                </h4>
                                <div className="matchdetails">
                                  <h6>Border vs Mpumalanga, 6th Match</h6>
                                  <h6>Border vs Mpumalanga, 6th Match</h6>
                                  <p className="dateandtime">
                                    Thursday, 16 Nov | 01:30 PM
                                  </p>
                                </div>
                              </div>

                              <div className="matchone">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex matchgame">
                                    <div className="counteryflag mr-2">
                                      <img src={flag} className="img-fluid" />
                                    </div>
                                    <p>Bangladesh</p>
                                  </div>
                                  <div className="over">
                                    <p>
                                      <strong>169/9</strong>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="matchone">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex matchgame">
                                    <div className="counteryflag mr-2">
                                      <img src={flag} className="img-fluid" />
                                    </div>
                                    <p>Bangladesh</p>
                                  </div>
                                  <div className="over">
                                    <p>
                                      <strong>169/9</strong>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex margin-top-10">
                                <div className="details">
                                  <small>Breack</small>
                                </div>
                              </div>
                            </div>
                            <div className="bgdashboard d-flex justify-content-between">
                              <button className="buttonnew">
                                LIVE UPDATES
                              </button>
                              <button className="buttonnew">SCOREBOARD</button>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="card">
                          <div className="cardlive">
                            <div className="matchlist">
                              <div className="d-flex justify-content-start">
                                <h4>
                                  <span className="livered"></span>Live
                                </h4>
                                <div className="matchdetails">
                                  <h6>Border vs Mpumalanga, 6th Match</h6>
                                  <h6>Border vs Mpumalanga, 6th Match</h6>
                                  <p className="dateandtime">
                                    Thursday, 16 Nov | 01:30 PM
                                  </p>
                                </div>
                              </div>

                              <div className="matchone">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex matchgame">
                                    <div className="counteryflag mr-2">
                                      <img src={flag} className="img-fluid" />
                                    </div>
                                    <p>Bangladesh</p>
                                  </div>
                                  <div className="over">
                                    <p>
                                      <strong>169/9</strong>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="matchone">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex matchgame">
                                    <div className="counteryflag mr-2">
                                      <img src={flag} className="img-fluid" />
                                    </div>
                                    <p>Bangladesh</p>
                                  </div>
                                  <div className="over">
                                    <p>
                                      <strong>169/9</strong>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex margin-top-10">
                                <div className="details">
                                  <small>Breack</small>
                                </div>
                              </div>
                            </div>
                            <div className="bgdashboard d-flex justify-content-between">
                              <button className="buttonnew">
                                LIVE UPDATES
                              </button>
                              <button className="buttonnew">SCOREBOARD</button>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="matchtwo">
                    <div className="haedingview d-flex justify-content-between">
                      <h3>CSA 4-Day Series Division 2</h3>
                      <button className="viewall">View All</button>
                    </div>
                    <Row>
                      <Col md={6}>
                        <div className="card">
                          <div className="cardlive">
                            <div className="matchlist">
                              <div className="d-flex justify-content-start">
                                <h4>
                                  <span className="livered"></span>Live
                                </h4>
                                <div className="matchdetails">
                                  <h6>Border vs Mpumalanga, 6th Match</h6>
                                  <h6>Border vs Mpumalanga, 6th Match</h6>
                                  <p className="dateandtime">
                                    Thursday, 16 Nov | 01:30 PM
                                  </p>
                                </div>
                              </div>

                              <div className="matchone">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex matchgame">
                                    <div className="counteryflag mr-2">
                                      <img src={flag} className="img-fluid" />
                                    </div>
                                    <p>Bangladesh</p>
                                  </div>
                                  <div className="over">
                                    <p>
                                      <strong>169/9</strong>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="matchone">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex matchgame">
                                    <div className="counteryflag mr-2">
                                      <img src={flag} className="img-fluid" />
                                    </div>
                                    <p>Bangladesh</p>
                                  </div>
                                  <div className="over">
                                    <p>
                                      <strong>169/9</strong>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex margin-top-10">
                                <div className="details">
                                  <small>Breack</small>
                                </div>
                              </div>
                            </div>
                            <div className="bgdashboard d-flex justify-content-between">
                              <button className="buttonnew">
                                LIVE UPDATES
                              </button>
                              <button className="buttonnew">SCOREBOARD</button>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="card">
                          <div className="cardlive">
                            <div className="matchlist">
                              <div className="d-flex justify-content-start">
                                <h4>
                                  <span className="livered"></span>Live
                                </h4>
                                <div className="matchdetails">
                                  <h6>Border vs Mpumalanga, 6th Match</h6>
                                  <h6>Border vs Mpumalanga, 6th Match</h6>
                                  <p className="dateandtime">
                                    Thursday, 16 Nov | 01:30 PM
                                  </p>
                                </div>
                              </div>

                              <div className="matchone">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex matchgame">
                                    <div className="counteryflag mr-2">
                                      <img src={flag} className="img-fluid" />
                                    </div>
                                    <p>Bangladesh</p>
                                  </div>
                                  <div className="over">
                                    <p>
                                      <strong>169/9</strong>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="matchone">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex matchgame">
                                    <div className="counteryflag mr-2">
                                      <img src={flag} className="img-fluid" />
                                    </div>
                                    <p>Bangladesh</p>
                                  </div>
                                  <div className="over">
                                    <p>
                                      <strong>169/9</strong>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex margin-top-10">
                                <div className="details">
                                  <small>Breack</small>
                                </div>
                              </div>
                            </div>
                            <div className="bgdashboard d-flex justify-content-between">
                              <button className="buttonnew">
                                LIVE UPDATES
                              </button>
                              <button className="buttonnew">SCOREBOARD</button>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div> */}
                </div>
              </Col>
              <Col md={4}>
                <Rightnewssection />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
}
