import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Tabheader from '../Component/Tabheader'
import { Link } from 'react-router-dom';
import flag from '../../src/assets/image/flag.png'
import ball from '../../src/assets/image/cricket-ball.png'
import latestnews from '../../src/assets/image/latestnews.png'
import latestnews2 from '../../src/assets/image/latestnews2.png'
import NoData_colored from '../../src/assets/image/NoData_colored.png'
import Icccricketworldcup from "./Icccricketworldcup";
import video1 from '../../src/assets/image/video1.png'
import playername from '../../src/assets/image/Ellipse 2.png'
import axios from "axios";
import YouTube from "react-youtube";
import Rightnewssection from "./Rightnewssection";
import dateFormat from "dateformat";
export default function Cricketlivescore() {
    const [usersvideo, setUservideo] = useState([]);
    const [UserNews, setUserNews] = useState([]);
    useEffect(() => {
      playGame();
      playGame2();
    }, []);
  
    const playGame = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/video-link`
        );
        const result = response.data.data;
        setUservideo(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    const getVideoId = (url) => {
      const match = url.match(
        /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
      );
      return match && match[1];
    };
  
    const getThumbnailUrl = (url) => {
      const videoId = getVideoId(url);
      return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : "";
    };
    const playGame2 = async () => {
      try {
        const response = await axios(
          // (`${process.env.REACT_APP_API_URL}/home.php`)
          `${process.env.REACT_APP_API_URL}/news-list`,
          {}
        );
  
        // console.warn(response.data.dtat);
        const res = response.data.data;
        setUserNews(res);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const getNewsId = (url) => {
      const match = url.match(
        /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
      );
      return match && match[1];
    };
    const getThumbnailUrlnews = (url) => {
      const videoId = getNewsId(url);
      return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : "";
    };
    return (
        <>
            <Tabheader />
            <section className="cricketlivescore" id="cricketlivescore">
                <div className="cricketscore">
                    <Container>
                        <div className="d-flex border-bottom-custom">
                            <ul className="d-flex matcheslist">
                                <li>Matches</li>
                                <li>Current and Future Fixtures</li>
                                <li>Matches By Day</li>
                                <li>Series Archive</li>
                            </ul>
                        </div>
                        <div className="Sectab">
                            <ul>
                                <li className="activetab">
                                    <Link to="/">Cricket</Link>
                                </li>
                                <li>
                                    <Link to="/">Football</Link>
                                </li>
                                <li>
                                    <Link to="/">Kabaddi</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="d-flex border-bottom-custom">
                            <ul className="d-flex matcheslist">
                                <li>All</li>
                                <li>International</li>
                                <li>T20 League</li>
                                <li>Domestic</li>
                            </ul>
                        </div>


                    </Container>

                </div>
                <div>
                    <Container>
                        <Row>
                            <Col md={8}>
                                <div className="leftside">
                                    <div className="tabsnew">
                                        <ul>
                                            <li className="activetabnew">Live</li>
                                            <li>Upcoming</li>
                                            <li>Result</li>
                                        </ul>
                                    </div>
                                    <div className="matchtwo">
                                        <div className="haedingview d-flex justify-content-between">
                                            <h3>Legends League Cricket</h3>
                                            <button className="viewall">View All</button>
                                        </div>
                                        <Row>
                                            <Col md={12}>
                                               <div className="resultnotfound d-flex justify-content-center">
                                                  <img src={NoData_colored} alt="NoData_colored" />
                                               </div>
                                            </Col>
                                        
                                        </Row>
                                    </div>
                                    
                                </div>
                          
                            </Col>
                            <Col md={4}>
              <Rightnewssection/>
              </Col>
              <Col md={12}>
              <Icccricketworldcup />
              </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </>
    )
}
