import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Tabheader from "./Tabheader";
import { Link } from "react-router-dom";
import flag from "../../src/assets/image/flag.png";
import ball from "../../src/assets/image/cricket-ball.png";
import latestnews from "../../src/assets/image/latestnews.png";
import latestnews2 from "../../src/assets/image/latestnews2.png";
import NoData_colored from "../../src/assets/image/NoData_colored.png";
import video1 from "../../src/assets/image/video1.png";
import playername from "../../src/assets/image/Ellipse 2.png";
import axios from "axios";
import Photo_Missing from "../../src/assets/image/Photo-Missing.png";
import Rightnewssection from "./Rightnewssection";
export default function WonamRanking() {
  const [ranking, setRanking] = useState([]);
  const [selectedRankingType, setSelectedRankingType] = useState("test");
  const [selectedPlayerType, setSelectedPlayerType] = useState("bating");
  const [selectedGenderType, setSelectedGenderType] = useState("Male");
  const [baseUrl, setBaseUrl] = useState("");


  // https://sportspad.com//Admin/api/v1
  useEffect(() => {
    Ranking_List();
    Ranking_Listwww()
  
  }, []);

  
  const Ranking_List = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/ranking-list`
      );
      const result = response.data.data;
      setRanking(result);

      // Set baseUrl based on the response
      setBaseUrl("https://sportspad.com/Admin/public/admin/uploads/top_player");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const Ranking_Listwww = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URLWWW}/ranking-list`
      );
      const result = response.data.data;
      setRanking(result);

      // Set baseUrl based on the response
      setBaseUrl("https://www.sportspad.com/Admin/public/admin/uploads/top_player");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleRankingTypeChange = (rankingType) => {
    setSelectedRankingType(rankingType);
  };
  const handleGenderTypeChange = (grnderType) => {
    setSelectedGenderType(grnderType);
  };

  const handlePlayerTypeChange = (playerType) => {
    setSelectedPlayerType(playerType);
  };

  const filteredRanking = ranking.filter(
    (player) =>
      player.ranking === selectedRankingType &&
      player.team === selectedPlayerType &&
      player.gender === selectedGenderType
  );

  return (
    <>
      <Tabheader />
      <section className="cricketlivescore" id="cricketlivescore">
        <div className="cricketscore">
          <Container>
            <div className="d-flex border-bottom-custom">
              <ul className="d-flex matcheslist">
                <li>Matches</li>
                <li>Current and Future Fixtures</li>
                <li>Matches By Day</li>
                <li>Series Archive</li>
              </ul>
            </div>
            <div className="Sectab">
              <ul className="text-center">
                <li
                  style={{
                    backgroundColor:
                      selectedPlayerType === "bating" ? "#ffffff" : "#f8f9fa",
                    color:
                      selectedPlayerType === "bating" ? "#000000" : "#6c757d",
                  }}
                  className="activeclass"
                  onClick={() => handlePlayerTypeChange("bating")}
                >
                  <span> BATTER</span>
                 
                </li>
                <li
                  style={{
                    backgroundColor:
                      selectedPlayerType === "bowling" ? "#ffffff" : "#f8f9fa",
                    color:
                      selectedPlayerType === "bowling" ? "#000000" : "#6c757d",
                  }}
                  className="activeclass"
                  onClick={() => handlePlayerTypeChange("bowling")}
                >
                  <span> BOWLER</span>
             
                </li>
                <li
                  style={{
                    backgroundColor:
                      selectedPlayerType === "allrounder"
                        ? "#ffffff"
                        : "#f8f9fa",
                    color:
                      selectedPlayerType === "allrounder"
                        ? "#000000"
                        : "#6c757d",
                  }}
                  className="activeclass"
                  onClick={() => handlePlayerTypeChange("allrounder")}
                >
                  <span>ALL ROUNDERS</span>
                </li>
              </ul>
            </div>
            <div className="Sectab">
              <ul className="text-center">
                <li
                  style={{
                    backgroundColor:
                      selectedGenderType === "Male" ? "#ffffff" : "#f8f9fa",
                    color:
                      selectedGenderType === "Male" ? "#000000" : "#6c757d",
                  }}
                  className="activeclass"
                  onClick={() => handleGenderTypeChange("Male")}
                >
                  <span>MALE</span>
                </li>
                <li
                  style={{
                    backgroundColor:
                      selectedGenderType === "Female" ? "#ffffff" : "#f8f9fa",
                    color:
                      selectedGenderType === "Female" ? "#000000" : "#6c757d",
                  }}
                  className="activeclass"
                  onClick={() => handleGenderTypeChange("Female")}
                >
                  <span>FEMALE</span>
                </li>
              </ul>
            </div>
          </Container>
        </div>
        <div>
          <Container>
            <Row>
              <Col md={8}>
                <div className="leftside">
                  <div className="Sectab">
                    <ul className="text-center">
                      <li
                        style={{
                          backgroundColor:
                            selectedRankingType === "test"
                              ? "#dc3545"
                              : "#f8f9fa",
                          color:
                            selectedRankingType === "test"
                              ? "#ffffff"
                              : "#6c757d",
                        }}
                        className="activeclass"
                        onClick={() => handleRankingTypeChange("test")}
                      >
                        <span>TEST RANKING</span>
                      </li>
                      <li
                        style={{
                          backgroundColor:
                            selectedRankingType === "odi"
                              ? "#dc3545"
                              : "#f8f9fa",
                          color:
                            selectedRankingType === "odi"
                              ? "#ffffff"
                              : "#6c757d",
                        }}
                        className="activeclass"
                        onClick={() => handleRankingTypeChange("odi")}
                      >
                        <span>ODI RANKING</span>
                      </li>
                      <li
                        style={{
                          backgroundColor:
                            selectedRankingType === "t20"
                              ? "#dc3545"
                              : "#f8f9fa",
                          color:
                            selectedRankingType === "t20"
                              ? "#ffffff"
                              : "#6c757d",
                        }}
                        className="activeclass"
                        onClick={() => handleRankingTypeChange("t20")}
                      >
                        <span>T20I RANKING</span>
                      </li>
                    </ul>
                  </div>
                  {filteredRanking.length>0 && (
                  <div className="matchtwo">
              
                    <div className="haedingview d-flex justify-content-between">
                      <h5>ICC Men's Rankings</h5>
                      <button className="viewall">View All</button>
                    </div>

                    <Row>
                      <table class="table">
                        <thead>
                          <tr className="bg-secondary">
                            <th scope="col">Rank</th>
                            <th scope="col">Player</th>
                            <th scope="col">Team</th>
                            <th scope="col">Rating</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredRanking.map((player, index) => (
                            <tr>
                              <th scope="row">{player.rank}</th>
                              <td className="d-flex justify-conent-center">
                                {" "}
                                <img
                                  src={`${baseUrl}/${player.player_image}`}
                                  alt="playerimage"
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = Photo_Missing;
                                    e.target.style.backgroundColor = "white";
                                  }}
                                  style={{
                                    height: "40px",
                                    width: "40px",
                                    borderRadius: "50%",
                                  }}
                                />
                                <strong>{player.player_name}</strong>
                              </td>
                              <td>{player.team_name}</td>
                              <td>
                                <strong>{player.rating}</strong>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </Row>
                  </div>
                  )}
                </div>
              </Col>

              <Col md={4}>
                <Rightnewssection />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
}
