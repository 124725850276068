import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import cricket from "../../src/assets/image/cricket.png";
import image4 from "../../src/assets/image/image4.png";
import image5 from "../../src/assets/image/image5.png";
import dateFormat from "dateformat";
import axios from "axios";
export default function Fancytips() {
  const [UserNews, setUserNews] = useState([]);
  const [baseUrlnews, setBaseUrl] = useState("");

  function stripHtmlTags(html) {
    const strippedHtml = html.replace(/<[^>]*>/g, '');
    return strippedHtml;
  }
  useEffect(() => {
    // playGame();
    playGame2();
    playGame2www();
  }, []);
  const playGame2 = async () => {
    try {
      const response = await axios(
        // (`${process.env.REACT_APP_API_URL}/home.php`)
        `${process.env.REACT_APP_API_URL}/news-list`,
        {}
      );

      // console.warn(response.data.dtat);
      const res = response.data.data;
      setUserNews(res);
      setBaseUrl("https://sportspad.com/Admin/public/admin/uploads/news");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const playGame2www = async () => {
    try {
      const response = await axios(
        // (`${process.env.REACT_APP_API_URL}/home.php`)
        `${process.env.REACT_APP_API_URLWWW}/news-list`,
        {}
      );

      // console.warn(response.data.dtat);
      const res = response.data.data;
      setUserNews(res);
      setBaseUrl("https://www.sportspad.com/Admin/public/admin/uploads/news");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getNewsId = (url) => {
    const match = url.match(
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    return match && match[1];
  };
  const getThumbnailUrlnews = (url) => {
    const videoId = getNewsId(url);
    return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : "";
  };
  return (
    <>
      <div className="fancytips" id="fancytips">
        <section className="bgsectionmaincolor">
          <Container>
            <div className="fancytipssection">
              <div className="d-flex justify-content-between align-items-center">
                <div className="fancyheading d-flex align-items-center">
                  <div class="headingnews">FANTASY</div>

                  {/* <div className="cricketimage">
                                    <img src={cricket} alt="cricket image"/>
                                    </div> */}
                </div>
                <div className="viewall">
                  <Link to="/News">
                    {" "}
                    <button>View All</button>
                  </Link>
                </div>
              </div>
            </div>
            <Row className="margin-top-50">
              <Col md={6}>
                {/* {UserNews.slice(0, 1).map((set, setIndex) => ( */}
                   {UserNews
                    .filter(
                      (set) =>
                        set.games &&
                        set.games[0] &&
                        set.games[0].name === "Fantasy"
                    ).slice(0, 1).map((set, setIndex) => (
                  <div className="bg-white fancyboxstepone ">
                    <div className="fantasyimage">
                      {/* <img src={image4} alt="image4" className="img-fluid"/> */}
                      <div className="fantasyimage">
                        <img
                          // src={set.image || image4}
                          src={`${baseUrlnews}/${set.image}`}
                          alt={`Thumbnail`}
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <div className="padding_16 fancytipslink position-relative ">
                      <p className="matchreport">Match Report</p>
                      <Link to="/News">{set.title}</Link>
                      <p className="mb-0">{stripHtmlTags(set.description)}</p>
                      <mark>{dateFormat(set.updated_at, "mmm dS, yyyy")}</mark>
                    </div>
                  </div>
                ))}
              </Col>
              <Col md={6}>
                <Row>
                  {/* {UserNews.slice(0, 4).map((set, setIndex) => ( */}
                  {UserNews
                    .filter(
                      (set) =>
                        set.games &&
                        set.games[0] &&
                        set.games[0].name === "Fantasy"
                    ).slice(1, 4).map((set, setIndex) => (
                    <Col md={6}>
                      <div className="bg-white fancyboxsteptwo">
                        <div className="fantasyimage">
                          <img
                            // src={getThumbnailUrlnews(set.video_link) || image5}
                            src={`${baseUrlnews}/${set.image}`}
                            alt={`Thumbnail`}
                          />
                        </div>
                        <div className="padding_5 fancytipslink position-relative">
                          <p className="matchreport">Match Report</p>
                          <Link to="/News">{set.title}</Link>
                          <p className="mb-0">{stripHtmlTags(set.description)}</p>
                          <mark className="" style={{ fontSize: "10px" }}>
                          {dateFormat(set.updated_at, "mmm dS, yyyy")}
                          </mark>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Col>
              {/* <Col md={3}>
                              <div className="bg-white fancyboxsteptwo">
                                    <div className="fantasyimage">
                                       <img src={image5} alt="image5" className="img-fluid"/>
                                    </div>
                                    <div className="padding_5 fancytipslink position-relative">
                                       <p className="matchreport">Match Report</p>
                                    <Link to="/">NSW break drought with thumping win over WA</Link>
                                    <p className="mb-0">Tasmania have maintained the position at the top of the Sheffield Shield ladder as their clash with Victoria ended in a stalemate</p>
                                    <mark>7 month Ago</mark>
                                    </div>
                                 </div>
                              <div className="bg-white fancyboxsteptwo">
                                    <div className="fantasyimage">
                                       <img src={image5} alt="image5" className="img-fluid"/>
                                    </div>
                                    <div className="padding_5 fancytipslink position-relative">
                                       <p className="matchreport">Match Report</p>
                                    <Link to="/">NSW break drought with thumping win over WA</Link>
                                    <p className="mb-0">Tasmania have maintained the position at the top of the Sheffield Shield ladder as their clash with Victoria ended in a stalemate</p>
                                    <mark>7 month Ago</mark>
                                    </div>
                                 </div>
                                 
                              </Col> */}
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
}
