import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import image6 from "../../src/assets/image/image6.png";
import image8 from "../../src/assets/image/image8.png";
import { Link } from "react-router-dom";
import axios from "axios";
import dateFormat from "dateformat";
import { useNavigate } from "react-router-dom";
export default function Othervideo() {
  const [usersvideo, setUservideo] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    playGame();
    playGamewww();
  }, []);
  const playGame = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/video-link`
      );
      const result = response.data.data;
      setUservideo(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const playGamewww = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URLWWW}/video-link`
      );
      const result = response.data.data;
      setUservideo(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getVideoId = (url) => {
    const match = url.match(
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    return match && match[1];
  };
  const getThumbnailUrl = (url) => {
    const videoId = getVideoId(url);
    return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : "";
  };
  const playVideo = async (link, id, title) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/video-single?video_id=${link}`
      );
      const videoDetails = response.data.data;

      // Perform any video playback logic here
      // console.log("Playing video:", videoDetails);

      // Optionally, you can navigate to the Video_Detail page
      navigate(`/Video_Detail`);
      localStorage.setItem("playvideo", link);
      // alert(`Navigating to Video_Detail with ID: ${id}`);
    } catch (error) {
      console.error("Error fetching video data:", error);
    }
  };
  const playVideowww = async (link, id, title) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URLWWW}/video-single?video_id=${link}`
      );
      const videoDetails = response.data.data;

      // Perform any video playback logic here
      // console.log("Playing video:", videoDetails);

      // Optionally, you can navigate to the Video_Detail page
      navigate(`/Video_Detail`);
      localStorage.setItem("playvideo", link);
      // alert(`Navigating to Video_Detail with ID: ${id}`);
    } catch (error) {
      console.error("Error fetching video data:", error);
    }
  };
  return (
    <>
      <div className="othervideo" id="othervideo">
        <Container>
        <div className="headingnews">Other Video</div>
          <div className="margin-top-50">
            <Row>
              {usersvideo.slice(1, 2).map((set, setIndex) => (
                <Col md={8}>
                  <div className="othervideonew">
                    <div className="othervideo position-relative">
                      <img
                        // onClick={() => playVideo(set.link)}
                        onClick={() => { playVideo(set.link); playVideowww(set.link); }}
                        src={getThumbnailUrl(set.link)}
                        alt={`Thumbnail `}
                      />
                      <div
                        className="linkoverlay "
                        onClick={() => { playVideo(set.link); playVideowww(set.link); }}
                      >
                        <Link to="/Video_Detail">
                          <span>
                            {" "}
                            <i class="fa fa-calendar" aria-hidden="true"></i>
                            {dateFormat(set.updated_at, "mmm dS, yyyy")}
                          </span>
                          {set.title}
                        </Link>
                      </div>
                    </div>

                    <div className="d-flex flex-wrap justify-content-between">
                      {usersvideo.slice(1, 5).map((set, setIndex) => (
                        <div className="d-flex videoone">
                          <div className="image1">
                            <img
                             onClick={() => { playVideo(set.link); playVideowww(set.link); }}
                              src={getThumbnailUrl(set.link)}
                              alt={`Thumbnail `}
                            />
                          </div>
                          <div className="imagedata">
                            <date>
                              {" "}
                              <i class="fa fa-calendar" aria-hidden="true"></i> 
                         <span className="mx-1">{dateFormat(set.updated_at, "mmm dS, yyyy")}</span> 
                            </date>
                            <Link
                              to="/Video_Detail"
                              onClick={() => { playVideo(set.link); playVideowww(set.link); }}
                            >
                              {set.title}
                            </Link>
                          </div>
                        </div>
                      ))}
                  
                    </div>
                  </div>
                </Col>
              ))}

              <Col md={4}>
                <div className="othervideonew">
                  {usersvideo.slice(1, 7).map((set, setIndex) => (
                    <div className="d-flex rightvideosection">
                      <div className="image1">
                        <img
                          onClick={() => { playVideo(set.link); playVideowww(set.link); }}
                          src={getThumbnailUrl(set.link)}
                          alt={`Thumbnail `}
                        />
                      </div>
                      <div
                        className="imagedata"
                        onClick={() => { playVideo(set.link); playVideowww(set.link); }}
                      >
                        <date>
                          {" "}
                        
                          <i class="fa fa-calendar" aria-hidden="true"></i> <span className="mx-1">{dateFormat(set.updated_at, "mmmm dS, yyyy")}</span>

                        </date>
                        <Link to="/Video_Detail">{set.title}</Link>
                      </div>
                    </div>
                  ))}
                  {/* <div className="d-flex rightvideosection">
                    <div className="image1">
                      <img src={image8} alt="image8" />
                    </div>
                    <div className="imagedata">
                      <date>Updated - 28 Oct 2023 06:12 PM</date>
                      <Link to="/">
                        ODI World Cup 2023 England vs Australia:
                      </Link>
                    </div>
                  </div>
                  <div className="d-flex rightvideosection">
                    <div className="image1">
                      <img src={image8} alt="image8" />
                    </div>
                    <div className="imagedata">
                      <date>Updated - 28 Oct 2023 06:12 PM</date>
                      <Link to="/">
                        ODI World Cup 2023 England vs Australia:
                      </Link>
                    </div>
                  </div>
                  <div className="d-flex rightvideosection">
                    <div className="image1">
                      <img src={image8} alt="image8" />
                    </div>
                    <div className="imagedata">
                      <date>Updated - 28 Oct 2023 06:12 PM</date>
                      <Link to="/">
                        ODI World Cup 2023 England vs Australia:
                      </Link>
                    </div>
                  </div>
                  <div className="d-flex rightvideosection">
                    <div className="image1">
                      <img src={image8} alt="image8" />
                    </div>
                    <div className="imagedata">
                      <date>Updated - 28 Oct 2023 06:12 PM</date>
                      <Link to="/">
                        ODI World Cup 2023 England vs Australia:
                      </Link>
                    </div>
                  </div> */}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}
