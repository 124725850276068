import React, { useState, useEffect } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import flag from "../../src/assets/image/flag.png";
import ball from "../../src/assets/image/cricket-ball.png";
import playername from "../../src/assets/image/Ellipse 2.png";
import $ from "jquery";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom/dist";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Photo_Missing from "../../src/assets/image/Photo-Missing.png";
function Icccricketworldcup() {
  const [users, setUsers] = useState([]);
  const [user, setUsers1] = useState([]);
  const [usersrank, setUsersrank] = useState([]);
  const [data, setData] = useState(null);

  const imageUrlflag = "  https://static.cricbuzz.com/a/img/v1/25x18/i1/c";
  useEffect(() => {
    const fetchData = async () => {
      const options = {
        method: "GET",
        url: "https://cricbuzz-cricket.p.rapidapi.com/matches/v1/recent",

        headers: {
          "X-RapidAPI-Key":
            "8a09779cc5msh8d06b8be0a9d59bp19c20fjsn64a51b7d7f64",
          "X-RapidAPI-Host": "cricbuzz-cricket.p.rapidapi.com",
        },
      };

      try {
        const response = await axios.request(options);
        const result = response.data.typeMatches;

        console.warn(result);

        setData(result);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const options = {
        method: "GET",
        url: "https://cricbuzz-cricket.p.rapidapi.com/venues/v1/45/matches",

        headers: {
          "X-RapidAPI-Key":
            "8a09779cc5msh8d06b8be0a9d59bp19c20fjsn64a51b7d7f64",
          "X-RapidAPI-Host": "cricbuzz-cricket.p.rapidapi.com",
        },
      };

      try {
        const response = await axios.request(options);
        const result = response.data.matchDetails;

        console.warn(result);

        // setData(result);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // playGame();
    playGameMatchlist();
  }, [1000]);

  const handleButtonClickName = (seriesNam) => {
    localStorage.setItem("selectedSeriesName", seriesNam);
  };
  const handleButtonClick = (id) => {
    localStorage.setItem("selectedMatchId", id);
  };

  const playGameMatchlist = async () => {
    try {
      const currentDate = new Date();

      // Subtract two days from the current date
      currentDate.setDate(currentDate.getDate() - 4);

      // Format the date in the required format ("YYYY-MM-DDTHH:mm:ss.SSSZ")
      const customDate = currentDate.toISOString();
      // const customDate = "2024-01-13T00:00:00.000Z";
      const response = await axios.get(
        `https://apiv2.cricket.com.au/web/matchticker/fixtures?jsconfig=eccn%3Atrue&format=json`,
        {}
      );

      const result = response.data.fixtures;
      console.warn(result);
      const ids = result.map((fixture) => fixture.id);

      // Storing the array of ids in local storage
      localStorage.setItem("match", JSON.stringify(ids));

      // localStorage.setItem("playvideo", id);
      setUsersrank(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const calculateSlidesToShow = () => {
    const itemCount = usersrank.length;

    if (itemCount >= 4) {
      return 4;
    } else if (itemCount >= 3) {
      return 3;
    } else if (itemCount >= 2) {
      return 2;
    } else {
      return 1;
    }
  };
  const sliderSettings = {
    dots: false,
    // infinite: true,
    speed: 500,
    slidesToScroll: 1,
    // slidesToShow: calculateSlidesToShow(),
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          // slidesToShow: calculateSlidesToShow(),
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <section className="bgsection">
        <Container>
          {/* <OwlCarousel className='owl-theme livescoreowl' {...state} items={4}  margin={5} nav={true} dots={false} > */}
          <Slider
            className="slick-theme livescoreowl custom-slider"
            {...sliderSettings}
          >
            {data &&
              data.map((gameType) =>
                gameType.seriesMatches.map(
                  (series) =>
                    series.seriesAdWrapper &&
                    series.seriesAdWrapper.matches.map((match, index) => (
                      // <Link
                      //   to="/LiveScore"
                      //   className="text-dark"
                      //   onClick={() => handleButtonClick(set.id)}
                      // >
                      <div class="item">
                        <div
                          className="cardlive "
                          onClick={() => handleButtonClick(match.matchInfo.matchId)}
                        >
                          <Link
                            to="/LiveScore"
                            className="text-dark"
                            onClick={() => handleButtonClick(match.matchInfo.matchId)}
                          >
                            <>
                              <div className="d-flex justify-content-between align-item-center">
                                <h6>
                                  <span
                                  // className={
                                  //   set.gameStatus === "In Play" ? "livered" : ""
                                  // }
                                  ></span>
                                  <span
                                    className="mx-1"
                                    style={{
                                      color:
                                        match.matchInfo.state === "Live"
                                          ? "red"
                                          : match.matchInfo.state === "In Play"
                                          ? "blue"
                                          : match.matchInfo.state === "Result"
                                          ? "black"
                                          : match.matchInfo.state === "Complete"
                                          ? "blue"
                                          : "black",
                                    }}
                                  >
                                    {match.matchInfo.state === "Live" ? (
                                      <span>LIVE</span>
                                    ) : match.matchInfo.state === "Prematch" ? (
                                      <span>UPCOMING</span>
                                    ) : match.matchInfo.state === "Lunch" ? (
                                      <span>Lunch BREAK</span>
                                    ) : match.matchInfo.state === "Dinner" ? (
                                      <span>Dinner BREAK</span>
                                    ) : match.matchInfo.state === "Complete" ? (
                                      <span>Completed</span>
                                    ) : match.matchInfo.state ===
                                      "Between Innings" ? (
                                      <span>INNING BREAK</span>
                                    ) : match.matchInfo.states === "Stumps" ? (
                                      <span>STUMPS</span>
                                    ) : null}
                                  </span>
                                </h6>
                                <p className=" mx-1 matchnamelistfor">
                                  {match.matchInfo.seriesName} |
                                  {match.matchInfo.matchDesc}
                                </p>
                              </div>
                              <div className="matchone">
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="d-flex matchgame align-items-center">
                                    <div className="counteryflag mr-2">
                                      <img
                                        // src={Photo_Missing}
                                        src={
                                          match.matchInfo.team1.imageId
                                            ? `${imageUrlflag}${match.matchInfo.team1.imageId}/172123.jpg`
                                            : Photo_Missing
                                        }
                                        
                                      
                                        className="img-fluid  "
                                        style={{
                                          border: "0.25px solid #adada8",
                                          height: "30px",
                                          width: "30px",
                                        }}
                                      />
                                    </div>
                                    <p> {match.matchInfo.team1.teamName}</p>
                                  </div>
                                  <div className="over">
                                    <p style={{}} className="mxr">
                                  {match.matchScore &&
                                      match.matchScore.team1Score &&
                                      match.matchScore.team1Score.inngs1 && 
                                      <>
                                        <strong className="text-secondary">
                                          {
                                            match.matchScore.team1Score.inngs1
                                              .runs
                                          }
                                          /
                                          {
                                            match.matchScore.team1Score.inngs1
                                              .wickets
                                          }
                                        </strong>
                                        <strong className="mxr text-secondary">
                                          (
                                          {
                                            match.matchScore.team1Score.inngs1
                                              .overs
                                          }
                                          )
                                        </strong>

                                        {match.matchScore &&
                                      match.matchScore.team1Score &&
                                      match.matchScore.team1Score.inngs2 && 
                                      <>
                                        <strong className="text-secondary">
                            
                                          {
                                            match.matchScore.team1Score.inngs2
                                              .runs
                                          }
                                          /
                                          {
                                            match.matchScore.team1Score.inngs2
                                              .wickets
                                          }
                                        </strong>
                                        <strong className="mxr text-secondary">
                                          (
                                          {
                                            match.matchScore.team1Score.inngs2
                                              .overs
                                          }
                                          )
                                        </strong>
                                      </>
                                         }
                                      </>
                                        }
                                   
                                     </p>
                                  </div>
                                </div>
                              </div>
                              <div className="matchone">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex matchgame align-items-center">
                                    <div className="counteryflag mr-2 ">
                                      <img
                                       src={
                                        match.matchInfo.team2.imageId
                                          ? `${imageUrlflag}${match.matchInfo.team2.imageId}/172123.jpg`
                                          : Photo_Missing
                                      }
                                        style={{
                                          border: "0.25px solid #adada8",
                                          height: "30px",
                                          width: "30px",
                                        }}
                                        className="img-fluid  "
                                        alt={"hfhgfh"}
                                      />
                                    </div>

                                    <p className="" style={{}}>
                                      {match.matchInfo.team2.teamName}
                                    </p>
                                  </div>
                                  <div className="over ">
                                        <p style={{}} className="mxr">
                                    {match.matchScore &&
                                      match.matchScore.team2Score &&
                                      match.matchScore.team2Score.inngs1 && (
                                          <>
                                            <strong className="text-secondary">
                                              {
                                                match.matchScore.team2Score
                                                  .inngs1.runs
                                              }
                                              /
                                              {
                                                match.matchScore.team2Score
                                                  .inngs1.wickets
                                              }
                                            </strong>
                                            <strong className="mxr text-secondary">
                                              (
                                              {
                                                match.matchScore.team2Score
                                                  .inngs1.overs
                                              }
                                              )
                                            </strong>
                                            {match.matchScore &&
                                      match.matchScore.team2Score &&
                                      match.matchScore.team2Score.inngs2 && (
                                          <>
                                            <strong className="text-secondary">
                                              {
                                                match.matchScore.team2Score
                                                  .inngs2.runs
                                              }
                                              /
                                              {
                                                match.matchScore.team2Score
                                                  .inngs2.wickets
                                              }
                                            </strong>
                                            <strong className="mxr text-secondary">
                                              (
                                              {
                                                match.matchScore.team2Score
                                                  .inngs2.overs
                                              }
                                              )
                                            </strong>
                                          </>
                                      )}
                                          </>
                                      )}
                                        </p>
                                       
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex margin-top-10">
                                <div className="details ">
                                  <p className="text-primary">
                                    {/* <>
                                    {new Date(
                                     set.startDateTime
                                    ).toLocaleDateString("en-US", {
                                      weekday: "short",
                                      day: "numeric",
                                      month: "short",
                                    })}
                                    <span className="mx-1">|</span>
                                    {new Date(
                                      set.startDateTime
                                    ).toLocaleTimeString("en-US", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })}
                                  </> */}
                                    {match.matchInfo.state === "Live" ? (
                                      <>
                                        {new Date(
                                          parseInt(match.matchInfo.startDate)
                                        ).toDateString()}
                                      </>
                                    ) : (
                                      <marquee scrollamount="3">
                                        {" "}
                                        {match.matchInfo.status}
                                      </marquee>
                                    )}
                                  </p>
                                </div>
                              </div>
                            </>
                          </Link>
                          <div className="playername">
                            <>
                              <Link to="/LiveScore">
                                {" "}
                                <button className="scoreboard">
                                  {/* Points Table */}
                                </button>
                              </Link>

                              <Link
                                to="/Series"
                                //  onClick={() => handleButtonClickName(set.competition.seriesId)}
                                className="text-light"
                              >
                                {" "}
                                <button className="scoreboard">Schedule</button>
                              </Link>
                            </>
                          </div>
                        </div>
                      </div>
                      // </Link>
                    ))
                )
              )}
          </Slider>
        </Container>
      </section>
    </div>
  );
}

export default Icccricketworldcup;
