import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import imagenews from '../assets/image/imagenews.png'
import Rightnewssection from "./Rightnewssection";
export default function Predictiontips() {
  return (
    <>
      <section className="news" id="news">
        <div class="cricketscore">
          <div class="container">
            <div class="d-flex border-bottom-custom">
              <ul class="d-flex matcheslist">
                <li>Matches</li>
                <li>Current and Future Fixtures</li>
                <li>Matches By Day</li>
                <li>Series Archive</li>
              </ul>
            </div>
            <div class="d-flex border-bottom-custom">
              <ul class="d-flex matcheslist">
                <li>All</li>
                <li>International</li>
                <li>T20 League</li>
                <li>Domestic</li>
              </ul>
            </div>
          </div>
        </div>
        <Container>
          <Row>
            <Col md={8}>
              <section className="newspage">
                <ul>
                  <li>HOME </li>
                  <li>
                    <i class="bi bi-chevron-double-right"></i>
                  </li>
                  <li>NEWS </li>
                  <li>
                    <i class="bi bi-chevron-double-right"></i>
                  </li>
                  <li>BC.Game promo code 2023: [bcstar] – up to 360% Bonus </li>
                </ul>
                <main id="newspage">
                  <div className="deatils">
                    <h3>
                      BC.Game promo code 2023: [bcstar] – up to 360% Bonus
                    </h3>
                  </div>
                  <div className="authorname">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="autor">
                        <p>Rohit Kumar</p>
                        <small>Author</small>
                      </div>
                      <div className="dateandtime">
                        <p>Updated - 08 Nov 2023 06:38 PM</p>
                      </div>
                    </div>
                  </div>
                  <div className="newssection2">
                    <p>
                      BC.Game promo code is “bcstar” for Indian players in 2023.
                      You can make use of this promo code for sports betting and
                      casino games when you sign up as a new player.
                    </p>

                    <h6>BC Game promo code Offers for India in 2023</h6>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>BC Game Offers</th>
                          <th>Offer Details</th>
                          <th>BC Game Offers</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Welcome Bonus</td>
                          <td>Drops and Wins</td>
                          <td>Promo code: bcstar</td>
                        </tr>
                        <tr>
                          <td>Welcome Bonus</td>
                          <td>Drops and Wins</td>
                          <td>Promo code: bcstar</td>
                        </tr>
                      </tbody>
                    </Table>
                    <div className="register">
                       <h4 className="mb-0">How to register on BC.Game</h4>
                       <p>Here is how you can register on BC.Game</p>
                       <ul>
                          <li>Get onto the official BC.Game website and look for the “Sign Up” button on the top right corner of the screen</li>
                          <li>You can use your email ID or phone number to register</li>
                          <li>Enter your email ID/phone number and create a password</li>
                          <li>Agree to the terms and conditions</li>
                          <li>Enter the promo code bcstar</li>
                          <li> Click the “Sign Up” button</li>
                          <li>You will receive a message confirming your registration</li>
                       </ul>
                    </div>
                  </div>
                    <div className="img">
                        <img src={imagenews} alt="imagenews" />
                    </div>
                    <div className="newssection2">
                       <p className="pb-0">A point to note is that Indian players need to verify their email as it allows confirmation of the players’ identity. It also helps when they want to change their password. You can also use your Google , Facebook, or Telegram account to register. However, email verification is still a KYC requirement.</p>
                       <div className="register">
                       <h4 className="mb-0">How to register on BC.Game</h4>
                       <p>Here is how you can register on BC.Game</p>
                       <ul>
                          <li>Get onto the official BC.Game website and look for the “Sign Up” button on the top right corner of the screen</li>
                          <li>You can use your email ID or phone number to register</li>
                          <li>Enter your email ID/phone number and create a password</li>
                          <li>Agree to the terms and conditions</li>
                          <li>Enter the promo code bcstar</li>
                          <li> Click the “Sign Up” button</li>
                          <li>You will receive a message confirming your registration</li>
                       </ul>
                    </div>
                    </div>
                </main>
              </section>
            </Col>
            <Col md={4}>
              <Rightnewssection/>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
