import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from 'react-bootstrap'
import Tabheader from './Tabheader'
import { Link } from 'react-router-dom';
import Pklimage from '../../src/assets/image/pkl.jpg'
import ball from '../../src/assets/image/cricket-ball.png'
import latestnews from '../../src/assets/image/latestnews.png'
// import fb from '../../src/assets/image/fb_icon.svg'
import flag from '../../src/assets/image/flagnumber.jpg'
// import tw from '../../src/assets/image/tw_icon.svg'
// import wt from '../../src/assets/image/wt_icon.svg'
import mt11logo from '../../src/assets/image/mt11logo.svg'
// import fbm from '../../src/assets/image/fbm_icon.svg'
import copy from '../../src/assets/image/copy_icon.svg'
import GujaratFortunegiants from '../../src/assets/image/GujaratFortunegiants.jpg'
import latestnews2 from '../../src/assets/image/latestnews2.png'
import NoData_colored from '../../src/assets/image/NoData_colored.png'
import video1 from '../../src/assets/image/video1.png'
import axios from "axios";
import YouTube from "react-youtube";
import Rightnewssection from "./Rightnewssection";
import dateFormat from "dateformat";
export default function Pkl() {
    // const [usersvideo, setUservideo] = useState([]);
    // const [UserNews, setUserNews] = useState([]);
    // useEffect(() => {
    //   playGame();
    //   playGame2();
    // }, []);
  
    // const playGame = async () => {
    //   try {
    //     const response = await axios.get(
    //       `https://api.a2logicgroup.com/Admin/api/v1/video-link`
    //     );
    //     const result = response.data.data;
    //     setUservideo(result);
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //   }
    // };
  
    // const getVideoId = (url) => {
    //   const match = url.match(
    //     /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    //   );
    //   return match && match[1];
    // };
  
    // const getThumbnailUrl = (url) => {
    //   const videoId = getVideoId(url);
    //   return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : "";
    // };
    // const playGame2 = async () => {
    //   try {
    //     const response = await axios(
    //       // (`${process.env.REACT_APP_API_URL}/home.php`)
    //       `https://api.a2logicgroup.com/Admin/api/v1/news-list`,
    //       {}
    //     );
  
    //     console.warn(response.data.dtat);
    //     const res = response.data.data;
    //     setUserNews(res);
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //   }
    // };
    // const getNewsId = (url) => {
    //   const match = url.match(
    //     /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    //   );
    //   return match && match[1];
    // };
    // const getThumbnailUrlnews = (url) => {
    //   const videoId = getNewsId(url);
    //   return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : "";
    // };
    return (
        <>
            <Tabheader />
            <section id="pkl">

                <div>
                    <Container>
                        <Row>
                            <Col md={8}>
                                <div className="pkl">
                                    <ul className="pl-0 d-flex">
                                        <li className="Home">Home</li>
                                        <li><i class="bi bi-chevron-double-right"></i></li>&nbsp;
                                        <li> Pro Kabaddi Season 10</li>
                                    </ul>
                                    <div className="card box-card bg-red-color p-2">
                                        <Row>
                                            <Col md={5}  className="d-flex align-items-center ">
                                            <div className="d-flex align-items-center ">
                                                <div className="imageforleague">
                                                    <img src={Pklimage} alt="pkl" />
                                                </div>
                                                <div className="detailsleague">
                                                    <h4>Pro Kabaddi Season 10</h4>
                                                    <h6>02 Dec 2023 - 21 Feb 2024</h6>
                                                    <div className="d-flex matchesbutton">
                                                        <button>132 Matches</button>
                                                        <button>12 Team</button>
                                                    </div>
                                                </div>
                                            </div>
                                            </Col>
                                            <Col md={7}>
                                            <div className="gradientcolor">
                                                <div className="d-flex align-items-center justify-content-between flex-direction-colunm">
                                                    <div className="matchvs">
                                                        <div className="d-flex align-items-center">
                                                            <div className="matchname  text-center">
                                                                <img src={GujaratFortunegiants} alt="GujaratFortunegiants" />
                                                                <small>GUJ</small>
                                                            </div>
                                                            <div className="matchname">
                                                                <h4>VS</h4>
                                                            </div>
                                                            <div className="matchname text-center">
                                                                <img src={GujaratFortunegiants} alt="GujaratFortunegiants" />
                                                                <small>GUJ</small>
                                                            </div>
                                                        </div>
                                                        <div className="scoresee">
                                                            Match 100
                                                        </div>
                                                    </div>
                                                    <div className="countdown">
                                                        <div className="d-flex align-items-center justify-content-between timer">

                                                            <p> 00<span >Days</span></p>:
                                                            <p>02<span >HRS</span></p>:
                                                            <p>06<span >Mins</span></p>:
                                                            <p>42<span >Sec</span></p>
                                                        </div>

                                                    </div>
                                                    {/* <div className="poweredby">
                                                   <strong className="text-white">
                                                      Powered By
                                                   </strong>
                                                   <img src={mt11logo} alt=""/>
                                               </div> */}
                                                </div>
                                            </div>
                                            </Col>
                                        </Row>
                                        <div className="d-flex align-items-center justify-content-between">
                                        
                                            {/* <div className="socialmediaicon d-flex">
                                            <img src={fb} alt="facebook"/>
                                            <img src={tw} alt="tw"/>
                                            <img src={wt} alt="wt"/>
                                            <img src={fbm} alt="fbm"/>
                                            <img src={copy} alt="copy"/>
                                        </div> */}
                                           

                                        </div>
                                    </div>
                          
                                    <div className="matchtwo">
                                        <div className="haedingview d-flex justify-content-between">
                                            <h3>CSA 4-Day Series Division 2</h3>
                                            <button className="viewall">View All</button>
                                        </div>
                                        <Row>

                                            <Col md={6}>
                                                <div className="card">
                                                    <div className="cardlive">
                                                        <div className="matchlist">
                                                            <div className="d-flex justify-content-start">
                                                                <h4 className="livematch"> Live</h4>
                                                                <div className="matchdetails">
                                                                    <h6>Vancouver Whitecaps FC vs Saint Louis City SC</h6>
                                                                    <p className="dateandtime">Thursday, 16 Nov | 01:30 PM</p>
                                                                </div>
                                                            </div>

                                                            <div className="d-flex justify-content-between align-items-center mt-2">
                                                                <div className="footballmatch">
                                                                    <div className="d-flex justify-content-between">
                                                                        <div className="d-flex matchgame">
                                                                            <div className="counteryflag mr-2">
                                                                                <img src={flag} className='img-fluid' />
                                                                                <p>Bangladesh</p>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="scorefootball text-center">
                                                                    <h6 className='m-0'><strong>  3-0</strong></h6>
                                                                    <small className='m-0'>90.</small><br></br>
                                                                    <small className='m-0'>Compleate</small>
                                                                </div>
                                                                <div className="footballmatch">
                                                                    <div className="d-flex justify-content-between">
                                                                        <div className="d-flex matchgame">
                                                                            <div className="counteryflag mr-2">
                                                                                <img src={flag} className='img-fluid' />
                                                                                <p>Bangladesh</p>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex margin-top-10">

                                                                <div className="details">

                                                                    <p className='mb-0 text-primary'> Vancouver Whitecaps FC won</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="bgdashboard d-flex justify-content-between">
                                                            <button className="buttonnew">LIVE UPDATES</button>
                                                            <button className="buttonnew">OVERVIEW</button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="card">
                                                    <div className="cardlive">
                                                        <div className="matchlist">
                                                            <div className="d-flex justify-content-start ">
                                                                <h4 className="livematch"> Live</h4>
                                                                <div className="matchdetails">
                                                                    <h6>Vancouver Whitecaps FC vs Saint Louis City SC</h6>
                                                                    <p className="dateandtime">Thursday, 16 Nov | 01:30 PM</p>
                                                                </div>
                                                            </div>

                                                            <div className="d-flex justify-content-between align-items-center mt-2">
                                                                <div className="footballmatch">
                                                                    <div className="d-flex justify-content-between">
                                                                        <div className="d-flex matchgame">
                                                                            <div className="counteryflag mr-2">
                                                                                <img src={flag} className='img-fluid' />
                                                                                <p>Bangladesh</p>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="scorefootball text-center">
                                                                    <h6 className='m-0'><strong>  3-0</strong></h6>
                                                                    <small className='m-0'>90.</small><br></br>
                                                                    <small className='m-0'>Compleate</small>
                                                                </div>
                                                                <div className="footballmatch">
                                                                    <div className="d-flex justify-content-between">
                                                                        <div className="d-flex matchgame">
                                                                            <div className="counteryflag mr-2">
                                                                                <img src={flag} className='img-fluid' />
                                                                                <p>Bangladesh</p>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex margin-top-10">

                                                                <div className="details">

                                                                    <p className='mb-0 text-primary'> Vancouver Whitecaps FC won</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="bgdashboard d-flex justify-content-between">
                                                            <button className="buttonnew">LIVE UPDATES</button>
                                                            <button className="buttonnew">OVERVIEW</button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                {/* ........................................Latest News side .................. */}
             <Rightnewssection/>
              </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </>
    )
}
