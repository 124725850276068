import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import image3 from '../assets/image/image3.png';
import news1 from '../assets/image/news1.png';
import india from '../assets/image/india.png';
import dateFormat from "dateformat";
import axios from "axios";
export default function LatestNews() {
    const [UserNews, setUserNews] = useState([]);
    const [UserPointTable, setUserPointTable] = useState([]);
    const [baseUrlnews, setBaseUrl] = useState("");
    // const baseUrlnews =
    // "https://sportspad.com/Admin/public/admin/uploads/news";
    const baseUrl =
    "https://sportspad.com/Admin/public/admin/uploads/points";
  useEffect(() => {
    // playGame();
    playGame2();
    playPointTable();
  }, []);
   
    const playGame2 = async () => {
        try {
            const response = await axios(
                // (`${process.env.REACT_APP_API_URL}/home.php`)
                `${process.env.REACT_APP_API_URL}/news-list`,
                {}
            );

            // console.warn(response.data.dtat);
            const res = response.data.data;
            setUserNews(res);
            setBaseUrl("https://sportspad.com/Admin/public/admin/uploads/news");
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const playGame2www = async () => {
        try {
            const response = await axios(
                // (`${process.env.REACT_APP_API_URL}/home.php`)
                `${process.env.REACT_APP_API_URLWWW}/news-list`,
                {}
            );

            // console.warn(response.data.dtat);
            const res = response.data.data;
            setUserNews(res);
            setBaseUrl("https://www.sportspad.com/Admin/public/admin/uploads/news");
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const getNewsId = (url) => {
        const match = url.match(
            /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
        );
        return match && match[1];
    };
    const getThumbnailUrlnews = (url) => {
        const videoId = getNewsId(url);
        return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : "";
    };
    const playPointTable = async () => {
        try {
          const response = await axios(
            // (`${process.env.REACT_APP_API_URL}/home.php`)
            `${process.env.REACT_APP_API_URL}/point-table`,
            {}
          );
    
        //   console.warn(response.data.dtat);
          const res = response.data.data;
          setUserPointTable(res);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      const handleClick = (slug, name) => {
  
        window.location.href = `/News_Detail?id=${slug}`;
      };
      
    return (
        <div className='latestNews' id="Latestnews">
            <Container >
             
            <div className="headingnews" >Latest News</div>
               
                        <Row>
                            <Col md={3}>
                                <div className="newsleft  ">
                                    {UserNews.slice(0, 2).map((set, setIndex) => (
                                        <div className="imagenews position-relative" onClick={() => {handleClick(set.slug);}}>
                                            <img
                                                // src={set.image || image3}
                                                src={`${baseUrlnews}/${set.image}`}
                                                alt={`Thumbnail  `}
                                                className="img-fluid" />
                                            <div className="linknewss">
                                           
                                           <strong className="p-0"><i class="fa fa-calculator" aria-hidden="true"></i>   {dateFormat(set.updated_at, "mmm dS, yyyy")}</strong>
                                                {/* <h1 >{set.title}</h1> */}
                                                <h1 className="text-white" onClick={() => {handleClick(set.slug);}}>{set.title}</h1>
                                          
                                            </div>
                                        </div>
                                    ))}
                                    {/* <div className="imagenews position-relative">
                                      <img src={image3} alt="news !" />
                                      <div className="linkoverlay"><Link to="/">Viv Richards reveals sage advice for India at World Cup</Link></div>
                                   </div> */}
                                </div>
                            </Col>
                            <Col md={6}>
                            {UserNews.slice(0, 1).map((set, setIndex) => (
                                    <div className="centernews">

                                        <div className="newsright position-relative">
                                            <img
                                                // src={set.image}
                                                src={`${baseUrlnews}/${set.image}`}
                                                alt={`Thumbnail  `}
                                                className="img-fluid" />
                                                 <div className="linknewss">
                                            <strong className="p-0"><i class="fa fa-calculator" aria-hidden="true"></i>  <span className="mx-1">{dateFormat(set.updated_at, "mmm dS, yy")}</span></strong>
                                            <h1 onClick={() => {handleClick(set.slug);}}>{set.title}</h1>
                                            </div>  
                                        </div>
                                    </div>
                            ))}
                            </Col>
                            {UserNews.length>1 &&(
                            <Col md={3}>


                                {UserNews.slice(3, 5).map((set, setIndex) => (
                                    <div className="newsright  position-relative"  onClick={() => {handleClick(set.slug);}}>
                                        <img
                                            // src={set.image}
                                            src={`${baseUrlnews}/${set.image}`}
                                            alt={`Thumbnail  `}
                                            className="img-fluid" />
                                            <div className="linknewss">
                                            <strong className="p-0"><i class="fa fa-calculator" aria-hidden="true"></i>   {dateFormat(set.updated_at, "mmm dS, yy")}</strong>

                                            <h1 onClick={() => {handleClick(set.slug);}}>{set.title}</h1>
                                            </div>
                                    </div>
                                ))}

                            </Col>
                            )}
                        </Row>
                  
                    {/* <Col md={4}>
                        <div className="PointTable">
                            <div className="pointtableheading d-flex justify-content-between">
                            <div class="headingnews">CWS 23 Point Table</div>
                                <Link>Full Table</Link>
                            </div>
                            <div className="table-responsive">

                            <table className="table mb-0">
                                <thead className="pointablebg">
                                    <th>POS</th>
                          
                                    <th>TEAM</th>
                                    <th>P</th>
                                    <th>W</th>
                                    <th>L</th>
                                    <th>D</th>
                                    <th>PTS</th>
                                    <th>NRR</th>
                                </thead>
                                <tbody className="listpointtable">
                    {UserPointTable.map((set, setIndex) => (
                      <tr>
                        <td>{set.position}</td>
                        <td>
                          <div className="d-flex align-items-center p-0">
                            <img style={{height:"20px"}}
                              src={`${baseUrlnews}/${set.flage_image}`}
                              alt="playerimage"
                              onError={(e) => {
                                e.target.onerror = null;
                            
                              }}
                            />
                            <p className="mb-0">
                              {set.team_name} <strong>(Q)</strong>
                            </p>
                          </div>
                        </td>
                        <td>{set.point}</td>
                        <td>{set.win}</td>
                        <td>{set.loss}</td>
                        <td>{set.drow}</td>
                        <td>{set.total_point}</td>
                        <td>{set.net_run_ret}</td>
                      </tr>
                    ))}
                    
                  </tbody>
                            </table>
                            </div>
                        </div>
                    </Col> */}
                {/* </Row> */}
            </Container>
        </div>
    )
}