import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Tabheader from '../Component/Tabheader'
import { Link } from 'react-router-dom';
import flag from '../../src/assets/image/flag.png'
import ball from '../../src/assets/image/cricket-ball.png'
import latestnews from '../../src/assets/image/latestnews.png'
import latestnews2 from '../../src/assets/image/latestnews2.png'
import NoData_colored from '../../src/assets/image/NoData_colored.png'
import video1 from '../../src/assets/image/video1.png'
import playername from '../../src/assets/image/Ellipse 2.png'
import Rightnewssection from './Rightnewssection';
export default function Sharjahhundredleague() {
    return (
        <>
            <Tabheader />
            <section className="cricketlivescore" id="cricketlivescore">
                <div className="cricketscore">
                    <Container>
                        <div className="d-flex border-bottom-custom">
                            <ul className="d-flex matcheslist">
                                <li>Matches</li>
                                <li>Current and Future Fixtures</li>
                                <li>Matches By Day</li>
                                <li>Series Archive</li>
                            </ul>
                        </div>
                        <div className="Sectab">
                            <ul>
                                <li className="activetab">
                                    <Link to="/">Cricket</Link>
                                </li>
                                <li>
                                    <Link to="/">Football</Link>
                                </li>
                                <li>
                                    <Link to="/">Kabaddi</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="d-flex border-bottom-custom">
                            <ul className="d-flex matcheslist">
                                <li>All</li>
                                <li>International</li>
                                <li>T20 League</li>
                                <li>Domestic</li>
                            </ul>
                        </div>


                    </Container>

                </div>
                <div>
                    <Container>
                        <Row>
                            <Col md={8}>
                                <div className="leftside">
                                    <div className="tabsnew">
                                        <ul>
                                            <li className="activetabnew">Live</li>
                                            <li>Upcoming</li>
                                            <li>Result</li>
                                        </ul>
                                    </div>
                                    <div className="matchtwo">
                                        <div className="haedingview d-flex justify-content-between">
                                            <h3>Sharjah Hundred League</h3>
                                            <button className="viewall">View All</button>
                                        </div>
                                        <Row>
                                            <Col md={12}>
                                               <div className="resultnotfound d-flex justify-content-center">
                                                  <img src={NoData_colored} alt="NoData_colored" />
                                               </div>
                                            </Col>
                                        
                                        </Row>
                                    </div>
                                    
                                </div>
                            </Col>
                            <Col md={4}>
                              <Rightnewssection/>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </>
    )
}
