import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from 'react-bootstrap'
import Tabheader from './Tabheader'
import { Link } from 'react-router-dom';
import flag from '../../src/assets/image/flagnumber.jpg'
import ball from '../../src/assets/image/cricket-ball.png'
import latestnews from '../../src/assets/image/latestnews.png'
import latestnews2 from '../../src/assets/image/latestnews2.png'
import NoData_colored from '../../src/assets/image/NoData_colored.png'
import video1 from '../../src/assets/image/video1.png'
import axios from "axios";
import YouTube from "react-youtube";
import dateFormat from "dateformat";
import Rightnewssection from "./Rightnewssection";
export default function Footballlivescore() {
    // const [usersvideo, setUservideo] = useState([]);
    // const [UserNews, setUserNews] = useState([]);
    // useEffect(() => {
    //   playGame();
    //   playGame2();
    // }, []);
  
    // const playGame = async () => {
    //   try {
    //     const response = await axios.get(
    //       `https://api.a2logicgroup.com/Admin/api/v1/video-link`
    //     );
    //     const result = response.data.data;
    //     setUservideo(result);
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //   }
    // };
  
    // const getVideoId = (url) => {
    //   const match = url.match(
    //     /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    //   );
    //   return match && match[1];
    // };
  
    // const getThumbnailUrl = (url) => {
    //   const videoId = getVideoId(url);
    //   return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : "";
    // };
    // const playGame2 = async () => {
    //   try {
    //     const response = await axios(
    //       // (`${process.env.REACT_APP_API_URL}/home.php`)
    //       `https://api.a2logicgroup.com/Admin/api/v1/news-list`,
    //       {}
    //     );
  
    //     console.warn(response.data.dtat);
    //     const res = response.data.data;
    //     setUserNews(res);
    //   } catch (error) {
    //     console.error("Error fetching data:", error);
    //   }
    // };
    // const getNewsId = (url) => {
    //   const match = url.match(
    //     /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    //   );
    //   return match && match[1];
    // };
    // const getThumbnailUrlnews = (url) => {
    //   const videoId = getNewsId(url);
    //   return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : "";
    // };
    return (
        <>
            <Tabheader />
            <section className="cricketlivescore" id="cricketlivescore">
                <div className="cricketscore">
                    <Container>
                        <div className="d-flex border-bottom-custom">
                            <ul className="d-flex matcheslist">
                                <li>Matches</li>
                                <li>Current and Future Fixtures</li>
                                <li>Matches By Day</li>
                                <li>Series Archive</li>
                            </ul>
                        </div>
                        <div className="Sectab">
                            <ul>
                                <li className="activetab">
                                    <Link to="/">Cricket</Link>
                                </li>
                                <li>
                                    <Link to="/">Football</Link>
                                </li>
                                <li>
                                    <Link to="/">Kabaddi</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="d-flex border-bottom-custom">
                            <ul className="d-flex matcheslist">
                                <li>All</li>
                                <li>International</li>
                                <li>T20 League</li>
                                <li>Domestic</li>
                            </ul>
                        </div>


                    </Container>

                </div>
                <div>
                    <Container>
                        <Row>
                            <Col md={8}>
                                <div className="leftside">
                                    <div className="tabsnew">
                                        <ul>
                                            <li className="activetabnew">Live</li>
                                            <li>Upcoming</li>
                                            <li>Result</li>
                                        </ul>
                                    </div>
                                    <div className="matchtwo">
                                        <div className="haedingview d-flex justify-content-between">
                                            <h3>CSA 4-Day Series Division 2</h3>
                                            <button className="viewall">View All</button>
                                        </div>
                                        <Row>
                                        
                                            <Col md={6}>
                                                <div className="card">
                                                    <div className="cardlive">
                                                        <div className="matchlist">
                                                            <div className="d-flex justify-content-start">
                                                                <h4 className="livematch"> Live</h4>
                                                                <div className="matchdetails">
                                                                    <h6>Vancouver Whitecaps FC vs Saint Louis City SC</h6>
                                                                    <p className="dateandtime">Thursday, 16 Nov | 01:30 PM</p>
                                                                </div>
                                                            </div>

                                                           <div className="d-flex justify-content-between align-items-center mt-2">
                                                           <div className="footballmatch">
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="d-flex matchgame">
                                                                        <div className="counteryflag mr-2">
                                                                            <img src={flag} className='img-fluid' />
                                                                        <p>Bangladesh</p>
                                                                        </div>
                                                                    </div>
                                                                   
                                                                </div>
                                                            </div>
                                                            <div className="scorefootball text-center">
                                                              <h6 className='m-0'><strong>  3-0</strong></h6>
                                                                <small className='m-0'>90.</small><br></br>
                                                                <small className='m-0'>Compleate</small>
                                                            </div>
                                                            <div className="footballmatch">
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="d-flex matchgame">
                                                                        <div className="counteryflag mr-2">
                                                                            <img src={flag} className='img-fluid' />
                                                                        <p>Bangladesh</p>
                                                                        </div>
                                                                    </div>
                                                                  
                                                                </div>
                                                            </div>
                                                           </div>
                                                            <div className="d-flex margin-top-10">

                                                                <div className="details">

                                                                    <p className='mb-0 text-primary'> Vancouver Whitecaps FC won</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="bgdashboard d-flex justify-content-between">
                                                            <button className="buttonnew">LIVE UPDATES</button>
                                                            <button className="buttonnew">OVERVIEW</button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="card">
                                                    <div className="cardlive">
                                                        <div className="matchlist">
                                                            <div className="d-flex justify-content-start ">
                                                                <h4 className="livematch"> Live</h4>
                                                                <div className="matchdetails">
                                                                    <h6>Vancouver Whitecaps FC vs Saint Louis City SC</h6>
                                                                    <p className="dateandtime">Thursday, 16 Nov | 01:30 PM</p>
                                                                </div>
                                                            </div>

                                                           <div className="d-flex justify-content-between align-items-center mt-2">
                                                           <div className="footballmatch">
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="d-flex matchgame">
                                                                        <div className="counteryflag mr-2">
                                                                            <img src={flag} className='img-fluid' />
                                                                        <p>Bangladesh</p>
                                                                        </div>
                                                                    </div>
                                                                   
                                                                </div>
                                                            </div>
                                                            <div className="scorefootball text-center">
                                                              <h6 className='m-0'><strong>  3-0</strong></h6>
                                                                <small className='m-0'>90.</small><br></br>
                                                                <small className='m-0'>Compleate</small>
                                                            </div>
                                                            <div className="footballmatch">
                                                                <div className="d-flex justify-content-between">
                                                                    <div className="d-flex matchgame">
                                                                        <div className="counteryflag mr-2">
                                                                            <img src={flag} className='img-fluid' />
                                                                        <p>Bangladesh</p>
                                                                        </div>
                                                                    </div>
                                                                  
                                                                </div>
                                                            </div>
                                                           </div>
                                                            <div className="d-flex margin-top-10">

                                                                <div className="details">

                                                                    <p className='mb-0 text-primary'> Vancouver Whitecaps FC won</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="bgdashboard d-flex justify-content-between">
                                                            <button className="buttonnew">LIVE UPDATES</button>
                                                            <button className="buttonnew">OVERVIEW</button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    
                                </div>
                            </Col>
                            <Col md={4}>
                <Rightnewssection />
              </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </>
    )
}
