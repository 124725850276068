import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import Tabheader from "./Tabheader";
import { Link } from "react-router-dom";
import video1 from "../../src/assets/image/video1.png";
import latestnews from "../../src/assets/image/latestnews.png";
import latestnews2 from "../../src/assets/image/latestnews2.png";
import axios from "axios";
import YouTube from "react-youtube";
import dateFormat from "dateformat";
import Rightnewssection from "./Rightnewssection";
import { useParams } from "react-router-dom";

 
export default function Video_Detail() {
  const [usersvideo, setUservideo] = useState([]);
  const [UserNews, setUserNews] = useState([]);
  const [singleVideo, setSingleVideo] = useState({});
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState({});
  const playvideo = localStorage.getItem("playvideo");
  

  const { id } = useParams();

  useEffect(() => {
    playVideo(id);
  }, [id]);

  useEffect(() => {
    playGame();
    playGamewww();
  }, []);

  const playGame = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/video-link`
      );
      const result = response.data.data;
      setUservideo(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const playGamewww = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URLWWW}/video-link`
      );
      const result = response.data.data;
      setUservideo(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
 

  const getVideoId = (url) => {
    if (!url) {
      return null;
    }

    const match = url.match(
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    return match && match[1];
  };

  const getThumbnailUrl = (url) => {
    const videoId = getVideoId(url);
    return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : "";
  };

  const playVideo = async (videoId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/video-single?video_id=${videoId}`
      );
      const videoDetails = response.data.data;

      setSingleVideo(videoDetails);
      setSelectedVideo(videoDetails);
    } catch (error) {
      console.error("Error fetching video data:", error);
    }
  };
  const playVideowww = async (videoId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URLWWW}/video-single?video_id=${videoId}`
      );
      const videoDetails = response.data.data;

      setSingleVideo(videoDetails);
      setSelectedVideo(videoDetails);
    } catch (error) {
      console.error("Error fetching video data:", error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <Tabheader /> */}
      <section className="Featurevideo" id="Featurevideo">
        <Container>
          {/* <div className="d-flex border-bottom-custom">
            <ul className="d-flex matcheslist">
            <li>
              <Link to="/Cricketlivescore" className="text-dark">
                {" "}
               LLC 2023
              </Link>
                </li>
                <li>
              <Link to="/Cricketlivescore" className="text-dark">
               Women's Big Bash League 2023
              </Link>
              </li>
              <li>Stats</li>
              <li>
              <Link to="/Predictiontips" className="text-dark">
                {" "}
                Today Match Prediction
              </Link>
              </li>
            </ul>
          </div> */}
          {/* <div className="feadturevideoheading">
            <p>Feature Video</p>
          </div> */}
        </Container>
        <div className="cricketscore videopage">
          <Container>
            <div className="Sectab">
              <ul>
                <li className="activetab">
                  <Link to="/Video_Detail">All</Link>
                </li>
                <li className="">
                  <Link to="/Featurevideo">Cricket</Link>
                </li>
                {/* <li>
                  <Link to="/Video_Detail">Football</Link>
                </li>
                <li>
                  <Link to="/Video_Detail">Kabaddi</Link>
                </li> */}
              </ul>
            </div>
          </Container>
        </div>
        <div className="newvideofeature">
          <Container>
            <Row>
              {/* ........................................All Video side .................. */}
              <Col md={8}>
                <div className="bg-color-new-video">
                  {/* <div className="video1">
                    <div className="haedingview d-flex justify-content-between">
                      <h3>Legends League Cricket</h3>
                      <button className="viewall">View All</button>
                    </div>
                  </div> */}

                  <Row>
                    <Col md={12}>
                      
                    {singleVideo ? (
                        <div
                          className="videodetails bg-white text-center"
                          style={{ width: "100%", height: "100%" }}
                        >
                          {/* <p> {singleVideo.title}</p> */}
                          {/* <p> ODI World Cup 2023: England vs Australia</p> */}
                          <p className="titleoverflow "> {singleVideo.title}</p>

                          <YouTube
                            videoId={getVideoId(selectedVideo.link)}
                            opts={{ width: "100%", height: "315" }}
                          />

                        </div>
                      ):(
                        <div
                        className="videodetails bg-white"
                        style={{ width: "100%", height: "100%" }}
                      >
                    
                    <p className="matchheadingnew"> ODI World Cup 2023: England vs Australia</p>
                        <iframe
                       src={`https://www.youtube.com/embed/${getVideoId(playvideo)}${getVideoId(playVideowww)}`}
                          frameBorder="0"
                          allowFullScreen
                          width={"100%"}
                          height={"315"}
                        ></iframe>
                                <p className="px-2">
                          {" "}
                          India go into their last game ahead of the home ODI
                          World Cup with several boxes ticked over the past
                          week. This final fixture was meant to serve as a dress
                          rehearsal for their first World Cup game against
                          Australia on October 8 in Chennai, but they go into it
                          with a severely depleted squad. Rohit Sharma and Virat
                          Kohli are back though, and will have India's
                          first-ever ODI series whitewash against Australia on
                          their agenda while adding to their momentum on the
                          road to the showpiece event
                        </p>
                        
                      </div>
                      )}
                      {/* <div
                        className=" "
                        style={{ width: "100%", height: "100%" }}
                      >
                        <p> ODI World Cup 2023: England vs Australia</p>
                        <iframe
                          width="100%"
                          height="315"
                          src="https://www.youtube.com/embed/vdNrPdeEuYQ?autoplay=1"
                          frameborder="0"
                          allowfullscreen
                        ></iframe>

                        <p>
                          {" "}
                          India go into their last game ahead of the home ODI
                          World Cup with several boxes ticked over the past
                          week. This final fixture was meant to serve as a dress
                          rehearsal for their first World Cup game against
                          Australia on October 8 in Chennai, but they go into it
                          with a severely depleted squad. Rohit Sharma and Virat
                          Kohli are back though, and will have India's
                          first-ever ODI series whitewash against Australia on
                          their agenda while adding to their momentum on the
                          road to the showpiece event
                        </p>
                      </div> */}
                    </Col>

                    {usersvideo.slice(1, 9).map((set, setIndex) => (
                      <Col md={6} key={setIndex}>
                        <div className="latestnewssec mt-2 videodetails">
                          <div
                            className="latestnewssection "
                            style={{ width: "100%", height: "100%" }}
                          >
                            {/* <YouTube videoId={getVideoId(set.link)} opts={{ width: "100%", height: "100%"  }}  /> */}
                          </div>
                          <Link to="/Video_Detail">
                            <div className="latestnewssection videoplaybtn">
                              {getThumbnailUrl(set.link) ? (
                                <img    
                                onClick={() => { playVideo(set.id); playVideowww(set.id); }}
                                  src={getThumbnailUrl(set.link)}
                                  alt={`Thumbnail for ${set.title}`}
                                />
                              ) : (
                                <iframe
                                  src={getThumbnailUrl(
                                    "https://www.youtube.com/embed/vdNrPdeEuYQ?autoplay=0"
                                  )}
                                  frameBorder="0"
                                  allowFullScreen
                                ></iframe>
                              )}
                            </div>
                          </Link>

                          <Link to="/Video_Detail" className="text-dark padding-left-right"     onClick={() => { playVideo(set.id); playVideowww(set.id); }}>
                          <span className="align-item-center">
                             Updated -
                           
                              {dateFormat(set.updated_at, "mmmm dS, yyyy")}
                           
                          </span>
                            <p>{set.title}</p>
                          </Link>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              </Col>
              <Col md={4}>
                {/* ........................................Latest News side .................. */}
             
           

              <Rightnewssection/>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
}
