import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import playerimage from "../../src/assets/image/playerimage.png";
import Photo_Missing from "../../src/assets/image/Photo-Missing.png";
import flag from "../../src/assets/image/india.png";

export default function Topplayer() {
  const [ranking, setRanking] = useState([]);
  const [baseUrl, setBaseUrl] = useState("");

  // const baseUrl =
  //   " https://sportspad.com/Admin/public/admin/uploads/top_player";
  // https://sportspad.com//Admin/api/v1
  useEffect(() => {
    Ranking_List();
    Ranking_Listwww();
  }, []);

  const Ranking_List = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/ranking-list`
      );
      const result = response.data.data;
      setRanking(result);

      // Set baseUrl based on the response
      setBaseUrl("https://sportspad.com/Admin/public/admin/uploads/top_player");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const Ranking_Listwww = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URLWWW}/ranking-list`
      );
      const result = response.data.data;
      setRanking(result);

      // Set baseUrl based on the response
      setBaseUrl(
        "https://www.sportspad.com/Admin/public/admin/uploads/top_player"
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const groupByRankingAndTeam = () => {
  //   // Group the data by ranking and team
  //   const groupedData = ranking.reduce((acc, player) => {
  //     const key = `${player.ranking}_${player.team}`;
  //     if (!acc[key]) {
  //       acc[key] = [];
  //     }
  //     acc[key].push(player);
  //     return acc;
  //   }, {});

  //   return Object.values(groupedData);
  // };
  const groupByRankingAndTeam = () => {
    const groupedData = ranking.reduce((acc, player) => {
      const rankingType = player.ranking.toLowerCase();
      const teamType = player.team.toLowerCase();

      if (!acc[rankingType]) {
        acc[rankingType] = { bating: [], bowling: [], allrounder: [] };
      }

      acc[rankingType][teamType].push(player);
      return acc;
    }, {});

    return Object.keys(groupedData).flatMap((rankingType) => {
      const rankingGroup = groupedData[rankingType];
      return ["bating", "bowling", "allrounder"].map(
        (teamType) => rankingGroup[teamType]
      );
    });
  };
  //   const sliderSettings = {
  //     dots: false,
  //     infinite: true,
  //     speed: 500,
  //     slidesToShow: 1,
  //     slidesToScroll: 1,
  //   };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    // nextArrow:
    // prevArrow: <i class="bi bi-chevron-right"></i>,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="Topplayer" id="Topplayer">
        <Container>
          <div className="headingnews">ICC Ranking</div>
        </Container>

        <div className="bgred">
          <Container>
            <Slider className="slick-theme livescoreowl" {...sliderSettings}>
              {groupByRankingAndTeam().map((group, groupIndex) => {
                // Check if any player in the group has a rank of 5
                const shouldShowCard = group.some((player) => player.rank == 5);

                return shouldShowCard ? (
                  <div className="item" key={groupIndex}>
                    <div className="playerdetails">
                      {group.slice(0, 1).map((player, playerIndex) => (
                        <div
                          className="d-flex justify-content-between bg-player"
                          key={`${groupIndex}_${playerIndex}`}
                        >
                          <div className="Playername">
                            <div className="odi d-flex align-items-center">
                              <h3>
                                {player.ranking.toUpperCase() === "T20"
                                  ? "T20I"
                                  : player.ranking.toUpperCase()}
                              </h3>
                              <p>
                                {player.team.charAt(0).toUpperCase() +
                                  player.team.slice(1) ===
                                "Bating"
                                  ? "Batter"
                                  : player.team.charAt(0).toUpperCase() +
                                      player.team.slice(1) ===
                                    "Bowling"
                                  ? "Bowler"
                                  : player.team.charAt(0).toUpperCase() +
                                      player.team.slice(1) ===
                                    "Allrounder"
                                  ? "All Rounder"
                                  : null}
                              </p>
                            </div>
                            {/* <span className="name">{player.player_name}</span> */}
                            <span className="name">
                              {player.player_name.split(" ")[0]}
                            </span>
                            <h2>
                              {player.player_name.split(" ").slice(1).join(" ")}
                            </h2>
                            {player.flage_image ? (
                              <img
                                src={`${baseUrl}/${player.flage_image}`}
                                alt="flag"
                              />
                            ) : (
                              <img src={flag} alt="fallback flag" />
                            )}
                          </div>
                          <div>
                            <div>
                              <img
                                src={`${baseUrl}/${player.player_image}`}
                                alt="playerimage"
                                style={{ height: "196px" }}
                                onError={(e) => {
                                  e.target.onerror = null; // Prevent infinite loop in case fallback image is also broken
                                  e.target.src = Photo_Missing; // Display fallback image if the main image fails
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="listplayer">
                        <ul>
                          {group.slice(0, 5).map((player, playerIndex) => (
                            <li key={playerIndex}>
                              <div className="d-flex justify-content-between padding-20">
                                <div className="d-flex align-items-center">
                                  <p>{player.rank}</p>
                                  <span className="dot"> </span>
                                  <div className="flagindia">
                                    {player.flage_image ? (
                                      <img
                                        src={`${baseUrl}/${player.flage_image}`}
                                        alt="flag"
                                        style={{
                                          height: "14px",
                                          width: "21px",
                                        }}
                                      />
                                    ) : (
                                      <img src={flag} alt="fallback flag" />
                                    )}
                                  </div>
                                  <p>
                                    <strong>{player.player_name}</strong>
                                  </p>
                                </div>
                                <p>
                                  <strong>{player.rating}</strong>
                                </p>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ) : null;
              })}
            </Slider>
          </Container>
        </div>
      </section>
    </>
  );
}
