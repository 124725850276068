import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Tabheader from './Tabheader'
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import flagfirst from '../../src/assets/image/flagfirst.png'
import flagsec from '../../src/assets/image/flagsec.png'
import ball from '../../src/assets/image/cricket-ball.png'
import latestnews from '../../src/assets/image/latestnews.png'
import latestnews2 from '../../src/assets/image/latestnews2.png'
import NoData_colored from '../../src/assets/image/NoData_colored.png'
import video1 from '../../src/assets/image/video1.png'
import playername from '../../src/assets/image/Ellipse 2.png'
export default function Cricketschedule() {
    return (
        <>
            <Tabheader />
            <section className="Cricketschedule" id="Cricketschedule">
                <div className="cricketscore">
                    <Container>
                        <div className="d-flex border-bottom-custom">
                            <ul className="d-flex matcheslist">
                                <li>Matches</li>
                                <li>Current and Future Fixtures</li>
                                <li>Matches By Day</li>
                                <li>Series Archive</li>
                            </ul>
                        </div>
                        <div className="Sectab">
                            <ul>
                                <li className="activetab">
                                <Link to="/Cricketschedule">Cricket</Link>
                                </li>
                                <li>
                                <Link to="/Footballschedule">Football</Link>
                                </li>
                                <li>
                                <Link to="/Kabaddischedule">Kabaddi</Link>
                                </li>
                            </ul>
                        </div>



                    </Container>

                </div>
                <div className='bg-color-new'>
                    <Container>
                        <div className="d-flex cricketschedule">
                            <ul className="d-flex">
                                <li>International</li>
                                <li>T20 League</li>
                                <li>T10 League</li>
                                <li>Domestic</li>
                                <li>Woman</li>
                            </ul>
                            <div className="series">
                            <div className="d-flex align-items-center">
                                <label>
                                    Series
                                </label>
                                <Dropdown>
                                        <Dropdown.Toggle variant="" id="dropdown-basic">
                                            Dropdown Button
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                        </Dropdown>
                            </div>
                            <div className="d-flex  align-items-center">
                                <label>
                                    All Team
                                </label>
                                <Dropdown>
                                        <Dropdown.Toggle variant="" id="dropdown-basic">
                                            Dropdown Button
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                        </Dropdown>
                            </div>
                            </div>
                        </div>
                        <Row>
                            <Col md={12}>
                                <div className="matchschedulelist">
                                  
                                    <div>
                                        <div className="datematch">
                                            <h3>WED, NOV 22 2023</h3>
                                        </div>
                                        <div className="matchdetails bg-white">
                                            <Row>
                                                <Col md={4}>
                                                    <div className='worldcup d-flex align-items-start'>
                                                        <h4>ICC Mens T20 World Cup Africa Region Qualifier</h4>
                                                    </div>
                                                </Col>
                                                <Col md={8}>
                                                    <div className="border-bottom-1">
                                                        <ul className='d-flex justify-content-between align-items-center pl-0'>
                                                            <li>
                                                                <div className="matchgame">
                                                                    <h5>Kenya vs Rwanda, 1st Match</h5>
                                                                    <small>United Cricket Club Ground, Windhoek</small>
                                                                </div>
                                                            </li>
                                                            <li className="d-flex">
                                                                <div className="flagmatch d-flex align-items-center">
                                                                    <strong>ZFM</strong>
                                                                    <div className="countryflag">
                                                                        <img src={flagfirst} alt="flag" />
                                                                    </div>
                                                                </div>
                                                                <div className="flagmatch d-flex align-items-center">
                                                                    VS
                                                                </div>
                                                                <div className="flagmatch d-flex align-items-center">
                                                                    <strong>ZFM</strong>
                                                                    <div className="countryflag">
                                                                        <img src={flagsec} alt="flag" />
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="timerdate">
                                                                <h4 className="timeup">

                                                                    01:00
                                                                    <span>PM</span>
                                                                    <span>07:30 AM GMT</span>
                                                                </h4>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="border-bottom-1">
                                                        <ul className='d-flex justify-content-between align-items-center pl-0'>
                                                            <li>
                                                                <div className="matchgame">
                                                                    <h5>Kenya vs Rwanda, 1st Match</h5>
                                                                    <small>United Cricket Club Ground, Windhoek</small>
                                                                </div>
                                                            </li>
                                                            <li className="d-flex">
                                                                <div className="flagmatch d-flex align-items-center">
                                                                    <strong>ZFM</strong>
                                                                    <div className="countryflag">
                                                                        <img src={flagfirst} alt="flag" />
                                                                    </div>
                                                                </div>
                                                                <div className="flagmatch d-flex align-items-center">
                                                                    VS
                                                                </div>
                                                                <div className="flagmatch d-flex align-items-center">
                                                                    <strong>ZFM</strong>
                                                                    <div className="countryflag">
                                                                        <img src={flagsec} alt="flag" />
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="timerdate">
                                                                <h4 className="timeup">

                                                                    01:00
                                                                    <span>PM</span>
                                                                    <span>07:30 AM GMT</span>
                                                                </h4>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="border-bottom-1">
                                                        <ul className='d-flex justify-content-between align-items-center pl-0'>
                                                            <li>
                                                                <div className="matchgame">
                                                                    <h5>Kenya vs Rwanda, 1st Match</h5>
                                                                    <small>United Cricket Club Ground, Windhoek</small>
                                                                </div>
                                                            </li>
                                                            <li className="d-flex">
                                                                <div className="flagmatch d-flex align-items-center">
                                                                    <strong>ZFM</strong>
                                                                    <div className="countryflag">
                                                                        <img src={flagfirst} alt="flag" />
                                                                    </div>
                                                                </div>
                                                                <div className="flagmatch d-flex align-items-center">
                                                                    VS
                                                                </div>
                                                                <div className="flagmatch d-flex align-items-center">
                                                                    <strong>ZFM</strong>
                                                                    <div className="countryflag">
                                                                        <img src={flagsec} alt="flag" />
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="timerdate">
                                                                <h4 className="timeup">

                                                                    01:00
                                                                    <span>PM</span>
                                                                    <span>07:30 AM GMT</span>
                                                                </h4>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="border-bottom-1">
                                                        <ul className='d-flex justify-content-between align-items-center pl-0'>
                                                            <li>
                                                                <div className="matchgame">
                                                                    <h5>Kenya vs Rwanda, 1st Match</h5>
                                                                    <small>United Cricket Club Ground, Windhoek</small>
                                                                </div>
                                                            </li>
                                                            <li className="d-flex">
                                                                <div className="flagmatch d-flex align-items-center">
                                                                    <strong>ZFM</strong>
                                                                    <div className="countryflag">
                                                                        <img src={flagfirst} alt="flag" />
                                                                    </div>
                                                                </div>
                                                                <div className="flagmatch d-flex align-items-center">
                                                                    VS
                                                                </div>
                                                                <div className="flagmatch d-flex align-items-center">
                                                                    <strong>ZFM</strong>
                                                                    <div className="countryflag">
                                                                        <img src={flagsec} alt="flag" />
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="timerdate">
                                                                <h4 className="timeup">

                                                                    01:00
                                                                    <span>PM</span>
                                                                    <span>07:30 AM GMT</span>
                                                                </h4>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </Col>
                                            </Row>

                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </>
    )
}
