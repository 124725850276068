import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container } from "react-bootstrap";
import Tabheader from "./Tabheader";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";

export default function Archive() {
  const seriesAllList =  localStorage.getItem("selectedSeriesName");
  const [usersrank, setUsersrank] = useState([]);
  const [selectedGameType, setSelectedGameType] = useState(null);
  const [showSeriesArchive, setShowSeriesArchive] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Live"); 
  useEffect(() => {
    playGameMatchlist();
  }, []);

  const handleGameTypeClick = (gameType) => {
    setSelectedGameType(gameType === "All" ? null : gameType);
  };

  const playGameMatchlist = async () => {
    try {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - 265);

      const customDate = currentDate.toISOString();
      const response = await axios.get(
        `https://apiv2.cricket.com.au/web/fixtures/yearfilter?startDateTime=${encodeURIComponent(
          customDate
        )}&jsconfig=eccn%3Atrue&format=json&limit=965`,
        {}
      );
      const result = response.data.fixtures;
      const result1 = response.data.fixtures[0].startDateTime;

      localStorage.setItem(
        "match",
        JSON.stringify(result.map((fixture) => fixture.id))
      );
      setUsersrank(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const gameTypes = ["All", ...new Set(usersrank.map((set) => set.gameType))];

  const filteredMatches = usersrank.filter(
    (set) =>
      (!selectedGameType || set.gameType === selectedGameType) &&
      // set.competition.name !== "Series Archive"
      // (!selectedGameType || set.gameType === selectedGameType) &&
      ((selectedTab === "Live" && set.isLive && !set.isCompleted) ||
        (selectedTab === "Upcoming" &&
          !set.isLive &&
          !set.isCompleted &&
          set.gameStatusId === "Prematch") ||
        (selectedTab === "Completed" && !set.isLive && set.isCompleted))
  );

  const handleButtonClick = (id, name) => {
    localStorage.setItem("selectedMatchId", id, name);
  };
  const groupedMatches = filteredMatches.reduce((acc, set) => {
    const competitionName = set.competition.name;

    if (!acc[competitionName]) {
      acc[competitionName] = [];
    }

    acc[competitionName].push(set);
    return acc;
  }, {});
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Tabheader />
      <section className="cricketlivescore" id="cricketlivescore">
        <div className="cricketscore">
          <Container>
            <div className="d-flex border-bottom-custom">
              <ul className="d-flex matcheslist">
                <li>
                  <Link
                    className="text-light"
                    onClick={() => setShowSeriesArchive(false)}
                  >
                    Matches By Day
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-light"
                    onClick={() => setShowSeriesArchive(true)}
                  >
                    Series Archive
                  </Link>
                </li>
              </ul>
            </div>
            <div className="Sectab">
              <ul>
                <li  className={selectedTab === "Live" ? "activetab text-dark" : "text-light"}    onClick={() => handleTabClick("Live")}>
            
                     
                   
                 
                      Live
                
                </li>
                <li    className={selectedTab === "Upcoming" ? "activetab" : "text-light"}  onClick={() => handleTabClick("Upcoming")}>
                      Upcoming
               
                </li>
                <li     className={selectedTab === "Completed" ? "activetab" : "text-light"} onClick={() => handleTabClick("Completed")}>
                 Completed
                 
                </li>
                 
              </ul>
            </div>
            <div className="archive">
              <div className="d-flex justify-content-between border-bottom-custom">
                <ul className="d-flex matcheslist">
                  {gameTypes.map((gameType, index) => (
                    <li
                      key={index}
                      onClick={() => handleGameTypeClick(gameType)}
                      className={gameType === selectedGameType ? "active" : ""}
                    >
                      {gameType.toUpperCase()}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Container>
        </div>
        <div className="archivepage">
          <Container>
            <div className="monthwise">
              <h3>Jan Month</h3>
            </div>
            {/* <div className="detailsarchive">
              {filteredMatches &&
                filteredMatches.length > 0 &&
                [
                  ...new Set(
                    filteredMatches.map((set) =>
                      dateFormat(set.startDateTime, "dd mmm, yyyy")
                    )
                  ),
                ].map((uniqueDate, dateIndex) => (
                  <div key={dateIndex} className="d-flex ">
                    <div className="monthyear">
                      <p>{uniqueDate}</p>
                    </div>
                    <div className="leaueside">
                      <div className="datewisematch">
                        <ul>
                          {filteredMatches
                            .filter(
                              (set) =>
                                dateFormat(set.startDateTime, "dd mmm, yyyy") === uniqueDate
                            )
                            .map((set, setIndex) => (
                              <Link
                                to={`/LiveScore`}
                                onClick={() => handleButtonClick(set.id, set.competition.name)}
                              >
                                <li key={setIndex}>
                                  <div className="matchnamewithleague">
                                    <p onClick={() => handleButtonClick(set.id)}>
                                      {set.competition.name}
                                    </p>
                                    <span>
                                      {dateFormat(set.startDateTime, "dd mmm, yyyy")}
                                    </span>
                                  </div>
                                </li>
                              </Link>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}

            </div> */}
            {showSeriesArchive ? (
              <div className="detailsarchive">
                {groupedMatches &&
                  Object.entries(groupedMatches).map(
                    ([competitionName, matches], competitionIndex) => (
                      <div key={competitionIndex}>
                        {/* <h4>{competitionName}</h4> */}
                        {matches.map((set, setIndex) => (
                          <Link
                            to={`/LiveScore`}
                            onClick={() =>
                              handleButtonClick(set.id, set.competition.name)
                            }
                          >
                            <div key={setIndex} className="d-flex ">
                              <div className="monthyear">

                                <p className="text-dark">
                                  {dateFormat(
                                    set.startDateTime,
                                    "dd mmm, yyyy"
                                  )}
                                </p>
                              </div>
                              <div className="leaueside">
                                <div className="datewisematch">
                                  <ul>
                                    <li className="card m-2">
                                      <div className="matchnamewithleague">
                                        {setIndex === 0 && (
                                          <p className="text-dark" onClick={() =>
                                            handleButtonClick(set.id)
                                          }>
                                            {competitionName}
                                          </p>
                                        )}
                                        {/* <p
                                          onClick={() =>
                                            handleButtonClick(set.id)
                                          }
                                        >
                                          {set.competition.name}
                                        </p> */}
                                        <small
                                          className="mt-1 text-dark "
                                          onClick={() =>
                                            handleButtonClick(set.id)
                                          }
                                        >
                                          {set.homeTeam.shortName} vs{" "}
                                          {set.awayTeam.shortName}
                                        </small>
                                      </div>
                                        <span className="text-dark">
                                          {dateFormat(
                                            set.startDateTime,
                                            "dd mmm, yyyy"
                                          )}
                                        </span>
                                        <span className="linknewssresult" >
                                      {
                                        set.resultText
                                      
                                      }
                                    </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </Link>
                        ))}
                      </div>
                    )
                  )}
              </div>
            ) : (
              <div className="detailsarchive">
                {filteredMatches &&
                  filteredMatches.length > 0 &&
                  [
                    ...new Set(
                      filteredMatches.map((set) =>
                        dateFormat(set.startDateTime, "dd mmm, yyyy")
                      )
                    ),
                  ].map((uniqueDate, dateIndex) => (
                    <div key={dateIndex} className="d-flex ">
                      <div className="monthyear">
                        <p>{uniqueDate}</p>
                      </div>
                      <div className="leaueside">
                        <div className="datewisematch">
                          <ul>
                            {filteredMatches
                              .filter(
                                (set) =>
                                  dateFormat(
                                    set.startDateTime,
                                    "dd mmm, yyyy"
                                  ) === uniqueDate
                              )
                              .map((set, setIndex) => (
                                <Link
                                  to={`/LiveScore`}
                                  onClick={() =>
                                    handleButtonClick(
                                      set.id,
                                      set.competition.name
                                    )
                                  }
                                >
                                  <li key={setIndex} className="card m-2">
                                    <div className="matchnamewithleague">
                                      <p
                                        onClick={() =>
                                          handleButtonClick(set.id)
                                        }
                                      >
                                        {set.competition.name}
                                      </p>
                                      <small
                                        className="mt-1 text-dark"
                                        onClick={() =>
                                          handleButtonClick(set.id)
                                        }
                                      >
                                        {set.homeTeam.shortName} vs{" "}
                                        {set.awayTeam.shortName}
                                      </small>
                                    </div>
                                    <span className="text-dark">
                                      {dateFormat(
                                        set.startDateTime,
                                        "dd mmm, yyyy"
                                      )}
                                    </span>
                                    <span className="linknewssresult" >
                                      {
                                        set.resultText
                                      
                                      }
                                    </span>
                                  </li>
                                </Link>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </Container>
        </div>
      </section>
    </>
  );
}
