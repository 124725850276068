import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Tabheader from "./Tabheader";
import { Link } from "react-router-dom";
import video1 from "../../src/assets/image/video1.png";
import latestnews from "../../src/assets/image/latestnews.png";
import latestnews2 from "../../src/assets/image/latestnews2.png";
import axios from "axios";
import YouTube from "react-youtube";
import dateFormat from "dateformat";
import { useNavigate } from 'react-router-dom';
import Rightnewssection from "./Rightnewssection";
import LatestNews from './News';
export default function Featurevideo() {
  const [usersvideo, setUservideo] = useState([]);
  const [UserNews, setUserNews] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    playGame();
    playGame2();
    playGamewww();
  }, []);

  const playGame = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/video-link`
      );
      const result = response.data.data;
      setUservideo(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const playGamewww = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URLWWW}/video-link`
      );
      const result = response.data.data;
      setUservideo(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getVideoId = (url) => {
    const match = url.match(
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    return match && match[1];
  };

  const getThumbnailUrl = (url) => {
    const videoId = getVideoId(url);
    return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : "";
  };
  const playGame2 = async () => {
    try {
      const response = await axios(
        // (`${process.env.REACT_APP_API_URL}/home.php`)
        `${process.env.REACT_APP_API_URL}/news-list`,
        {}
      );

      // console.warn(response.data.dtat);
      const res = response.data.data;
      setUserNews(res);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getNewsId = (url) => {
    const match = url.match(
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    return match && match[1];
  };
  const getThumbnailUrlnews = (url) => {
    const videoId = getNewsId(url);
    return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : "";
  };
  const playVideo = async (link,id,title) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/video-single?video_id=${link}`);
      const videoDetails = response.data.data;

      // console.log('Playing video:', videoDetails);


      navigate(`/Video_Detail`);
      localStorage.setItem("playvideo",link);
    
    } catch (error) {
      console.error('Error fetching video data:', error);
    }
  };
  const playVideowww = async (link,id,title) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URLWWW}/video-single?video_id=${link}`);
      const videoDetails = response.data.data;

      // console.log('Playing video:', videoDetails);


      navigate(`/Video_Detail`);
      localStorage.setItem("playvideo",link);
    
    } catch (error) {
      console.error('Error fetching video data:', error);
    }
  };
  return (
    <>
      <Tabheader />
      <section className="Featurevideo" id="Featurevideo">
        <Container>
          {/* <div className="d-flex border-bottom-custom">
          <ul className="d-flex matcheslist">
              
              <li>
              <Link
                Link to="/Cricketlivescore" className="text-dark">
                {" "}
                LLC 2023
              </Link>
                </li>

                <li>
              <Link to="/Cricketlivescore" className="text-dark">
                  Women's Big Bash League 2023
              </Link>
                  </li>

              <li>Stats</li>
                <li>
              <Link to="/Predictiontips" className="text-dark">
                {" "}
                  Today Match Prediction
              </Link>
                  </li>
            </ul>
          </div> */}
          <div className="feadturevideoheading">
            <p>Feature Video</p>
          </div>
        </Container>
        <div className="cricketscore videopage">
          <Container>
            <div className="Sectab">
            <ul>
                <li className="activetab">
                  <Link to="/Featurevideo">All</Link>
                </li>
                <li className="">
                  <Link to="/Featurevideo">Cricket</Link>
                </li>
                <li>
                  <Link to="/Featurevideo">Football</Link>
                </li>
                <li>
                  <Link to="/Featurevideo">Kabaddi</Link>
                </li>
              </ul>
            </div>
          </Container>
        </div>
        <div className="newvideofeature">
          <Container>
            <Row>
              <Col md={8}>
                <div className="bg-color-new-video">
                  {/* <div className="video1">
                    <div className="haedingview d-flex justify-content-between">
                      <h3>ALL LATEST VIDEO</h3>
                      <button className="viewall">View All</button>
                      <></>
                    </div>
                  </div> */}
                  <Row>
                  {usersvideo.slice(1, 11).map((set, setIndex) => (
                      <Col md={6} key={setIndex}>
                        <div className="latestnewssec mt-2">
                          <div
                            className="latestnewssection "
                            style={{ width: "100%", height: "100%" }}
                          >
                            {/* <YouTube videoId={getVideoId(set.link)} opts={{ width: "100%", height: "100%"  }}  /> */}
                          </div>
                          <Link to="/Video_Detail">
                            <div className="latestnewssection videoplaybtn">
                              {getThumbnailUrl(set.link) ? (
                                <img  onClick={() => { playVideo(set.link); playVideowww(set.link); }}
                                  src={getThumbnailUrl(set.link)}
                                  alt={`Thumbnail `}
                                />
                              ) : (
                                <iframe
                                  src={getThumbnailUrl(
                                    "https://www.youtube.com/embed/vdNrPdeEuYQ?autoplay=0"
                                  )}
                                  frameBorder="0"
                                  allowFullScreen
                                ></iframe>
                              )}
                            </div>
                          </Link>

                          <Link to="/Video_Detail" className="text-dark">
                          <span className="align-item-center">
                          <i class="fa fa-calendar" aria-hidden="true"></i> <span className="mx-1">{dateFormat(set.updated_at, "mmmm dS, yyyy")}</span>
                    </span>                           
                        
                            <p onClick={() => { playVideo(set.link); playVideowww(set.link); }}>{set.title}</p>
                          </Link>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              </Col>
              <Col md={4}>
              <Rightnewssection />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
}
