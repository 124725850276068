import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import Tabheader from "./Tabheader";
import { Link } from "react-router-dom";
import flag2 from "../../src/assets/image/flag2.png";
import ball from "../../src/assets/image/cricket-ball.png";
import latestnews from "../../src/assets/image/latestnews.png";
import latestnews2 from "../../src/assets/image/latestnews2.png";
import video1 from "../../src/assets/image/video1.png";
import playername from "../../src/assets/image/Ellipse 2.png";
import flag from "../../src/assets/image/flag2.png";
import Pklimage from "../../src/assets/image/pkl.jpg";
import dateFormat from "dateformat";
import Rightnewssection from "./Rightnewssection";
import Photo_Missing from "../../src/assets/image/Photo-Missing.png";
import NoData_colored from "../../src/assets/image/NoData_colored.png";
import search from "../../src/assets/image/search.gif";
import Dropdown from "react-bootstrap/Dropdown";

import GujaratFortunegiants from "../../src/assets/image/GujaratFortunegiants.jpg";
import Icccricketworldcup from "./Icccricketworldcup";
export default function Kabaddischedule() {
  const seriesAllList = localStorage.getItem("selectedSeriesName");
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [user, setUsers1] = useState([]);
  const [date, setDate] = useState();
  const [usersrank, setUsersrank] = useState([]);
  const [selectedGameType, setSelectedGameType] = useState(null);
  const [selectedTab, setSelectedTab] = useState("Live");
  const [selectedCompetition, setSelectedCompetition] = useState(null);
  const [data, setData] = useState(null);
  const imageUrlflag = "  https://static.cricbuzz.com/a/img/v1/25x18/i1/c";

  useEffect(() => {
    const fetchData = async () => {
      const options = {
        method: 'GET',
        url: 'https://cricbuzz-cricket.p.rapidapi.com/matches/v1/recent',

        headers: {
          'X-RapidAPI-Key': '8a09779cc5msh8d06b8be0a9d59bp19c20fjsn64a51b7d7f64',
    'X-RapidAPI-Host': 'cricbuzz-cricket.p.rapidapi.com'
        }
      };

      try {
        const response = await axios.request(options);
        const result = response.data.typeMatches
     
        console.warn(result);
        
        setData(result)

      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  const [dataseries, setdataseries] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const options = {
        method: 'GET',
        url: 'https://cricbuzz-cricket.p.rapidapi.com/series/v1/6927',

        headers: {
          'X-RapidAPI-Key': '38854d7c62mshb0ede427a67411cp103359jsn655f2aec1f55',
    'X-RapidAPI-Host': 'cricbuzz-cricket.p.rapidapi.com'
        }
      };

      try {
        const response = await axios.request(options);
        const result = response.data.matchDetails
     
        console.warn(result);
        
        setdataseries(result)

      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
 
  useEffect(() => {
    playGameMatchlist();
  }, []);

  const handleGameTypeClick = (gameType) => {
    setSelectedGameType(gameType === "All" ? null : gameType);
  };

  const handleCompetitionClick = (competitionName) => {
    setSelectedCompetition(competitionName === "All" ? null : competitionName);
  };
  const playGameMatchlist = async () => {
    try {
      setLoading(true);
      const currentDate = new Date();

      // Subtract two days from the current date
      currentDate.setDate(currentDate.getDate() - 265);

      // Format the date in the required format ("YYYY-MM-DDTHH:mm:ss.SSSZ")
      const customDate = currentDate.toISOString();
      // const customDate = "2024-01-13T00:00:00.000Z";
      const response = await axios.get(
        `https://apiv2.cricket.com.au/web/fixtures/yearfilter?startDateTime=${encodeURIComponent(
          customDate
        )}&jsconfig=eccn%3Atrue&format=json&limit=965`,
        {}
      );
      const result = response.data.fixtures;

      const result1 = response.data.fixtures[0].startDateTime;
      setDate(result1);

      localStorage.setItem(
        "match",
        JSON.stringify(result.map((fixture) => fixture.id))
      );
      setUsersrank(result);
      console.warn(result);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const gameTypes = ["All", ...new Set(usersrank.map((set) => set.gameType))];

  // const filteredMatches = usersrank.filter(
  //   (set) => !selectedGameType || set.gameType === selectedGameType
  // );

  // const filteredMatches = usersrank
  //   .filter(
  //     (set) =>
  //       (!selectedGameType || set.gameType === selectedGameType) &&
  //       ((selectedTab === "Live" && set.isLive && !set.isCompleted) ||
  //         (selectedTab === "Upcoming" &&
  //           !set.isLive &&
  //           !set.isCompleted &&
  //           set.gameStatusId === "Prematch") ||
  //         (selectedTab === "Completed" && set.isCompleted)) &&
  //       (!selectedCompetition || set.competition.name === selectedCompetition)
  //   )
  //   .sort((a, b) =>
  //     b.isCompleted ? new Date(b.startDateTime) - new Date(a.startDateTime) : 0
  //   );

  // ...

  const filteredMatches = usersrank
    .filter(
      (set) =>
        (!selectedGameType || set.gameType === selectedGameType) &&
        ((selectedTab === "Live" && set.isLive && !set.isCompleted) ||
          (selectedTab === "Upcoming" &&
            !set.isLive &&
            !set.isCompleted &&
            set.gameStatusId === "Prematch") ||
          (selectedTab === "Completed" && set.isCompleted)) &&
        (!selectedCompetition ||
          set.competition.name === selectedCompetition) &&
        (!seriesAllList || set.competition.name === seriesAllList)
    )
    .sort((a, b) =>
      b.isCompleted ? new Date(b.startDateTime) - new Date(a.startDateTime) : 0
    );
  const handleClearButtonClick = () => {
    localStorage.removeItem("selectedMatchId");
    localStorage.removeItem("selectedSeriesName");
    // Add any other localStorage keys you want to clear
    // ...

    // After clearing, you may want to reload the page or update the state
    window.location.reload(); // Reload the page
  };
  // ...

  const handleButtonClick = (id) => {
    localStorage.setItem("selectedMatchId", id);
  };
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };
  const uniqueNames = Array.from(
    new Set(usersrank.map((set) => set.competition.name))
  );
  uniqueNames.reverse();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Tabheader />
      <section className="cricketlivescore" id="cricketlivescore">
        <div className="cricketscore">
          <Container>
            <div className="d-flex border-bottom-custom">
              <ul className="d-flex matcheslist">
                {/* <li>Matches</li>
                <li >Current and Future Fixtures</li> */}
                <li>
                  {" "}
                  <Link to="/Archive" className="text-light">
                    Matches By Day
                  </Link>
                </li>
                <li>
                  <Link to="/Archive" className="text-light">
                    Series Archive
                  </Link>
                </li>
              </ul>
            </div>

            {/* <div className="d-flex border-bottom-custom">
              <div className="selectid">
                <div className="select ">
                  <ul className="">
                    {uniqueNames.map((competition, index) => (
                      <li
                        key={index}
                        onClick={() => handleCompetitionClick(competition)}
                        // className={competition === selectedCompetition ? "active" : ""}
                    
                      >
                        {competition}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div> */}
            <div className="Sectab d-flex align-items-center all_tabs">
              <ul>
                <li
                  className={
                    selectedTab === "Live"
                      ? "activetab text-dark"
                      : "text-light"
                  }
                  onClick={() => handleTabClick("Live")}
                >
                  Live
                </li>
                <li
                  className={
                    selectedTab === "Upcoming"
                      ? "activetab text-dark"
                      : "text-light"
                  }
                  onClick={() => handleTabClick("Upcoming")}
                >
                  Upcoming
                </li>
                <li
                  className={
                    selectedTab === "Completed"
                      ? "activetab text-dark"
                      : "text-light"
                  }
                  onClick={() => handleTabClick("Completed")}
                >
                  Completed
                </li>
              </ul>
              <Dropdown>
                <Dropdown.Toggle
                  className="text-dark bold dropdowntoggle"
                  id="dropdown-basic"
                >
                  Select Series
                </Dropdown.Toggle>

                <Dropdown.Menu className="serieslistmenu">
                {data && data.map((gameType, index) => (
                      gameType.seriesMatches.map((series, seriesIndex) => (
                    <Dropdown.Item
                    key={seriesIndex}
                      onClick={() => handleCompetitionClick(series.seriesAdWrapper && series.seriesAdWrapper.seriesId)}
                    >
                
                      {series.seriesAdWrapper && series.seriesAdWrapper.seriesName}
                    </Dropdown.Item>
              ))
              ))}
                </Dropdown.Menu>
              </Dropdown>
        
              <ul>
                <li
                  className="btn  ml-2 activetab text-dark"
                  onClick={handleClearButtonClick}
                >
                  Clear
                </li>
              </ul>
              {/* <button
          className="btn btn-secondary ml-2"
          onClick={handleClearButtonClick}
        >
          Clear
        </button> */}
            </div>
            <div className="d-flex border-bottom-custom">
              <ul className="d-flex matcheslist ul_scroll">
              {data && data.map((gameType, index) => (
                  <li
                    key={index}
                    onClick={() => handleGameTypeClick(gameType.matchType)}
                    className={gameType === selectedGameType ? "active" : ""}
                  >
                    {gameType.matchType}
                  </li>
                ))}
              </ul>
            </div>
  


          </Container>
        </div>
        <div>
          <Container>
            <Row>
          
             
                <Col md={8}>
                  <div className="pkl">
                    <div className="matchtwo">
                      <div className="haedingview d-flex justify-content-between  mb-3">
                        <h3></h3>

                        <button className="viewall"></button>
                      </div>
                    
                        <Row>
                          {filteredMatches &&
                            filteredMatches.length > 0 &&
                            filteredMatches.map((set, setIndex) => (
                              <Col md={6} className="mb-2">
                                <Link
                                  to="/LiveScore"
                                  className="text-dark"
                                  // onClick={() => handleButtonClick(set.id)}
                                >
                                  <div className="card">
                                    <div className="cardlive">
                                      <div className="matchlist">
                                        <div className="d-flex justify-content-start text-center "></div>
                                        <div className="d-flex justify-content-between align-item-center">
                                          <h6>
                                            {set.isLive && !set.isCompleted && (
                                              <h4 className="livematch">
                                                Live
                                              </h4>
                                            )}
                                            {!set.isLive && set.isCompleted && (
                                              <h4 className="completedmatch text-primary bg-light">
                                                Completed
                                              </h4>
                                            )}
                                            {!set.isLive &&
                                              !set.isCompleted &&
                                              set.gameStatusId ===
                                                "Prematch" && (
                                                <h4 className="completedmatch text-success bg-light">
                                                  Upcoming
                                                </h4>
                                              )}
                                          </h6>
                                          <p className=" matchnamelistfor">
                                            {set.competition.name}
                                          </p>
                                        </div>

                                        <div className="d-flex justify-content-between align-items-center mt-2">
                                          <div className="footballmatch">
                                            <div className="d-flex justify-content-between">
                                              <div className="d-flex matchgame">
                                                <div className="counteryflag mr-2">
                                                  <img
                                                    src={`https://static-files.cricket-australia.pulselive.com/flag/54/${set.homeTeam.id}.png`}
                                                    className="img-fluid  "
                                                    style={{
                                                      border:
                                                        "0.25px solid #adada8",
                                                      height: "40px",
                                                      width: "40px",
                                                    }}
                                                  />

                                                  <p>
                                                    {set.homeTeam.shortName}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="scorefootball text-center">
                                            <h5 className="m-0">
                                              <p className="text-secondary">
                                                {" "}
                                                Vs
                                              </p>
                                            </h5>
                                            <small className="m-0"> </small>
                                            <br></br>
                                          </div>
                                          <div className="footballmatch">
                                            <div className="d-flex justify-content-between">
                                              <div className="d-flex matchgame">
                                                <div className="counteryflag mr-2">
                                                  <img
                                                    src={`https://static-files.cricket-australia.pulselive.com/flag/54/${set.awayTeam.id}.png`}
                                                    className="img-fluid  "
                                                    style={{
                                                      border:
                                                        "0.25px solid #adada8",
                                                      height: "40px",
                                                      width: "40px",
                                                    }}
                                                  />
                                                  <p>
                                                    {set.awayTeam.shortName}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="d-flex margin-top-10">
                                          <div className="">
                                            <p className=" text-primary">
                                              {" "}
                                              {set.isLive === false ? (
                                                <>
                                                  {new Date(
                                                    set.startDateTime
                                                  ).toLocaleDateString(
                                                    "en-US",
                                                    {
                                                      weekday: "short",
                                                      day: "numeric",
                                                      month: "short",
                                                    }
                                                  )}
                                                  <strong className="mx-1">
                                                    |
                                                  </strong>
                                                  {new Date(
                                                    set.startDateTime
                                                  ).toLocaleTimeString(
                                                    "en-US",
                                                    {
                                                      hour: "2-digit",
                                                      minute: "2-digit",
                                                    }
                                                  )}
                                                </>
                                              ) : (
                                                <p className="result">
                                                  {" "}
                                                  {set.resultText}
                                                </p>
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </Col>
                            ))}
                        </Row>
                     
                    </div>
                  </div>
                </Col>
                <Col md={8}>
                  <div className="pkl">
                    <div className="matchtwo">
                      <div className="haedingview d-flex justify-content-between  mb-3">
                        <h3></h3>

                        <button className="viewall"></button>
                      </div>
                   
                        <Row>
                        {data && data.map((gameType) => (
                         gameType.seriesMatches.map((series) => (
                                 series.seriesAdWrapper && series.seriesAdWrapper.matches.map((match, index) => (
                              <Col md={6} className="mb-2" key={index}>
                                <Link
                                  to="/LiveScore"
                                  className="text-dark"
                                  onClick={() => handleButtonClick(match.matchInfo.matchId)}
                                >
                                
                                  <div className="card"     onClick={() => handleButtonClick(match.matchInfo.matchId)}>
                                    <div className="cardlive">
                                      <div className="matchlist">
                                        <div className="d-flex justify-content-start text-center "></div>
                                        <div className="d-flex justify-content-between align-item-center">
                                          <h6>
                                            {  match.matchInfo.state === "Live" && (
                                              <h4 className="livematch">
                                                Live
                                              </h4>
                                            )}
                                            {match.matchInfo.state === "Complete" && (
                                              <h4 className="completedmatch text-primary bg-light">
                                                Completed
                                              </h4>
                                            )}
                                           { match.matchInfo.state === 
                                                "Prematch" && (
                                                <h4 className="completedmatch text-success bg-light">
                                                  Upcoming
                                                </h4>
                                              )} 
                                          </h6>
                                          <p className=" matchnamelistfor">
                                          {/* {series.seriesName} */}
                                          {match.matchInfo.seriesName} |
                                          {match.matchInfo.matchDesc}
                                          </p>
                                        </div>

                                        <div className="d-flex justify-content-between align-items-center mt-2">
                                          <div className="footballmatch">
                                            <div className="d-flex justify-content-between">
                                              <div className="d-flex matchgame">
                                                <div className="counteryflag mr-2">
                                                  <img
                                                    // src={Photo_Missing}
                                                    src={
                                                      match.matchInfo.team1.imageId
                                                        ? `${imageUrlflag}${match.matchInfo.team1.imageId}/172123.jpg`
                                                        : Photo_Missing
                                                    }
                                                    className="img-fluid  "
                                                    style={{
                                                      border:
                                                        "0.25px solid #adada8",
                                                      height: "40px",
                                                      width: "40px",
                                                    }}
                                                  />

                                                  <p>
                                                    {match.matchInfo.team1.teamName}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="scorefootball text-center">
                                            <h5 className="m-0">
                                              <p className="text-secondary">
                                                {" "}
                                                Vs
                                              </p>
                                            </h5>
                                            <small className="m-0"> </small>
                                            <br></br>
                                          </div>
                                          <div className="footballmatch">
                                            <div className="d-flex justify-content-between">
                                              <div className="d-flex matchgame">
                                                <div className="counteryflag mr-2">
                                                  <img
                                                    //  src={Photo_Missing}
                                                    src={
                                                      match.matchInfo.team2.imageId
                                                        ? `${imageUrlflag}${match.matchInfo.team2.imageId}/172123.jpg`
                                                        : Photo_Missing
                                                    }
                                                    className="img-fluid  "
                                                    style={{
                                                      border:
                                                        "0.25px solid #adada8",
                                                      height: "40px",
                                                      width: "40px",
                                                    }}
                                                  />
                                                  <p>
                                                    {match.matchInfo.team2.teamName}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="d-flex margin-top-10">
                                          <div className="">
                                            <p className=" text-primary">
                                              {" "}
                                              {match.matchInfo.state === "Live" ? (
                                                <>
                                                {new Date(parseInt(match.matchInfo.startDate)).toDateString()}
                                                </>
                                              ) : (
                                                <p className="result">
                                                  {" "}
                                                  {match.matchInfo.status}
                                                </p>
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </Col>
                              ))
                              ))
                            ))}
                        </Row>
                   
                    </div>
                  </div>
                </Col>

              <Col md={4}>
                <Rightnewssection />
              </Col>
            </Row>
          </Container>
         
        </div>
     
      </section>
    </>
  );
}
