import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Something from "react-bootstrap-tabs";
import Tabheader from "./Tabheader";
import { Link } from "react-router-dom";
import Pklimage from "../../src/assets/image/pkl.jpg";
import playerimagevirat from "../../src/assets/image/playerimagevirat.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import flag from "../../src/assets/image/flag2.png";
import flagnew from "../../src/assets/image/flagnew.png";
import Icccricketworldcup from "./Icccricketworldcup";

// import ballphoto from "../../src/assets/image/ballphoto.png";
import ballphoto from "../../src/assets/image/ballphoto.jpg";
import Bat from "../../src/assets/image/Bat.jpg";
import Rightnewssection from "./Rightnewssection";
import axios from "axios";
import YouTube from "react-youtube";
import dateFormat from "dateformat";
import playerimage from "../../src/assets/image/playerimage.png";
import Photo_Missing from "../../src/assets/image/Photo-Missing.png";
import { Tabs, Tab } from "react-bootstrap-tabs";
export default function Legendsleaguecricket() {
  const playviselectedMatchIddeo = localStorage.getItem("selectedMatchId");

  const [usersvideo, setUservideo] = useState([]);
  const [usersrank, setUsersrank] = useState([]);
  const [UserNews, setUserNews] = useState([]);
  const [users, setUsers] = useState([]);
  const [user, setUsers1] = useState([]);
  // const [commentrys, setCommentry] = useState([]);
  const [Commentryplayers, setCommentryplayers] = useState([]);
  const [playerss, setPlayers] = useState([]);

  const [bestman, setBestman] = useState([]);
  const [bestman1, setBestman1] = useState([]);
  const [Bowlerss, setBowlers] = useState([]);
  const [Bowlerss1, setBowlers1] = useState([]);
  const [BestmanIndex2, setBestmanIndex2] = useState([]);
  const [BowlersIndex2, setBowlersIndex2] = useState([]);
  const [BestmanIndex3, setBestmanIndex3] = useState([]);
  const [BowlersIndex3, setBowlersIndex3] = useState([]);
  const [MatechDetail, setMatechDetail] = useState([]);

  // const [selectedMatch, setSelectedMatch] = useState(null);
  const imageUrl = "https://i.cricketcb.com/stats/img/faceImages/";

  const [activeButton, setActiveButton] = useState("info");

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };
  const [selectedMatchIndex, setSelectedMatchIndex] = useState(null);

  useEffect(() => {
    playGame();
    playGame2();
    // Commentry();
    scorecardMatch();
  }, [1000]);

  const [SSscoreCard, setscoreCard] = useState([]);
  const [matchstatus, setstatus] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const options = {
        method: "GET",
        url: `https://cricbuzz-cricket.p.rapidapi.com/mcenter/v1/${playviselectedMatchIddeo}/scard`,
        headers: {
          "X-RapidAPI-Key":
            "8a09779cc5msh8d06b8be0a9d59bp19c20fjsn64a51b7d7f64",
          "X-RapidAPI-Host": "cricbuzz-cricket.p.rapidapi.com",
        },
      };
  
      try {
        const response = await axios.request(options);
        const result = response.data;
        console.warn(result);
        if (result && result.scoreCard) {
          setscoreCard(result);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData();
  }, []);
  
  useEffect(() => {
    const fetchData = async () => {
      const options = {
        method: "GET",
        url: `https://cricbuzz-cricket.p.rapidapi.com/mcenter/v1/${playviselectedMatchIddeo}`,
        headers: {
          "X-RapidAPI-Key":
            "8a09779cc5msh8d06b8be0a9d59bp19c20fjsn64a51b7d7f64",
          "X-RapidAPI-Host": "cricbuzz-cricket.p.rapidapi.com",
        },
      };
  
      try {
        const response = await axios.request(options);
        console.warn(response.data.matchInfo);
        const matches = response.data.matchInfo;
        if (matches) {
          setMatechDetail(matches);
        }
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData();
  }, []);
  
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const options = {
  //       method: 'GET',
  //       url: 'https://cricbuzz-cricket.p.rapidapi.com/series/v1/6297/squads/41202',
  //      headers: {
  //       'X-RapidAPI-Key': 'af0f920440msh05534737b20c2eep15cab2jsnfc1afa17f0e2',
  //       'X-RapidAPI-Host': 'cricbuzz-cricket.p.rapidapi.com'
  //       }
  //     };

  //     try {
  //       const response = await axios.request(options);
  //       // setData(response.data);
  //       console.warn(response.data)
  //       // const matches = response.data.matchInfo
  //       // setMatechDetail(matches)
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   fetchData();
  // }, []);
  const playGame = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/video-link`
      );
      const result = response.data.data;
      setUservideo(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getVideoId = (url) => {
    const match = url.match(
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    return match && match[1];
  };

  const getThumbnailUrl = (url) => {
    const videoId = getVideoId(url);
    return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : "";
  };
  const playGame2 = async () => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_API_URL}/news-list`,
        {}
      );

      // console.warn(response.data.dtat);
      const res = response.data.data;
      setUserNews(res);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getNewsId = (url) => {
    const match = url.match(
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    return match && match[1];
  };
  const getThumbnailUrlnews = (url) => {
    const videoId = getNewsId(url);
    return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : "";
  };

  useEffect(() => {
    setSelectedMatchIndex(1);
  }, [user]);

  const handleMatchInfoClick = (matchIndex) => {
    setSelectedMatchIndex(matchIndex);
  };

  const scorecardMatch = async () => {
    try {
      const response = await axios.get(
        `https://apiv2.cricket.com.au/web/views/scorecard?fixtureId=${playviselectedMatchIddeo}`,
        {}
      );

      const result = response.data;
      // console.warn(result);
      setCommentryplayers(result);

      const results = response.data.Fixture;
      console.warn(result);

      const playerss = response.data.Players;
      const resplayerss = JSON.stringify(playerss);
      const objectplayerss = JSON.parse(resplayerss);
      // const playerss = response.data.Players;
      setPlayers(objectplayerss);
      // console.warn(objectplayerss)
      // index 0 Batsmen
      // const bestman = response.data.Fixture.Innings[0];
      // const res = JSON.stringify(bestman.Batsmen);
      // const objectRes = JSON.parse(res);
      // setBestman(objectRes);
      // // console.warn(objectRes);
      // // index 0 Bowlers
      // const bowlers = response.data.Fixture.Innings[0];
      // const resBowlers = JSON.stringify(bowlers.Bowlers);
      // const objectResBowlers = JSON.parse(resBowlers);
      // // console.warn(objectResBowlers);
      // setBowlers(objectResBowlers);
      // // index 1 Batsmen
      // const bestman1 = response.data.Fixture.Innings[1];
      // const ress = JSON.stringify(bestman1.Batsmen);
      // const objectRess = JSON.parse(ress);
      // setBestman1(objectRess);
      // console.warn(objectRess);

      // // index 1 Bowlers
      // const bowlersss = response.data.Fixture.Innings[1];
      // const resBowlersss = JSON.stringify(bowlersss.Bowlers);
      // const objectResBowlersss = JSON.parse(resBowlersss);
      // // console.warn(objectResBowlersss);
      // setBowlers1(objectResBowlersss);
      // // index 2 Batsmen

      // const bestmanindex2 = response.data.Fixture.Innings[2];
      // const resindex2 = JSON.stringify(bestmanindex2.Batsmen);
      // const objectResindex2 = JSON.parse(resindex2);
      // setBestmanIndex2(objectResindex2);

      // // index 2 Bowlers
      // const bowlersindex2 = response.data.Fixture.Innings[2];
      // const resBowlersindex2 = JSON.stringify(bowlersindex2.Bowlers);
      // const objectResBowlersindex2 = JSON.parse(resBowlersindex2);
      // // console.warn(objectResBowlersindex2);
      // setBowlersIndex2(objectResBowlersindex2);

      // // index 3 Batsmen
      // const bestmanindex3 = response.data.Fixture.Innings[3];
      // const resindex3 = JSON.stringify(bestmanindex3.Batsmen);
      // const objectResindex3 = JSON.parse(resindex3);

      // setBestmanIndex3(objectResindex3);
      // // index 3 Bowlers
      // const bowlersindex3 = response.data.Fixture.Innings[3];
      // const resBowlersindex3 = JSON.stringify(bowlersindex3.Bowlers);
      // const objectResBowlersindex3 = JSON.parse(resBowlersindex3);
      // // console.warn(objectResBowlersindex3);
      // setBowlersIndex3(objectResBowlersindex3);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function formatTeamName(teamName) {
    const words = teamName.split(" ");
    return words.slice(0, 2).join(" ");
  }

  return (
    <>
      <Tabheader />
      <section id="legendleaguecricket">
        <div>
          <Container>
            <Row>
              <Col md={12}>
                <div className="pkl">
                  <ul className="pl-0 d-flex">
                    <li className="Home">
                      <Link to="/" className="text-dark">
                        Home
                      </Link>
                    </li>
                    <li>
                      <i class="bi bi-chevron-double-right"></i>
                    </li>
                    &nbsp;
                  </ul>
                  <div className="card box-card p-0">
                    <div class="item text-light">
                      <div
                        className="cardlive"
                        style={{
                          background:
                            "linear-gradient(90deg, hsl(243, 100%, 7%) 0%, rgba(9,39,121,0.8631827731092436) 35%,  hsl(243, 100%, 7%)  100%)",
                        }}
                      >
                        <div className="d-flex justify-content-between align-item-center">
                          <h6>
                            <p className="  matchnamelistfor">
                              {SSscoreCard &&
                                SSscoreCard.matchHeader &&
                                SSscoreCard.matchHeader.seriesName}

                              <strong className="mx-1 ">|</strong>
                              {SSscoreCard &&
                                SSscoreCard.matchHeader &&
                                SSscoreCard.matchHeader.matchDescription}
                            </p>
                          </h6>

                          <h6 className="d-flex justify-content-between align-items-center livescroreupdate blink track">
                            <span
                              className="track"
                              style={{
                                backgroundColor:
                                  SSscoreCard &&
                                  SSscoreCard.matchHeader &&
                                  SSscoreCard.matchHeader.state === "Live"
                                    ? "updatelive"
                                    : SSscoreCard &&
                                      SSscoreCard.matchHeader &&
                                      SSscoreCard.matchHeader === "Prematch"
                                    ? "green"
                                    : SSscoreCard &&
                                      SSscoreCard.matchHeader &&
                                      SSscoreCard.matchHeader === "Complete"
                                    ? "blue"
                                    : "black",
                                color: "white",
                                // Set text color to white or any other color you prefer
                              }}
                            >
                              {SSscoreCard &&
                              SSscoreCard.matchHeader &&
                              SSscoreCard.matchHeader.state === "Live" ? (
                                <span
                                  style={{ background: "red" }}
                                  className="track"
                                >
                                  LIVE
                                </span>
                              ) : SSscoreCard &&
                                SSscoreCard.matchHeader &&
                                SSscoreCard.matchHeader.state === "Prematch" ? (
                                "UPCOMING"
                              ) : SSscoreCard &&
                                SSscoreCard.matchHeader &&
                                SSscoreCard.matchHeader.state === "Lunch" ? (
                                "Lunch BREAK"
                              ) : SSscoreCard &&
                                SSscoreCard.matchHeader &&
                                SSscoreCard.matchHeader.state === "Dinner" ? (
                                "Dinner BREAK"
                              ) : SSscoreCard &&
                                SSscoreCard.matchHeader &&
                                SSscoreCard.matchHeader.state === "Complete" ? (
                                "Completed"
                              ) : SSscoreCard &&
                                SSscoreCard.matchHeader &&
                                SSscoreCard.matchHeader.state ===
                                  "BetweenInnings" ? (
                                " INNING BREAK"
                              ) : SSscoreCard &&
                                SSscoreCard.matchHeader &&
                                SSscoreCard.matchHeader.state === "Stumps" ? (
                                "STUMPS"
                              ) : null}
                            </span>
                          </h6>
                        </div>

                        <div className="matchone">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex matchgame align-items-center">
                              <div className="counteryflag mr-2 ">
                                <img
                                  src={Photo_Missing}
                                  
                                  className="img-fluid  "
                                  style={{
                                    border: "0.25px solid #adada8",
                                    height: "40px",
                                  }}
                                />
                              </div>
                              <p className="mx-2">
                                {/* {Commentryplayers.Fixture.HomeTeam.ShortName} */}
                                {SSscoreCard &&
                                  SSscoreCard.scoreCard && SSscoreCard.scoreCard[0] &&
                                  SSscoreCard.scoreCard[0].batTeamDetails
                                    .batTeamShortName}
                              </p>
                            </div>
                            <div className="over d-flex justify-content-between align-items-center">
                              <div className="counteryflag mr-2">
                                <img
                                  src={Photo_Missing}
                                  style={{
                                    border: "0.25px solid #adada8",
                                    height: "40px",
                                    width: "40px",
                                  }}
                                  className="img-fluid  "
                                />
                              </div>
                              <p className="mx-2">
                                {SSscoreCard &&
                               SSscoreCard.scoreCard && SSscoreCard.scoreCard[0] &&
                                  SSscoreCard.scoreCard[1].batTeamDetails
                                    .batTeamShortName}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="matchone align-items-center"></div>
                        <div className="matchone">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex matchgame align-items-center ">
                              <div>
                                <div>
                                <p className="">
  <strong>
    {SSscoreCard &&
      SSscoreCard.scoreCard &&
      SSscoreCard.scoreCard[0] &&
      SSscoreCard.scoreCard[0].scoreDetails
        ? SSscoreCard.scoreCard[0].scoreDetails.runs
        : ""}
  </strong>
  <>/</>
  <strong>
    {SSscoreCard &&
      SSscoreCard.scoreCard &&
      SSscoreCard.scoreCard[0] &&
      SSscoreCard.scoreCard[0].scoreDetails
        ? SSscoreCard.scoreCard[0].scoreDetails.wickets
        : ""}
  </strong>
  <strong className="mx-1">
    (
    <>
      <strong className="mx-1">
        <>
          {SSscoreCard &&
            SSscoreCard.scoreCard &&
            SSscoreCard.scoreCard[0] &&
            SSscoreCard.scoreCard[0].scoreDetails
              ? SSscoreCard.scoreCard[0].scoreDetails.overs
              : ""}
        </>
      </strong>
    </>
    )
  </strong>
  <br />
  <strong>
    {SSscoreCard &&
      SSscoreCard.scoreCard &&
      SSscoreCard.scoreCard[2] &&
      SSscoreCard.scoreCard[2].scoreDetails
        ? SSscoreCard.scoreCard[2].scoreDetails.runs
        : ""}
  </strong>
  {SSscoreCard &&
      SSscoreCard.scoreCard &&
      SSscoreCard.scoreCard[2] &&
      SSscoreCard.scoreCard[2].scoreDetails &&
  <>/</>
}
  <strong>
    {SSscoreCard &&
      SSscoreCard.scoreCard &&
      SSscoreCard.scoreCard[2] &&
      SSscoreCard.scoreCard[2].scoreDetails
        ? SSscoreCard.scoreCard[2].scoreDetails.wickets
        : ""}
  </strong>
  {SSscoreCard &&
      SSscoreCard.scoreCard &&
      SSscoreCard.scoreCard[2] &&
      SSscoreCard.scoreCard[2].scoreDetails &&
  <strong className="mx-1">
    (
    <>
      <strong className="mx-1">
        <>
          {SSscoreCard &&
            SSscoreCard.scoreCard &&
            SSscoreCard.scoreCard[2] &&
            SSscoreCard.scoreCard[2].scoreDetails
              ? SSscoreCard.scoreCard[2].scoreDetails.overs
              : ""}
        </>
      </strong>
    </>
    )
  </strong>
}
</p>
{SSscoreCard &&
  SSscoreCard.scoreCard &&
  SSscoreCard.scoreCard.map((item) => (
    <>
      {item.scoreDetails.isDeclared === "false" && (
        <>
          <strong>RR</strong>
          <strong className="mx-1">
            {item.scoreDetails.runRate}
          </strong>
        </>
      )}
    </>
  ))}

                                </div>
                              </div>

                              {/* <div className="counteryflag mr-2  d-flex justify-content-between">{  Commentryplayers.Fixture.StartDateTime}</div> */}
                            </div>
                            <div className="over ">
                              <div>
                                <div>
                                <p className="">
  <strong>
    {SSscoreCard &&
      SSscoreCard.scoreCard &&
      SSscoreCard.scoreCard[1] &&
      SSscoreCard.scoreCard[1].scoreDetails
        ? SSscoreCard.scoreCard[1].scoreDetails.runs
        : ""}
  </strong>
  {SSscoreCard &&
      SSscoreCard.scoreCard &&
      SSscoreCard.scoreCard[1] &&
      SSscoreCard.scoreCard[1].scoreDetails &&
  <>/</>
}
  <strong>
    {SSscoreCard &&
      SSscoreCard.scoreCard &&
      SSscoreCard.scoreCard[1] &&
      SSscoreCard.scoreCard[1].scoreDetails
        ? SSscoreCard.scoreCard[1].scoreDetails.wickets
        : ""}
  </strong>
  {SSscoreCard &&
      SSscoreCard.scoreCard &&
      SSscoreCard.scoreCard[1] &&
      SSscoreCard.scoreCard[1].scoreDetails &&
  <strong className="mx-1">
    (
    <>
      <strong className="mx-1">
        <>
          {SSscoreCard &&
            SSscoreCard.scoreCard &&
            SSscoreCard.scoreCard[1] &&
            SSscoreCard.scoreCard[1].scoreDetails
              ? SSscoreCard.scoreCard[1].scoreDetails.overs
              : ""}
        </>
      </strong>
    </>
    )
  </strong>
}
  <br />
  <strong>
    {SSscoreCard &&
      SSscoreCard.scoreCard &&
      SSscoreCard.scoreCard[3] &&
      SSscoreCard.scoreCard[3].scoreDetails
        ? SSscoreCard.scoreCard[3].scoreDetails.runs
        : ""}
  </strong>
  {SSscoreCard &&
      SSscoreCard.scoreCard &&
      SSscoreCard.scoreCard[3] &&
      SSscoreCard.scoreCard[3].scoreDetails &&
  <>/</>
}
  <strong>
    {SSscoreCard &&
      SSscoreCard.scoreCard &&
      SSscoreCard.scoreCard[3] &&
      SSscoreCard.scoreCard[3].scoreDetails
        ? SSscoreCard.scoreCard[3].scoreDetails.wickets
        : ""}
  </strong>
  {SSscoreCard &&
      SSscoreCard.scoreCard &&
      SSscoreCard.scoreCard[3] &&
      SSscoreCard.scoreCard[3].scoreDetails && 
  <strong className="mx-1">
    (
    <>
      <strong className="mx-1">
        <>
          {SSscoreCard &&
            SSscoreCard.scoreCard &&
            SSscoreCard.scoreCard[3] &&
            SSscoreCard.scoreCard[3].scoreDetails
              ? SSscoreCard.scoreCard[3].scoreDetails.overs
              : ""}
        </>
      </strong>
    </>
    )
  </strong>
}
  <b></b>
</p>
{SSscoreCard &&
  SSscoreCard.scoreCard &&
  SSscoreCard.scoreCard.map((item, index) => (
    <>
      {item.scoreDetails &&
        item.scoreDetails.isDeclared === "false" && (
          <>
            <strong>RR</strong>
            <strong className="mx-1">
              {item.scoreDetails.runRate}
            </strong>
          </>
        )}
    </>
  ))}


                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex margin-top-10">
                          <div className="details ">
                            <p className="text-light m-0">
                                {/* {{SSscoreCard &&
  SSscoreCard.status.StartDateTime > 0 ? (
                                  <>
                                    {new Date(
                                      Commentryplayers.Fixture.StartDateTime
                                    ).toLocaleDateString("en-US", {
                                      weekday: "short",
                                      day: "numeric",
                                      month: "short",
                                    })}
                                    <span className="mx-1">|</span>
                                    {new Date(
                                      Commentryplayers.Fixture.StartDateTime
                                    ).toLocaleTimeString("en-US", {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })}
                                  </>
                                ) : (
                                  
                                  
                                )} */}
                                <marquee scrollamount="5"> {SSscoreCard &&
  SSscoreCard.status}</marquee>
                              </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={8}>
                <div className="legendscricket">
                  <div className="d-flex justify-content-between matchesbutton ">
                    <button
                      className={`Tabsnewlive ${
                        activeButton === "info" ? "activebutton" : ""
                      }`}
                      onClick={() => handleButtonClick("info")}
                    >
                      Scorecard
                    </button>
                    {/* <button
                      className={`Tabsnewlive ${
                        activeButton === "Live" ? "activebutton" : ""
                      }`}
                      onClick={() => handleButtonClick("Live")}
                    >
                      Live
                    </button> */}

                    <Link
                      to="#"
                      className={`Tabsnewlive ${
                        activeButton === "squads" ? "activebutton" : ""
                      }`}
                      onClick={() => handleButtonClick("squads")}
                    >
                      Squads
                    </Link>
                    <Link
                      to="#"
                      className={`Tabsnewlive ${
                        activeButton === "scorecard" ? "activebutton" : ""
                      }`}
                      onClick={() => handleButtonClick("scorecard")}
                    >
                      Info
                    </Link>

                    <Link to="/Series" className="Tabsnewlive">
                      <button> Matches</button>
                    </Link>
                    {/* <button>
                      <Link to="#" className="text-danger">
                        Point Table
                      </Link>
                    </button> */}
                    <Link to="/Featurevideo" className="Tabsnewlive">
                      <button>Video</button>
                    </Link>
                    <Link to="/News" className="Tabsnewlive">
                      <button> News</button>
                    </Link>

                    {/* <button></button> */}
                    <Link to="/Predictiontips" className="Tabsnewlive">
                      <button> Fantasy</button>
                    </Link>
                  </div>
                </div>
              </Col>

              {activeButton === "info" && (
                <Col md={8}>
                  <div className="matchscore bg-white">
                    <div className="datacard">
                      {/* <h2>SAU 192/6 (20)</h2> */}
                      {/* <h2 className="text-dark">{Commentryplayers.Fixture.ResultText} </h2> */}
                    </div>
                    {/* {MatechDetail.state ==="complete" ?(
                    <p>{MatechDetail.status }</p>
                    ):(
                      <p>{MatechDetail && MatechDetail.result.resultType} {MatechDetail && MatechDetail.result.winningMargin} {MatechDetail && MatechDetail.result.winningTeam}</p>
                    )}
                    <div className="playermatch">
                      <span>PLAYER OF THE MATCH</span>
                      {MatechDetail.playersOfTheMatch && MatechDetail.playersOfTheMatch.map((player,index) => (

                        <>
                         
                      <Link className="d-block">{player.fullName}</Link>
                       
                      </>
                      ))}
                    </div> */}
                  </div>
                  {/* hjggh  inning 1st*/}
                  {SSscoreCard.scoreCard && SSscoreCard.scoreCard.length>0 &&
                  <div className="bg-white">
                  <div className="stats d-flex  justify-content-between">
    <div>
      <div className="d-flex">
        <img
          src={Photo_Missing}
          style={{
            border: "0.25px solid #adada8",
            height: "30px",
            width: "30px",
          }}
          className="img-fluid"
          alt="Team Logo"
        />
        <strong className="mx-1">
          {SSscoreCard && SSscoreCard.scoreCard && SSscoreCard.scoreCard[0] &&  SSscoreCard.scoreCard[0].batTeamDetails ? 
            SSscoreCard.scoreCard[0].batTeamDetails.batTeamShortName :
            ""
          }
          <span className="innings"> 1st Innings</span>
        </strong>
      </div>
    </div>

    <div>
      <div>
        <p className="d-flex">
          <strong></strong> 
          {SSscoreCard && SSscoreCard.scoreCard && SSscoreCard.scoreCard[0]&& SSscoreCard.scoreCard[0].scoreDetails ? 
            `${SSscoreCard.scoreCard[0].scoreDetails.runs} - ${SSscoreCard.scoreCard[0].scoreDetails.wickets}` :
            ""
          }
          <strong className="mx-1 ">(</strong>
          <span className="">
            <span> {SSscoreCard && SSscoreCard.scoreCard && SSscoreCard.scoreCard[0]&& SSscoreCard.scoreCard[0].scoreDetails ? 
                      SSscoreCard.scoreCard[0].scoreDetails.overs :
                      ""
            }</span>
            <span className="mx-1">Ov</span>
            <strong className="">)</strong>
          </span>
        </p>
      </div>
    </div>
  </div>

                    <Container fluid>
                      <Row className="statssection">
                        <Col md={12} className="p-0">
                          <div className="PointTable">
                            <div className="pointtableheading d-flex justify-content-between"></div>
                            <div className="table-responsive">
                              <table className="table mb-0">
                                <thead className="pointablebg">
                                  <th colspan="2" className="matchtitle">
                                    Batter{" "}
                                  </th>

                                  <th className="text-center">R</th>

                                  <th className="text-center">B</th>
                                  <th className="text-center">4S</th>
                                  <th className="text-center">6S</th>
                                  <th className="text-center">SR</th>
                                </thead>
                                <tbody className="listpointtable text-center">
                                  {SSscoreCard &&
                                  SSscoreCard.scoreCard && SSscoreCard.scoreCard[0] &&
                                  SSscoreCard.scoreCard[0].batTeamDetails ? (
                                    <>
                                      {Object.keys(
                                        SSscoreCard.scoreCard[0].batTeamDetails
                                          .batsmenData
                                      ).map((key) => {
                                        const item =
                                          SSscoreCard.scoreCard[0]
                                            .batTeamDetails.batsmenData[key];
                                        return (
                                          <tr key={item.batId}>
                                            <td className="">
                                              <img
                                                src={
                                                  item.batId
                                                    ? `${imageUrl}${item.batId}.jpg`
                                                    : Photo_Missing
                                                }
                                                alt=""
                                                style={{
                                                  height: "40px",
                                                  borderRadius: "50%",
                                                  maxWidth: "40px",
                                                  objectFit: "fill",
                                                }}
                                              />
                                            </td>
                                            <td className=" verticalalgin text-left text-primary ">
                                              <p className="pb-0 mb-0 playernamescoreboard">
                                                {item.batName}
                                              </p>

                                              <div className="d-flex">
                                                {/* {item.IsOnStrike && (
                  <small className="text-danger">
                    *
                  </small>
                )} */}
                                                <p className="DismiText  mb-0 d-flex">
                                                  {item.outDesc
                                                    .replace(/[^\w\s]/g, "")
                                                    .trim()}
                                                </p>
                                              </div>
                                            </td>

                                            {item.runs !== null && (
                                              <td>
                                                <strong>
                                                  {item.runs ?? "0"}
                                                </strong>
                                              </td>
                                            )}
                                            {item.balls !== null && (
                                              <td>{item.balls ?? "0"}</td>
                                            )}
                                            {item.sixes !== null && (
                                              <td>{item.sixes ?? "0"}</td>
                                            )}
                                            {item.fours !== null && (
                                              <td>{item.fours ?? "0"}</td>
                                            )}
                                            {item.strikeRate !== null && (
                                              <td>{item.strikeRate ?? "0"}</td>
                                            )}
                                          </tr>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </tbody>

                                <tbody>
                                  <tr>
                                    <td colspan="3" className="fw-bold">
                                      Extras
                                    </td>

                                    <td className="" colspan="4">
                                      {SSscoreCard &&
                                      SSscoreCard.scoreCard &&
                                      SSscoreCard.scoreCard[0].extrasData ? (
                                        <div>
                                          <div>
                                            <p className="d-flex">
                                              <strong>
                                                {
                                                  SSscoreCard.scoreCard[0]
                                                    .extrasData.total
                                                }
                                              </strong>
                                              <strong className="mx-1 text-secondary">
                                                (
                                              </strong>
                                              <span className=" text-secondary d-flex">
                                                <React.Fragment>
                                                  <span className="mx-1">
                                                    b-
                                                  </span>
                                                  <span>
                                                    {
                                                      SSscoreCard.scoreCard[0]
                                                        .extrasData.byes
                                                    }
                                                  </span>
                                                  <span className="mx-1">
                                                    lb-
                                                  </span>
                                                  <span>
                                                    {SSscoreCard.scoreCard &&
                                                      SSscoreCard.scoreCard[0]
                                                        .extrasData.legByes}
                                                  </span>
                                                  <span className="mx-1">
                                                    nb-
                                                  </span>
                                                  <span>
                                                    {SSscoreCard.scoreCard &&
                                                      SSscoreCard.scoreCard[0]
                                                        .extrasData.noBalls}
                                                  </span>
                                                  <span className="mx-1">
                                                    p-
                                                  </span>
                                                  <span>
                                                    {SSscoreCard.scoreCard &&
                                                      SSscoreCard.scoreCard[0]
                                                        .extrasData.penalty}
                                                  </span>
                                                  <span className="mx-1">
                                                    w-
                                                  </span>
                                                  <span>
                                                    {SSscoreCard.scoreCard &&
                                                      SSscoreCard.scoreCard[0]
                                                        .extrasData.wides}
                                                  </span>
                                                </React.Fragment>
                                              </span>{" "}
                                              <strong className="text-secondary">
                                                )
                                              </strong>
                                            </p>
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody>
                                  <tr>
                                    <td colspan="3" className="fw-bold">
                                      Total
                                    </td>

                                    <td className="" colspan="4">
                                      {SSscoreCard &&
                                      SSscoreCard.scoreCard &&    SSscoreCard.scoreCard[0]&& 
                                      SSscoreCard.scoreCard[0].scoreDetails ? (
                                        <div>
                                          <div>
                                            <p className="d-flex">
                                              <strong>
                                                {SSscoreCard.scoreCard&& SSscoreCard.scoreCard[0] &&
                                                  SSscoreCard.scoreCard[0]
                                                    .scoreDetails.runs}
                                              </strong>
                                              <strong className="mx-1 text-secondary">
                                                (
                                              </strong>
                                              <span className="text-secondary">
                                                <span className="">
                                                  {SSscoreCard.scoreCard &&SSscoreCard.scoreCard[0]&&
                                                    SSscoreCard.scoreCard[0]
                                                      .scoreDetails.wickets}
                                                  wkts
                                                </span>
                                                <span>
                                                  {SSscoreCard.scoreCard &&SSscoreCard.scoreCard[0]&&
                                                    SSscoreCard.scoreCard[0]
                                                      .scoreDetails
                                                      .overs}
                                                </span>
                                                <span className="mx-1">Ov</span>
                                                <strong className="text-secondary">
                                                  )
                                                </strong>
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                                {/* <tbody>
  <tr>
    <td colSpan="3" className="fw-bold">
      Fall of Wickets
    </td>
  </tr>
  {SSscoreCard &&
    SSscoreCard.scoreCard &&
    SSscoreCard.scoreCard[0].wicketsData && (
      <>
        {Object.keys(SSscoreCard.scoreCard[0].wicketsData).map((key) => {
          const wicket = SSscoreCard.scoreCard[0].wicketsData[key];
          return (
           <>
              <td>{wicket.wktNbr}.{wicket.batName}-{wicket.wktOver}({wicket.wktRuns})</td>
             
      </>
          );
        })}
      </>
    )}
</tbody> */}
                              </table>
                              <table className="table mb-0">
                                <thead className="pointablebg">
                                  <tr>
                                    <th colSpan="2" className="matchtitle">
                                      Bowler
                                    </th>
                                    <th>O</th>

                                    <th>M</th>
                                    <th>R</th>
                                    <th>W</th>
                                    <th>Wd</th>
                                    <th>NB</th>
                                    <th>DB</th>
                                    <th>Econ</th>
                                  </tr>
                                </thead>
                                <tbody className="listpointtable text-center">
                                  {SSscoreCard &&
                                  SSscoreCard.scoreCard && SSscoreCard.scoreCard[0]&&
                                  SSscoreCard.scoreCard[0].bowlTeamDetails ? (
                                    <>
                                      {Object.keys(
                                        SSscoreCard.scoreCard[0].bowlTeamDetails
                                          .bowlersData
                                      ).map((key) => {
                                        const bowler =
                                          SSscoreCard.scoreCard[0]
                                            .bowlTeamDetails.bowlersData[key];
                                        return (
                                          <tr key={bowler.bowlerId}>
                                            <td>
                                              <img
                                                src={
                                                  bowler.bowlTeamId
                                                    ? `${imageUrl}${bowler.bowlTeamId}.jpg`
                                                    : Photo_Missing
                                                }
                                                alt={bowler.bowlName}
                                                style={{
                                                  height: "40px",
                                                  borderRadius: "50%",
                                                  maxWidth: "40px",
                                                  objectFit: "fill",
                                                }}
                                              />
                                            </td>
                                            <td>{bowler.bowlName}</td>
                                            <td>{bowler.maidens}</td>
                                            <td>{bowler.overs}</td>
                                            <td>{bowler.runs}</td>
                                            <td>{bowler.wickets}</td>
                                            <td>{bowler.wides}</td>
                                            <td>{bowler.no_balls}</td>
                                            <td>{bowler.dots}</td>
                                            <td>{bowler.economy}</td>
                                          </tr>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    <div>
                      {/* <div className=" mb-0">
                           
                            <div className="listpointtable d-flex">
                              <div className="width-heading">
                                <strong class=" d-flex ">Yet to Bat</strong>
                              </div>
                              <div className="d-flex flex-wrap width_90">
                                {[
                                  ...(Commentryplayers.Fixture &&
                                    Commentryplayers.Fixture.Innings[0]
                                      .Wickets),
                                  ...bestman,
                                  ...playerss.slice(0, 11),
                                ].map((item, index) => {
                                  const playerInfo = playerss.find(
                                    (player) => player.Id === item.PlayerId
                                  );

                                  if (!playerInfo) {
                                    return null;
                                  }

                                  return (
                                    <>
                                      {item.BallsFaced === 0 ? (
                                        <p>
                                          <div className=" new_text_info">
                                            {" "}
                                            <Link>
                                              {" "}
                                              {playerInfo.DisplayName}
                                            </Link>
                                            ,
                                          </div>
                                        </p>
                                      ) : null}
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div> */}
                      {/* <div class="stats bg-secondary  n">
                            Fall of Wickets
                          </div> */}
                    </div>
                    <div class="card ">
                      {/* <table className="d-flex">
                          <tbody className="fallinwicket">
  {Commentryplayers.Fixture &&
    Commentryplayers.Fixture.Innings[0].Wickets.map((item, index) => {
      const playerInfo = playerss.find((player) => player.Id === item.PlayerId);

      if (!playerInfo || item.IsOut === false) {
        return null;
      }

      return (
        <div key={index} className="">
          {item.Runs} - {item.Order} ({" "}
          <Link>{playerInfo.DisplayName}</Link>
          &nbsp;, {item.OverBallDisplay})&nbsp;
        </div>
      );
    })}
</tbody>

                          </table> */}
                    </div>
                  </div>
}
                  {/* jhgjfdjg inning 2nd */}
                  {SSscoreCard.scoreCard && SSscoreCard.scoreCard.length >1 &&
                  <div className="bg-white">
                  <div className="stats d-flex  justify-content-between">
    <div>
      <div className="d-flex">
        <img
          src={Photo_Missing}
          style={{
            border: "0.25px solid #adada8",
            height: "30px",
            width: "30px",
          }}
          className="img-fluid"
          alt="Team Logo"
        />
        <strong className="mx-1">
          {SSscoreCard && SSscoreCard.scoreCard && SSscoreCard.scoreCard[1] &&  SSscoreCard.scoreCard[1].batTeamDetails ? 
            SSscoreCard.scoreCard[1].batTeamDetails.batTeamShortName :
            ""
          }
          <span className="innings"> 2nd Innings</span>
        </strong>
      </div>
    </div>

    <div>
      <div>
        <p className="d-flex">
          <strong></strong> 
          {SSscoreCard && SSscoreCard.scoreCard && SSscoreCard.scoreCard[1]&& SSscoreCard.scoreCard[1].scoreDetails ? 
            `${SSscoreCard.scoreCard[1].scoreDetails.runs} - ${SSscoreCard.scoreCard[1].scoreDetails.wickets}` :
            ""
          }
          <strong className="mx-1 ">(</strong>
          <span className="">
            <span> {SSscoreCard && SSscoreCard.scoreCard && SSscoreCard.scoreCard[1]&& SSscoreCard.scoreCard[1].scoreDetails ? 
                      SSscoreCard.scoreCard[1].scoreDetails.overs :
                      ""
            }</span>
            <span className="mx-1">Ov</span>
            <strong className="">)</strong>
          </span>
        </p>
      </div>
    </div>
  </div>
                    <Container fluid>
                      <Row className="statssection">
                        <Col md={12} className="p-0">
                          <div className="PointTable">
                            <div className="pointtableheading d-flex justify-content-between"></div>
                            <div className="table-responsive">
                              <table className="table mb-0">
                                <thead className="pointablebg">
                                  <th colspan="2" className="matchtitle">
                                    Batter{" "}
                                  </th>

                                  <th className="text-center">R</th>

                                  <th className="text-center">B</th>
                                  <th className="text-center">4S</th>
                                  <th className="text-center">6S</th>
                                  <th className="text-center">SR</th>
                                </thead>
                                <tbody className="listpointtable text-center">
                                  {SSscoreCard &&
                                  SSscoreCard.scoreCard &&
                                  SSscoreCard.scoreCard[1]&&
                                  SSscoreCard.scoreCard[1].batTeamDetails ? (
                                    <>
                                      {Object.keys(
                                        SSscoreCard.scoreCard[1].batTeamDetails
                                          .batsmenData
                                      ).map((key) => {
                                        const item =
                                          SSscoreCard.scoreCard[1]
                                            .batTeamDetails.batsmenData[key];
                                        return (
                                          <tr key={item.batId}>
                                            <td className="">
                                              <img
                                                src={
                                                  item.batId
                                                    ? `${imageUrl}${item.batId}.jpg`
                                                    : Photo_Missing
                                                }
                                                alt=""
                                                style={{
                                                  height: "40px",
                                                  borderRadius: "50%",
                                                  maxWidth: "40px",
                                                  objectFit: "fill",
                                                }}
                                              />
                                            </td>
                                            <td className=" verticalalgin text-left text-primary ">
                                              <p className="pb-0 mb-0 playernamescoreboard">
                                                {item.batName}
                                              </p>

                                              <div className="d-flex">
                                                {/* {item.IsOnStrike && (
                  <small className="text-danger">
                    *
                  </small>
                )} */}
                                                <p className="DismiText  mb-0 d-flex">
                                                  {item.outDesc
                                                    .replace(/[^\w\s]/g, "")
                                                    .trim()}
                                                </p>
                                              </div>
                                            </td>

                                            {item.runs !== null && (
                                              <td>
                                                <strong>
                                                  {item.runs ?? "0"}
                                                </strong>
                                              </td>
                                            )}
                                            {item.balls !== null && (
                                              <td>{item.balls ?? "0"}</td>
                                            )}
                                            {item.sixes !== null && (
                                              <td>{item.sixes ?? "0"}</td>
                                            )}
                                            {item.fours !== null && (
                                              <td>{item.fours ?? "0"}</td>
                                            )}
                                            {item.strikeRate !== null && (
                                              <td>{item.strikeRate ?? "0"}</td>
                                            )}
                                          </tr>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </tbody>

                                <tbody>
                                  <tr>
                                    <td colspan="3" className="fw-bold">
                                      Extras
                                    </td>

                                    <td className="" colspan="4">
                                      {SSscoreCard &&
                                      SSscoreCard.scoreCard &&
                                      SSscoreCard.scoreCard[1]&&
                                      SSscoreCard.scoreCard[1].extrasData ? (
                                        <div>
                                          <div>
                                            <p className="d-flex">
                                              <strong>
                                                {
                                                  SSscoreCard.scoreCard[1]
                                                    .extrasData.total
                                                }
                                              </strong>
                                              <strong className="mx-1 text-secondary">
                                                (
                                              </strong>
                                              <span className=" text-secondary d-flex">
                                                <React.Fragment>
                                                  <span className="mx-1">
                                                    b-
                                                  </span>
                                                  <span>
                                                    {
                                                      SSscoreCard.scoreCard[1]
                                                        .extrasData.byes
                                                    }
                                                  </span>
                                                  <span className="mx-1">
                                                    lb-
                                                  </span>
                                                  <span>
                                                    {SSscoreCard.scoreCard &&
                                                      SSscoreCard.scoreCard[1]
                                                        .extrasData.legByes}
                                                  </span>
                                                  <span className="mx-1">
                                                    nb-
                                                  </span>
                                                  <span>
                                                    {SSscoreCard.scoreCard &&
                                                      SSscoreCard.scoreCard[1]
                                                        .extrasData.noBalls}
                                                  </span>
                                                  <span className="mx-1">
                                                    p-
                                                  </span>
                                                  <span>
                                                    {SSscoreCard.scoreCard &&
                                                      SSscoreCard.scoreCard[1]
                                                        .extrasData.penalty}
                                                  </span>
                                                  <span className="mx-1">
                                                    w-
                                                  </span>
                                                  <span>
                                                    {SSscoreCard.scoreCard &&
                                                      SSscoreCard.scoreCard[1]
                                                        .extrasData.wides}
                                                  </span>
                                                </React.Fragment>
                                              </span>{" "}
                                              <strong className="text-secondary">
                                                )
                                              </strong>
                                            </p>
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody>
                                  <tr>
                                    <td colspan="3" className="fw-bold">
                                      Total
                                    </td>

                                    <td className="" colspan="4">
                                      {SSscoreCard &&
                                      SSscoreCard.scoreCard && SSscoreCard.scoreCard[1]&&
                                      SSscoreCard.scoreCard[1].scoreDetails ? (
                                        <div>
                                          <div>
                                            <p className="d-flex">
                                              <strong>
                                                {SSscoreCard.scoreCard &&
                                                  SSscoreCard.scoreCard[1]
                                                    .scoreDetails.runs}
                                              </strong>
                                              <strong className="mx-1 text-secondary">
                                                (
                                              </strong>
                                              <span className="text-secondary">
                                                <span className="">
                                                  {SSscoreCard.scoreCard &&
                                                    SSscoreCard.scoreCard[1]
                                                      .scoreDetails.wickets}
                                                  wkts
                                                </span>
                                                <span>
                                                  {SSscoreCard.scoreCard &&
                                                    SSscoreCard.scoreCard[1]
                                                      .scoreDetails.wickets
                                                      .overs}
                                                </span>
                                                <span className="mx-1">Ov</span>
                                                <strong className="text-secondary">
                                                  )
                                                </strong>
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                                {/* <tbody>
  <tr>
    <td colSpan="3" className="fw-bold">
      Fall of Wickets
    </td>
  </tr>
  {SSscoreCard &&
    SSscoreCard.scoreCard &&
    SSscoreCard.scoreCard[0].wicketsData && (
      <>
        {Object.keys(SSscoreCard.scoreCard[0].wicketsData).map((key) => {
          const wicket = SSscoreCard.scoreCard[0].wicketsData[key];
          return (
           <>
              <td>{wicket.wktNbr}.{wicket.batName}-{wicket.wktOver}({wicket.wktRuns})</td>
             
      </>
          );
        })}
      </>
    )}
</tbody> */}
                              </table>
                              <table className="table mb-0">
                                <thead className="pointablebg">
                                  <tr>
                                    <th colSpan="2" className="matchtitle">
                                      Bowler
                                    </th>
                                    <th>O</th>

                                    <th>M</th>
                                    <th>R</th>
                                    <th>W</th>
                                    <th>Wd</th>
                                    <th>NB</th>
                                    <th>DB</th>
                                    <th>Econ</th>
                                  </tr>
                                </thead>
                                <tbody className="listpointtable text-center">
                                  {SSscoreCard &&
                                  SSscoreCard.scoreCard && SSscoreCard.scoreCard[1]&&
                                  SSscoreCard.scoreCard[1].bowlTeamDetails ? (
                                    <>
                                      {Object.keys(
                                        SSscoreCard.scoreCard[1].bowlTeamDetails
                                          .bowlersData
                                      ).map((key) => {
                                        const bowler =
                                          SSscoreCard.scoreCard[1]
                                            .bowlTeamDetails.bowlersData[key];
                                        return (
                                          <tr key={bowler.bowlerId}>
                                            <td>
                                              <img
                                                src={
                                                  bowler.bowlTeamId
                                                    ? `${imageUrl}${bowler.bowlTeamId}.jpg`
                                                    : Photo_Missing
                                                }
                                                alt={bowler.bowlName}
                                                style={{
                                                  height: "40px",
                                                  borderRadius: "50%",
                                                  maxWidth: "40px",
                                                  objectFit: "fill",
                                                }}
                                              />
                                            </td>
                                            <td>{bowler.bowlName}</td>
                                            <td>{bowler.maidens}</td>
                                            <td>{bowler.overs}</td>
                                            <td>{bowler.runs}</td>
                                            <td>{bowler.wickets}</td>
                                            <td>{bowler.wides}</td>
                                            <td>{bowler.no_balls}</td>
                                            <td>{bowler.dots}</td>
                                            <td>{bowler.economy}</td>
                                          </tr>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    <div>
                      {/* <div className=" mb-0">
                           
                            <div className="listpointtable d-flex">
                              <div className="width-heading">
                                <strong class=" d-flex ">Yet to Bat</strong>
                              </div>
                              <div className="d-flex flex-wrap width_90">
                                {[
                                  ...(Commentryplayers.Fixture &&
                                    Commentryplayers.Fixture.Innings[0]
                                      .Wickets),
                                  ...bestman,
                                  ...playerss.slice(0, 11),
                                ].map((item, index) => {
                                  const playerInfo = playerss.find(
                                    (player) => player.Id === item.PlayerId
                                  );

                                  if (!playerInfo) {
                                    return null;
                                  }

                                  return (
                                    <>
                                      {item.BallsFaced === 0 ? (
                                        <p>
                                          <div className=" new_text_info">
                                            {" "}
                                            <Link>
                                              {" "}
                                              {playerInfo.DisplayName}
                                            </Link>
                                            ,
                                          </div>
                                        </p>
                                      ) : null}
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div> */}
                      {/* <div class="stats bg-secondary  n">
                            Fall of Wickets
                          </div> */}
                    </div>
                    <div class="card ">
                      {/* <table className="d-flex">
                          <tbody className="fallinwicket">
  {Commentryplayers.Fixture &&
    Commentryplayers.Fixture.Innings[0].Wickets.map((item, index) => {
      const playerInfo = playerss.find((player) => player.Id === item.PlayerId);

      if (!playerInfo || item.IsOut === false) {
        return null;
      }

      return (
        <div key={index} className="">
          {item.Runs} - {item.Order} ({" "}
          <Link>{playerInfo.DisplayName}</Link>
          &nbsp;, {item.OverBallDisplay})&nbsp;
        </div>
      );
    })}
</tbody>

                          </table> */}
                    </div>
                  </div>
}
                  {/* hufgdjfhgujfdh inning 3 */}
                  {SSscoreCard.scoreCard && SSscoreCard.scoreCard.length >2 &&
                  <div className="bg-white">
                  <div className="stats d-flex  justify-content-between">
    <div>
      <div className="d-flex">
        <img
          src={Photo_Missing}
          style={{
            border: "0.25px solid #adada8",
            height: "30px",
            width: "30px",
          }}
          className="img-fluid"
          alt="Team Logo"
        />
        <strong className="mx-1">
          {SSscoreCard && SSscoreCard.scoreCard && SSscoreCard.scoreCard[2]&& SSscoreCard.scoreCard[2].batTeamDetails ? 
            SSscoreCard.scoreCard[2].batTeamDetails.batTeamShortName :
            ""
          }
          <span className="innings"> 3rd Innings</span>
        </strong>
      </div>
    </div>

    <div>
      <div>
        <p className="d-flex">
          <strong></strong> 
          {SSscoreCard && SSscoreCard.scoreCard && SSscoreCard.scoreCard[2]&& SSscoreCard.scoreCard[2].scoreDetails ? 
            `${SSscoreCard.scoreCard[2].scoreDetails.runs} - ${SSscoreCard.scoreCard[2].scoreDetails.wickets}` :
            ""
          }
          <strong className="mx-1 ">(</strong>
          <span className="">
            <span> {SSscoreCard && SSscoreCard.scoreCard && SSscoreCard.scoreCard[2]&& SSscoreCard.scoreCard[2].scoreDetails ? 
                      SSscoreCard.scoreCard[2].scoreDetails.overs :
                      ""
            }</span>
            <span className="mx-1">Ov</span>
            <strong className="">)</strong>
          </span>
        </p>
      </div>
    </div>
  </div>

                    <Container fluid>
                      <Row className="statssection">
                        <Col md={12} className="p-0">
                          <div className="PointTable">
                            <div className="pointtableheading d-flex justify-content-between"></div>
                            <div className="table-responsive">
                              <table className="table mb-0">
                                <thead className="pointablebg">
                                  <th colspan="2" className="matchtitle">
                                    Batter{" "}
                                  </th>

                                  <th className="text-center">R</th>

                                  <th className="text-center">B</th>
                                  <th className="text-center">4S</th>
                                  <th className="text-center">6S</th>
                                  <th className="text-center">SR</th>
                                </thead>
                                <tbody className="listpointtable text-center">
                                  {SSscoreCard &&
                                  SSscoreCard.scoreCard &&
                                  SSscoreCard.scoreCard[1]&&
                                  SSscoreCard.scoreCard[1].batTeamDetails ? (
                                    <>
                                      {Object.keys(
                                        SSscoreCard.scoreCard[2].batTeamDetails
                                          .batsmenData
                                      ).map((key) => {
                                        const item =
                                          SSscoreCard.scoreCard[2]
                                            .batTeamDetails.batsmenData[key];
                                        return (
                                          <tr key={item.batId}>
                                            <td className="">
                                              <img
                                                src={
                                                  item.batId
                                                    ? `${imageUrl}${item.batId}.jpg`
                                                    : Photo_Missing
                                                }
                                                alt=""
                                                style={{
                                                  height: "40px",
                                                  borderRadius: "50%",
                                                  maxWidth: "40px",
                                                  objectFit: "fill",
                                                }}
                                              />
                                            </td>
                                            <td className=" verticalalgin text-left text-primary ">
                                              <p className="pb-0 mb-0 playernamescoreboard">
                                                {item.batName}
                                              </p>

                                              <div className="d-flex">
                                                {/* {item.IsOnStrike && (
                  <small className="text-danger">
                    *
                  </small>
                )} */}
                                                <p className="DismiText  mb-0 d-flex">
                                                  {item.outDesc
                                                    .replace(/[^\w\s]/g, "")
                                                    .trim()}
                                                </p>
                                              </div>
                                            </td>

                                            {item.runs !== null && (
                                              <td>
                                                <strong>
                                                  {item.runs ?? "0"}
                                                </strong>
                                              </td>
                                            )}
                                            {item.balls !== null && (
                                              <td>{item.balls ?? "0"}</td>
                                            )}
                                            {item.sixes !== null && (
                                              <td>{item.sixes ?? "0"}</td>
                                            )}
                                            {item.fours !== null && (
                                              <td>{item.fours ?? "0"}</td>
                                            )}
                                            {item.strikeRate !== null && (
                                              <td>{item.strikeRate ?? "0"}</td>
                                            )}
                                          </tr>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </tbody>

                                <tbody>
                                  <tr>
                                    <td colspan="3" className="fw-bold">
                                      Extras
                                    </td>

                                    <td className="" colspan="4">
                                      {SSscoreCard &&
                                      SSscoreCard.scoreCard &&
                                      SSscoreCard.scoreCard[2]&&
                                      SSscoreCard.scoreCard[2].extrasData ? (
                                        <div>
                                          <div>
                                            <p className="d-flex">
                                              <strong>
                                                {
                                                  SSscoreCard.scoreCard[2]
                                                    .extrasData.total
                                                }
                                              </strong>
                                              <strong className="mx-1 text-secondary">
                                                (
                                              </strong>
                                              <span className=" text-secondary d-flex">
                                                <React.Fragment>
                                                  <span className="mx-1">
                                                    b-
                                                  </span>
                                                  <span>
                                                    {
                                                      SSscoreCard.scoreCard[2]
                                                        .extrasData.byes
                                                    }
                                                  </span>
                                                  <span className="mx-1">
                                                    lb-
                                                  </span>
                                                  <span>
                                                    {SSscoreCard.scoreCard &&
                                                      SSscoreCard.scoreCard[2]
                                                        .extrasData.legByes}
                                                  </span>
                                                  <span className="mx-1">
                                                    nb-
                                                  </span>
                                                  <span>
                                                    {SSscoreCard.scoreCard &&
                                                      SSscoreCard.scoreCard[2]
                                                        .extrasData.noBalls}
                                                  </span>
                                                  <span className="mx-1">
                                                    p-
                                                  </span>
                                                  <span>
                                                    {SSscoreCard.scoreCard &&
                                                      SSscoreCard.scoreCard[2]
                                                        .extrasData.penalty}
                                                  </span>
                                                  <span className="mx-1">
                                                    w-
                                                  </span>
                                                  <span>
                                                    {SSscoreCard.scoreCard &&
                                                      SSscoreCard.scoreCard[2]
                                                        .extrasData.wides}
                                                  </span>
                                                </React.Fragment>
                                              </span>{" "}
                                              <strong className="text-secondary">
                                                )
                                              </strong>
                                            </p>
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody>
                                  <tr>
                                    <td colspan="3" className="fw-bold">
                                      Total
                                    </td>

                                    <td className="" colspan="4">
                                      {SSscoreCard &&
                                      SSscoreCard.scoreCard &&
                                      SSscoreCard.scoreCard[2]&&
                                      SSscoreCard.scoreCard[2].scoreDetails ? (
                                        <div>
                                          <div>
                                            <p className="d-flex">
                                              <strong>
                                                {SSscoreCard.scoreCard &&
                                                  SSscoreCard.scoreCard[2]
                                                    .scoreDetails.runs}
                                              </strong>
                                              <strong className="mx-1 text-secondary">
                                                (
                                              </strong>
                                              <span className="text-secondary">
                                                <span className="">
                                                  {SSscoreCard.scoreCard &&
                                                    SSscoreCard.scoreCard[2]
                                                      .scoreDetails.wickets}
                                                  wkts
                                                </span>
                                                <span>
                                                  {SSscoreCard.scoreCard &&
                                                    SSscoreCard.scoreCard[2]
                                                      .scoreDetails
                                                      .overs}
                                                </span>
                                                <span className="mx-1">Ov</span>
                                                <strong className="text-secondary">
                                                  )
                                                </strong>
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                                {/* <tbody>
  <tr>
    <td colSpan="3" className="fw-bold">
      Fall of Wickets
    </td>
  </tr>
  {SSscoreCard &&
    SSscoreCard.scoreCard &&
    SSscoreCard.scoreCard[0].wicketsData && (
      <>
        {Object.keys(SSscoreCard.scoreCard[0].wicketsData).map((key) => {
          const wicket = SSscoreCard.scoreCard[0].wicketsData[key];
          return (
           <>
              <td>{wicket.wktNbr}.{wicket.batName}-{wicket.wktOver}({wicket.wktRuns})</td>
             
      </>
          );
        })}
      </>
    )}
</tbody> */}
                              </table>
                              <table className="table mb-0">
                                <thead className="pointablebg">
                                  <tr>
                                    <th colSpan="2" className="matchtitle">
                                      Bowler
                                    </th>
                                    <th>O</th>

                                    <th>M</th>
                                    <th>R</th>
                                    <th>W</th>
                                    <th>Wd</th>
                                    <th>NB</th>
                                    <th>DB</th>
                                    <th>Econ</th>
                                  </tr>
                                </thead>
                                <tbody className="listpointtable text-center">
                                  {SSscoreCard &&
                                  SSscoreCard.scoreCard &&
                                  SSscoreCard.scoreCard[2]&&
                                  SSscoreCard.scoreCard[2].bowlTeamDetails ? (
                                    <>
                                      {Object.keys(
                                        SSscoreCard.scoreCard[2].bowlTeamDetails
                                          .bowlersData
                                      ).map((key) => {
                                        const bowler =
                                          SSscoreCard.scoreCard[2]
                                            .bowlTeamDetails.bowlersData[key];
                                        return (
                                          <tr key={bowler.bowlerId}>
                                            <td>
                                              <img
                                                src={
                                                  bowler.bowlTeamId
                                                    ? `${imageUrl}${bowler.bowlTeamId}.jpg`
                                                    : Photo_Missing
                                                }
                                                alt={bowler.bowlName}
                                                style={{
                                                  height: "40px",
                                                  borderRadius: "50%",
                                                  maxWidth: "40px",
                                                  objectFit: "fill",
                                                }}
                                              />
                                            </td>
                                            <td>{bowler.bowlName}</td>
                                            <td>{bowler.maidens}</td>
                                            <td>{bowler.overs}</td>
                                            <td>{bowler.runs}</td>
                                            <td>{bowler.wickets}</td>
                                            <td>{bowler.wides}</td>
                                            <td>{bowler.no_balls}</td>
                                            <td>{bowler.dots}</td>
                                            <td>{bowler.economy}</td>
                                          </tr>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    <div>
                      {/* <div className=" mb-0">
                           
                            <div className="listpointtable d-flex">
                              <div className="width-heading">
                                <strong class=" d-flex ">Yet to Bat</strong>
                              </div>
                              <div className="d-flex flex-wrap width_90">
                                {[
                                  ...(Commentryplayers.Fixture &&
                                    Commentryplayers.Fixture.Innings[0]
                                      .Wickets),
                                  ...bestman,
                                  ...playerss.slice(0, 11),
                                ].map((item, index) => {
                                  const playerInfo = playerss.find(
                                    (player) => player.Id === item.PlayerId
                                  );

                                  if (!playerInfo) {
                                    return null;
                                  }

                                  return (
                                    <>
                                      {item.BallsFaced === 0 ? (
                                        <p>
                                          <div className=" new_text_info">
                                            {" "}
                                            <Link>
                                              {" "}
                                              {playerInfo.DisplayName}
                                            </Link>
                                            ,
                                          </div>
                                        </p>
                                      ) : null}
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div> */}
                      {/* <div class="stats bg-secondary  n">
                            Fall of Wickets
                          </div> */}
                    </div>
                    <div class="card ">
                      {/* <table className="d-flex">
                          <tbody className="fallinwicket">
  {Commentryplayers.Fixture &&
    Commentryplayers.Fixture.Innings[0].Wickets.map((item, index) => {
      const playerInfo = playerss.find((player) => player.Id === item.PlayerId);

      if (!playerInfo || item.IsOut === false) {
        return null;
      }

      return (
        <div key={index} className="">
          {item.Runs} - {item.Order} ({" "}
          <Link>{playerInfo.DisplayName}</Link>
          &nbsp;, {item.OverBallDisplay})&nbsp;
        </div>
      );
    })}
</tbody>

                          </table> */}
                    </div>
                  </div>
}
                  {/* fdjghdjfhj inning 4 */}
                  {SSscoreCard.scoreCard && SSscoreCard.scoreCard.length >3 &&
                  <div className="bg-white">
                  <div className="stats d-flex  justify-content-between">
    <div>
      <div className="d-flex">
        <img
          src={Photo_Missing}
          style={{
            border: "0.25px solid #adada8",
            height: "30px",
            width: "30px",
          }}
          className="img-fluid"
          alt="Team Logo"
        />
        <strong className="mx-1">
          {SSscoreCard && SSscoreCard.scoreCard && SSscoreCard.scoreCard[3]&& SSscoreCard.scoreCard[3].batTeamDetails ? 
            SSscoreCard.scoreCard[3].batTeamDetails.batTeamShortName :
            ""
          }
          <span className="innings"> 4th Innings</span>
        </strong>
      </div>
    </div>

    <div>
      <div>
        <p className="d-flex">
          <strong></strong> 
          {SSscoreCard && SSscoreCard.scoreCard && SSscoreCard.scoreCard[3].scoreDetails ? 
            `${SSscoreCard.scoreCard[3].scoreDetails.runs} - ${SSscoreCard.scoreCard[3].scoreDetails.wickets}` :
            ""
          }
          <strong className="mx-1 ">(</strong>
          <span className="">
            <span> {SSscoreCard && SSscoreCard.scoreCard && SSscoreCard.scoreCard[3]&& SSscoreCard.scoreCard[3].scoreDetails ? 
                      SSscoreCard.scoreCard[3].scoreDetails.overs :
                      ""
            }</span>
            <span className="mx-1">Ov</span>
            <strong className="">)</strong>
          </span>
        </p>
      </div>
    </div>
  </div>

                    <Container fluid>
                      <Row className="statssection">
                        <Col md={12} className="p-0">
                          <div className="PointTable">
                            <div className="pointtableheading d-flex justify-content-between"></div>
                            <div className="table-responsive">
                              <table className="table mb-0">
                                <thead className="pointablebg">
                                  <th colspan="2" className="matchtitle">
                                    Batter{" "}
                                  </th>

                                  <th className="text-center">R</th>

                                  <th className="text-center">B</th>
                                  <th className="text-center">4S</th>
                                  <th className="text-center">6S</th>
                                  <th className="text-center">SR</th>
                                </thead>
                                <tbody className="listpointtable text-center">
                                  {SSscoreCard &&
                                  SSscoreCard.scoreCard &&
                                  SSscoreCard.scoreCard[3]&&
                                  SSscoreCard.scoreCard[3].batTeamDetails ? (
                                    <>
                                      {Object.keys(
                                        SSscoreCard.scoreCard[3].batTeamDetails
                                          .batsmenData
                                      ).map((key) => {
                                        const item =
                                          SSscoreCard.scoreCard[3]
                                            .batTeamDetails.batsmenData[key];
                                        return (
                                          <tr key={item.batId}>
                                            <td className="">
                                              <img
                                                src={
                                                  item.batId
                                                    ? `${imageUrl}${item.batId}.jpg`
                                                    : Photo_Missing
                                                }
                                                alt=""
                                                style={{
                                                  height: "40px",
                                                  borderRadius: "50%",
                                                  maxWidth: "40px",
                                                  objectFit: "fill",
                                                }}
                                              />
                                            </td>
                                            <td className=" verticalalgin text-left text-primary ">
                                              <p className="pb-0 mb-0 playernamescoreboard">
                                                {item.batName}
                                              </p>

                                              <div className="d-flex">
                                                {/* {item.IsOnStrike && (
                  <small className="text-danger">
                    *
                  </small>
                )} */}
                                                <p className="DismiText  mb-0 d-flex">
                                                  {item.outDesc
                                                    .replace(/[^\w\s]/g, "")
                                                    .trim()}
                                                </p>
                                              </div>
                                            </td>

                                            {item.runs !== null && (
                                              <td>
                                                <strong>
                                                  {item.runs ?? "0"}
                                                </strong>
                                              </td>
                                            )}
                                            {item.balls !== null && (
                                              <td>{item.balls ?? "0"}</td>
                                            )}
                                            {item.sixes !== null && (
                                              <td>{item.sixes ?? "0"}</td>
                                            )}
                                            {item.fours !== null && (
                                              <td>{item.fours ?? "0"}</td>
                                            )}
                                            {item.strikeRate !== null && (
                                              <td>{item.strikeRate ?? "0"}</td>
                                            )}
                                          </tr>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </tbody>

                                <tbody>
                                  <tr>
                                    <td colspan="3" className="fw-bold">
                                      Extras
                                    </td>

                                    <td className="" colspan="4">
                                      {SSscoreCard &&
                                      SSscoreCard.scoreCard &&
                                      SSscoreCard.scoreCard[3]&&
                                      SSscoreCard.scoreCard[3].extrasData ? (
                                        <div>
                                          <div>
                                            <p className="d-flex">
                                              <strong>
                                                {
                                                  SSscoreCard.scoreCard[3]
                                                    .extrasData.total
                                                }
                                              </strong>
                                              <strong className="mx-1 text-secondary">
                                                (
                                              </strong>
                                              <span className=" text-secondary d-flex">
                                                <React.Fragment>
                                                  <span className="mx-1">
                                                    b-
                                                  </span>
                                                  <span>
                                                    {
                                                      SSscoreCard.scoreCard[3]
                                                        .extrasData.byes
                                                    }
                                                  </span>
                                                  <span className="mx-1">
                                                    lb-
                                                  </span>
                                                  <span>
                                                    {SSscoreCard.scoreCard &&
                                                      SSscoreCard.scoreCard[2]
                                                        .extrasData.legByes}
                                                  </span>
                                                  <span className="mx-1">
                                                    nb-
                                                  </span>
                                                  <span>
                                                    {SSscoreCard.scoreCard &&
                                                      SSscoreCard.scoreCard[2]
                                                        .extrasData.noBalls}
                                                  </span>
                                                  <span className="mx-1">
                                                    p-
                                                  </span>
                                                  <span>
                                                    {SSscoreCard.scoreCard &&
                                                      SSscoreCard.scoreCard[3]
                                                        .extrasData.penalty}
                                                  </span>
                                                  <span className="mx-1">
                                                    w-
                                                  </span>
                                                  <span>
                                                    {SSscoreCard.scoreCard &&
                                                      SSscoreCard.scoreCard[3]
                                                        .extrasData.wides}
                                                  </span>
                                                </React.Fragment>
                                              </span>{" "}
                                              <strong className="text-secondary">
                                                )
                                              </strong>
                                            </p>
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                                <tbody>
                                  <tr>
                                    <td colspan="3" className="fw-bold">
                                      Total
                                    </td>

                                    <td className="" colspan="4">
                                      {SSscoreCard &&
                                      SSscoreCard.scoreCard &&
                                      SSscoreCard.scoreCard[3]&&
                                      SSscoreCard.scoreCard[3].scoreDetails ? (
                                        <div>
                                          <div>
                                            <p className="d-flex">
                                              <strong>
                                                {SSscoreCard.scoreCard &&
                                                  SSscoreCard.scoreCard[3]
                                                    .scoreDetails.runs}
                                              </strong>
                                              <strong className="mx-1 text-secondary">
                                                (
                                              </strong>
                                              <span className="text-secondary">
                                                <span className="">
                                                  {SSscoreCard.scoreCard &&
                                                    SSscoreCard.scoreCard[3]
                                                      .scoreDetails.wickets}
                                                  wkts
                                                </span>
                                                <span>
                                                  {SSscoreCard.scoreCard &&
                                                    SSscoreCard.scoreCard[3]
                                                      .scoreDetails
                                                      .overs}
                                                </span>
                                                <span className="mx-1">Ov</span>
                                                <strong className="text-secondary">
                                                  )
                                                </strong>
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                                {/* <tbody>
  <tr>
    <td colSpan="3" className="fw-bold">
      Fall of Wickets
    </td>
  </tr>
  {SSscoreCard &&
    SSscoreCard.scoreCard &&
    SSscoreCard.scoreCard[0].wicketsData && (
      <>
        {Object.keys(SSscoreCard.scoreCard[0].wicketsData).map((key) => {
          const wicket = SSscoreCard.scoreCard[0].wicketsData[key];
          return (
           <>
              <td>{wicket.wktNbr}.{wicket.batName}-{wicket.wktOver}({wicket.wktRuns})</td>
             
      </>
          );
        })}
      </>
    )}
</tbody> */}
                              </table>
                              <table className="table mb-0">
                                <thead className="pointablebg">
                                  <tr>
                                    <th colSpan="2" className="matchtitle">
                                      Bowler
                                    </th>
                                    <th>O</th>

                                    <th>M</th>
                                    <th>R</th>
                                    <th>W</th>
                                    <th>Wd</th>
                                    <th>NB</th>
                                    <th>DB</th>
                                    <th>Econ</th>
                                  </tr>
                                </thead>
                                <tbody className="listpointtable text-center">
                                  {SSscoreCard &&
                                  SSscoreCard.scoreCard &&
                                  SSscoreCard.scoreCard[2]&&
                                  SSscoreCard.scoreCard[2].bowlTeamDetails ? (
                                    <>
                                      {Object.keys(
                                        SSscoreCard.scoreCard[2].bowlTeamDetails
                                          .bowlersData
                                      ).map((key) => {
                                        const bowler =
                                          SSscoreCard.scoreCard[2]
                                            .bowlTeamDetails.bowlersData[key];
                                        return (
                                          <tr key={bowler.bowlerId}>
                                            <td>
                                              <img
                                                src={
                                                  bowler.bowlTeamId
                                                    ? `${imageUrl}${bowler.bowlTeamId}.jpg`
                                                    : Photo_Missing
                                                }
                                                alt={bowler.bowlName}
                                                style={{
                                                  height: "40px",
                                                  borderRadius: "50%",
                                                  maxWidth: "40px",
                                                  objectFit: "fill",
                                                }}
                                              />
                                            </td>
                                            <td>{bowler.bowlName}</td>
                                            <td>{bowler.maidens}</td>
                                            <td>{bowler.overs}</td>
                                            <td>{bowler.runs}</td>
                                            <td>{bowler.wickets}</td>
                                            <td>{bowler.wides}</td>
                                            <td>{bowler.no_balls}</td>
                                            <td>{bowler.dots}</td>
                                            <td>{bowler.economy}</td>
                                          </tr>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    <div>
                      {/* <div className=" mb-0">
                           
                            <div className="listpointtable d-flex">
                              <div className="width-heading">
                                <strong class=" d-flex ">Yet to Bat</strong>
                              </div>
                              <div className="d-flex flex-wrap width_90">
                                {[
                                  ...(Commentryplayers.Fixture &&
                                    Commentryplayers.Fixture.Innings[0]
                                      .Wickets),
                                  ...bestman,
                                  ...playerss.slice(0, 11),
                                ].map((item, index) => {
                                  const playerInfo = playerss.find(
                                    (player) => player.Id === item.PlayerId
                                  );

                                  if (!playerInfo) {
                                    return null;
                                  }

                                  return (
                                    <>
                                      {item.BallsFaced === 0 ? (
                                        <p>
                                          <div className=" new_text_info">
                                            {" "}
                                            <Link>
                                              {" "}
                                              {playerInfo.DisplayName}
                                            </Link>
                                            ,
                                          </div>
                                        </p>
                                      ) : null}
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div> */}
                      {/* <div class="stats bg-secondary  n">
                            Fall of Wickets
                          </div> */}
                    </div>
                    <div class="card ">
                      {/* <table className="d-flex">
                          <tbody className="fallinwicket">
  {Commentryplayers.Fixture &&
    Commentryplayers.Fixture.Innings[0].Wickets.map((item, index) => {
      const playerInfo = playerss.find((player) => player.Id === item.PlayerId);

      if (!playerInfo || item.IsOut === false) {
        return null;
      }

      return (
        <div key={index} className="">
          {item.Runs} - {item.Order} ({" "}
          <Link>{playerInfo.DisplayName}</Link>
          &nbsp;, {item.OverBallDisplay})&nbsp;
        </div>
      );
    })}
</tbody>

                          </table> */}
                    </div>
                  </div>
}
                </Col>
              )}

              {activeButton === "squads" && MatechDetail && (
                // {(activeButton === "squads") | Commentryplayers.Players && Commentryplayers.Players.length > 0 && (
                <Col md={8}>
                  <h4 className="bg-white text-center fw-bold p-2 mb-0">
                    Playing XI
                  </h4>
                  <table class="table">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="text-light text-left"
                          style={{ background: "#d32f2f" }}
                        >
                          <span className="d-flex align-items-center">
                            {/* <img
                                src={
                                  Commentryplayers.Fixture &&
                                  Commentryplayers.Fixture.HomeTeam.LogoUrl
                                }
                                alt="Home Team Logo"
                                className="img-fluid"
                                style={{
                                  border: "0.25px solid #adada8",
                                  height: "30px",
                                }}
                              /> */}
                            <span className="mx-1">
                              {MatechDetail.team1.shortName}
                            </span>
                          </span>
                        </th>
                        {/* <th
                            className=""
                            style={{ background: "#d32f2f" }}
                          ></th> */}
                        <th
                          scope="col"
                          className=" text-light text-right"
                          style={{ background: "#d32f2f" }}
                        >
                          <div className="d-flex justify-content-end align-items-center">
                            {/* <img
                                src={
                                  Commentryplayers.Fixture &&
                                  Commentryplayers.Fixture.AwayTeam.LogoUrl
                                }
                                alt="Away Team Logo"
                                className="img-fluid"
                                style={{
                                  border: "0.25px solid #adada8",
                                  height: "30px",
                                }}
                              /> */}
                            <p className="mb-0">
                              {MatechDetail.team2.shortName}
                            </p>
                          </div>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td className="borderr" style={{ width: "50%" }}>
                          {MatechDetail.team1.playerDetails &&
                            MatechDetail.team1.playerDetails.map(
                              (player, index) => (
                                <tr className="mb-2 d-block" key={player.id}>
                                  <td className="mb-3">
                                    <React.Fragment>
                                      <div className="d-flex">
                                        <img
                                          className="objectfit"
                                          src={
                                            player.id
                                              ? `${imageUrl}${player.id}.jpg`
                                              : Photo_Missing
                                          }
                                          style={{
                                            width: "50px",
                                            height: "50px",
                                            borderRadius: "50%",
                                          }}
                                          alt={"dsff"}
                                        />
                                        <div>
                                          <h6 className="mb-0 ttxt1 text-primary">
                                            {player.fullName}

                                            {player.keeper && " (WK)"}
                                            {player.captain && " (C)"}
                                          </h6>
                                          <p className="mb-0 ttxt2 pb-0">
                                            {player.role}
                                          </p>
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  </td>
                                </tr>
                              )
                            )}
                        </td>

                        <td style={{ width: "50%" }}>
                          {MatechDetail.team2.playerDetails &&
                            MatechDetail.team2.playerDetails.map(
                              (player, index) => (
                                <tr className="mb-2 d-block">
                                  <span className="d-flex align-items-center justify-content-end">
                                    <td className="d-flex">
                                      <React.Fragment key={player.id}>
                                        <div className="d-flex">
                                          <div>
                                            <h6 className="mb-0 playersss text-primary text-align-right">
                                              {player.fullName}

                                              {player.keeper && " (WK)"}
                                              {player.captain && " (C)"}
                                            </h6>
                                            <p className="ttxt mb-0 pb-0 text-align-right">
                                              {player.role}
                                            </p>
                                          </div>
                                          <img
                                            className="objectfit"
                                            src={
                                              player.id
                                                ? `${imageUrl}${player.id}.jpg`
                                                : Photo_Missing
                                            }
                                            style={{
                                              width: "50px",
                                              height: "50px",
                                              borderRadius: "50%",
                                            }}
                                          ></img>
                                        </div>
                                      </React.Fragment>
                                    </td>
                                  </span>
                                </tr>
                              )
                            )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              )}

              {/* 
{activeButton === "Live" && (
                <>
                  <Col md={8}>
                    {BestmanIndex3.length > 0 && (
                      <div className="bg-white">
                        <div className="stats d-flex  justify-content-between">
                          {Commentryplayers.Fixture &&
                            Commentryplayers.Fixture.Innings &&
                            Commentryplayers.Fixture.Innings[3] && (
                              <div>
                                {[3].map((inningIndex) => {
                                  const inning =
                                    Commentryplayers.Fixture.Innings[
                                      inningIndex
                                    ];

                                  if (!inning) {
                                    return null;
                                  }

                                  const {
                                    RunsScored,
                                    NumberOfWicketsFallen,
                                    OversBowled,
                                  } = inning;

                                  if (
                                    Commentryplayers.Fixture.Innings[
                                      inningIndex
                                    ].BattingTeamId ===
                                      Commentryplayers.Fixture.AwayTeam.Id ||
                                    Commentryplayers.Fixture.Innings[
                                      inningIndex
                                    ].BattingTeamId ===
                                      Commentryplayers.Fixture.HomeTeam.Id
                                  ) {
                                    return (
                                      <div key={inningIndex} className="d-flex">
                                        <img
                                          src={
                                            Commentryplayers.Fixture.Innings[
                                              inningIndex
                                            ].BattingTeamId ===
                                            Commentryplayers.Fixture.AwayTeam.Id
                                              ? Commentryplayers.Fixture
                                                  .AwayTeam.LogoUrl
                                              : Commentryplayers.Fixture
                                                  .HomeTeam.LogoUrl
                                          }
                                          style={{
                                            border: "0.25px solid #adada8",
                                            height: "30px",
                                            width: "30px",
                                          }}
                                          className="img-fluid"
                                          alt="Team Logo"
                                        />
                                        <strong className="mx-1">
                                          {Commentryplayers.Fixture.Innings[
                                            inningIndex
                                          ].BattingTeamId ===
                                          Commentryplayers.Fixture.AwayTeam.Id
                                            ? Commentryplayers.Fixture.AwayTeam
                                                .Name
                                            : Commentryplayers.Fixture.HomeTeam
                                                .Name}
                                        </strong>
                                        2nd Innings
                                      </div>
                                    );
                                  }

                                  return null;
                                })}
                              </div>
                            )}

                          {Commentryplayers.Fixture &&
                          Commentryplayers.Fixture ? (
                            <div>
                              {[3].map((inningIndex) => {
                                const inning =
                                  Commentryplayers.Fixture.Innings[inningIndex];

                                if (!inning) {
                                  return null;
                                }

                                const {
                                  RunsScored,
                                  NumberOfWicketsFallen,
                                  OversBowled,
                                } = inning;

                                return (
                                  <div key={inningIndex}>
                                    <p className="d-flex">
                                      <strong>{RunsScored}</strong> -
                                      <strong>{NumberOfWicketsFallen}</strong>
                                      <strong className="mx-1 ">(</strong>
                                      <span className="">
                                        <span>{OversBowled}</span>
                                        <span className="mx-1">Ov</span>
                                        <strong className="">)</strong>
                                      </span>
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      
                        <Container fluid>
                          <Row className="statssection">
                            <Col md={12} className="p-0">
                              <div className="PointTable">
                                <div className="pointtableheading d-flex justify-content-between"></div>
                                <div className="table-responsive">
                                  <table className="table mb-0">
                                    <thead className="pointablebg">
                                      <th colspan="2" className="matchtitle">
                                        Batter{" "}
                                      </th>

                                      <th className="text-center">R</th>

                                      <th className="text-center">B</th>
                                      <th className="text-center">4S</th>
                                      <th className="text-center">6S</th>
                                      <th className="text-center">SR</th>
                                    </thead>
                                    <tbody className="listpointtable text-center">
                                      {[
                                        ...BestmanIndex3,
                                        ...playerss.slice(12, 22),
                                      ].map((item) => {
                                        const playerInfo = playerss.find(
                                          (player) =>
                                            player.Id === item.PlayerId
                                        );

                                        if (!playerInfo) {
                                      
                                          return null; 
                                        }

                                        return (
                                          <>
                                            {item.BallsFaced !== 0 ? (
                                              <tr
                                                key={item.PlayerId}
                                                className={`${
                                                  item.IsBatting === true
                                                    ? "verticalalgin active-row"
                                                    : ""
                                                }`}
                                              >
                                                <td className="">
                                                  <img
                                                    src={
                                                      playerInfo.ImageUrl
                                                        ? playerInfo.ImageUrl
                                                        : Photo_Missing
                                                    }
                                                    alt=""
                                                    style={{
                                                      height: "40px",
                                                      borderRadius: "50%",
                                                    }}
                                                  />
                                                </td>
                                                <td className=" verticalalgin text-left text-primary ">
                                                  <p className="pb-0 mb-0 playernamescoreboard">
                                                    {playerInfo.DisplayName}
                                                  </p>

                                                  <div className="d-flex">
                                                    {item.IsOnStrike && (
                                                      <small className="text-danger">
                                                        *
                                                      </small>
                                                    )}
                                                    <p className="DismiText  mb-0 d-flex">
                                                      {item.DismissalText.replace(
                                                        /[^\w\s]/g,
                                                        ""
                                                      ).trim()}
                                                    </p>
                                                  </div>
                                                </td>

                                                {item.RunsScored !== null && (
                                                  <td c>
                                                    <strong>
                                                      {item.RunsScored ?? "0"}
                                                    </strong>
                                                  </td>
                                                )}
                                                {item.BallsFaced !== null && (
                                                  <td>
                                                    {item.BallsFaced ?? "0"}
                                                  </td>
                                                )}
                                                {item.SixesScored !== null && (
                                                  <td>
                                                    {item.SixesScored ?? "0"}
                                                  </td>
                                                )}
                                                {item.FoursScored !== null && (
                                                  <td>
                                                    {item.FoursScored ?? "0"}
                                                  </td>
                                                )}
                                                {item.StrikeRate !== null && (
                                                  <td>
                                                    {item.StrikeRate ?? "0"}
                                                  </td>
                                                )}
                                              </tr>
                                            ) : null}
                                          </>
                                        );
                                      })}
                                    </tbody>
                                 
                                  </table>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                    
                      </div>
                    )}

                    {BowlersIndex3.length > 0 && (
                      <div className="bg-white mt-2">
                        <Container fluid>
                          <Row className="statssection">
                            <Col md={12} className="p-0">
                              <div className="PointTable">
                                <div className="pointtableheading d-flex justify-content-between"></div>
                                <div className="table-responsive">
                                  <table className="table mb-0">
                                    <thead className="pointablebg  text-center">
                                      <th
                                        colspen="2"
                                        className="text-left matchtitle"
                                      >
                                        Bowlers
                                      </th>

                                      <th>O</th>

                                      <th>M</th>
                                      <th>R</th>
                                      <th>W</th>
                                      <th>Econ</th>
                                      <th>Wd</th>
                                      <th>NB</th>
                                      <th>DB</th>
                                    </thead>
                                    <tbody className="listpointtable text-center">
                                      {[
                                        ...BowlersIndex3,
                                        ...playerss.slice(0, 11),
                                      ].map((item) => {
                                        const playerInfo = playerss.find(
                                          (player) =>
                                            player.Id === item.PlayerId
                                        );

                                        if (!playerInfo) {
                                          // Handle the case where playerInfo is undefined
                                          return null; // or display a placeholder, throw an error, or handle it in another way
                                        }

                                        return (
                                          <tr
                                            key={item.PlayerId}
                                            className="verticalalgin bolwing"
                                          >
                                            <td className="d-flex align-items-center DismiText">
                                              <img
                                                src={
                                                  playerInfo.ImageUrl
                                                    ? playerInfo.ImageUrl
                                                    : Photo_Missing
                                                }
                                                alt=""
                                                style={{
                                                  height: "40px",
                                                  borderRadius: "50%",
                                                }}
                                              />
                                              <p className=" verticalalgin text-left    text-primary  margin-left-15 mb-0">
                                                {" "}
                                                {playerInfo.DisplayName}{" "}
                                                {item.IsOnStrike && (
                                                  <small className="">*</small>
                                                )}
                                              </p>
                                            </td>

                                        
                                            {item.OversBowled !== null && (
                                              <td>
                                                {item.BallsBowled === 0
                                                  ? item.OversBowled ?? "0"
                                                  : `${
                                                      item.OversBowled ?? "0"
                                                    }.${item.BallsBowled}`}
                                              </td>
                                            )}
                                            {item.MaidensBowled !== null && (
                                              <td>
                                                {item.MaidensBowled ?? "0"}
                                              </td>
                                            )}
                                            {item.RunsConceded !== null && (
                                              <td>
                                                {item.RunsConceded ?? "0"}
                                              </td>
                                            )}
                                            {item.WicketsTaken !== null && (
                                              <td>
                                                <strong>
                                                  {" "}
                                                  {item.WicketsTaken ?? "0"}
                                                </strong>
                                              </td>
                                            )}
                                            {item.Economy !== null && (
                                              <td>{item.Economy ?? "0"}</td>
                                            )}
                                            {item.WideBalls !== null && (
                                              <td>{item.WideBalls ?? "0"}</td>
                                            )}
                                            {item.NoBalls !== null && (
                                              <td>{item.NoBalls ?? "0"}</td>
                                            )}
                                            {item.DotBalls !== null && (
                                              <td>{item.DotBalls ?? "0"}</td>
                                            )}
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    )}
                    {BestmanIndex2.length > 0 && (
                      <div className="bg-white">
                        <div className="stats d-flex  justify-content-between">
                          {Commentryplayers.Fixture &&
                            Commentryplayers.Fixture.Innings &&
                            Commentryplayers.Fixture.Innings[2] && (
                              <div>
                                {[2].map((inningIndex) => {
                                  const inning =
                                    Commentryplayers.Fixture.Innings[
                                      inningIndex
                                    ];

                                  if (!inning) {
                                    return null;
                                  }

                                  const {
                                    RunsScored,
                                    NumberOfWicketsFallen,
                                    OversBowled,
                                  } = inning;

                                  if (
                                    Commentryplayers.Fixture.Innings[
                                      inningIndex
                                    ].BattingTeamId ===
                                      Commentryplayers.Fixture.AwayTeam.Id ||
                                    Commentryplayers.Fixture.Innings[
                                      inningIndex
                                    ].BattingTeamId ===
                                      Commentryplayers.Fixture.HomeTeam.Id
                                  ) {
                                    return (
                                      <div key={inningIndex} className="d-flex">
                                        <img
                                          src={
                                            Commentryplayers.Fixture.Innings[
                                              inningIndex
                                            ].BattingTeamId ===
                                            Commentryplayers.Fixture.AwayTeam.Id
                                              ? Commentryplayers.Fixture
                                                  .AwayTeam.LogoUrl
                                              : Commentryplayers.Fixture
                                                  .HomeTeam.LogoUrl
                                          }
                                          style={{
                                            border: "0.25px solid #adada8",
                                            height: "30px",
                                            width: "30px",
                                          }}
                                          className="img-fluid"
                                          alt="Team Logo"
                                        />
                                        <strong className="mx-1">
                                          {Commentryplayers.Fixture.Innings[
                                            inningIndex
                                          ].BattingTeamId ===
                                          Commentryplayers.Fixture.AwayTeam.Id
                                            ? Commentryplayers.Fixture.AwayTeam
                                                .Name
                                            : Commentryplayers.Fixture.HomeTeam
                                                .Name}
                                        </strong>
                                        2nd Innings
                                      </div>
                                    );
                                  }

                                  return null;
                                })}
                              </div>
                            )}

                          {Commentryplayers.Fixture &&
                          Commentryplayers.Fixture ? (
                            <div>
                              {[2].map((inningIndex) => {
                                const inning =
                                  Commentryplayers.Fixture.Innings[inningIndex];

                                if (!inning) {
                                  return null;
                                }

                                const {
                                  RunsScored,
                                  NumberOfWicketsFallen,
                                  OversBowled,
                                } = inning;

                                return (
                                  <div key={inningIndex}>
                                    <p className="d-flex">
                                      <strong>{RunsScored}</strong> -
                                      <strong>{NumberOfWicketsFallen}</strong>
                                      <strong className="mx-1 ">(</strong>
                                      <span className="">
                                        <span>{OversBowled}</span>
                                        <span className="mx-1">Ov</span>
                                        <strong className="">)</strong>
                                      </span>
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                       

                        <Container fluid>
                          <Row className="statssection">
                            <Col md={12} className="p-0">
                              <div className="PointTable">
                                <div className="pointtableheading d-flex justify-content-between"></div>
                                <div className="table-responsive">
                                  <table className="table mb-0">
                                    <thead className="pointablebg">
                                      <th colspan="2" className="matchtitle">
                                        Batter{" "}
                                      </th>

                                      <th className="text-center">R</th>

                                      <th className="text-center">B</th>
                                      <th className="text-center">4S</th>
                                      <th className="text-center">6S</th>
                                      <th className="text-center">SR</th>
                                    </thead>
                                    <tbody className="listpointtable text-center">
                                      {[
                                        ...BestmanIndex2,
                                        ...playerss.slice(0, 11),
                                      ].map((item) => {
                                        const playerInfo = playerss.find(
                                          (player) =>
                                            player.Id === item.PlayerId
                                        );

                                        if (!playerInfo) {
                              
                                          return null; 
                                        }

                                        return (
                                          <>
                                            {item.BallsFaced !== 0 ? (
                                              <tr
                                                key={item.PlayerId}
                                                className={`${
                                                  item.IsBatting === true
                                                    ? "verticalalgin active-row"
                                                    : ""
                                                }`}
                                              >
                                                <td className="">
                                                  <img
                                                    src={
                                                      playerInfo.ImageUrl
                                                        ? playerInfo.ImageUrl
                                                        : Photo_Missing
                                                    }
                                                    alt=""
                                                    style={{
                                                      height: "40px",
                                                      borderRadius: "50%",
                                                    }}
                                                  />
                                                </td>
                                                <td className=" verticalalgin text-left text-primary ">
                                                  <p className="pb-0 mb-0 playernamescoreboard">
                                                    {playerInfo.DisplayName}
                                                  </p>

                                                  <div className="d-flex">
                                                    {item.IsOnStrike && (
                                                      <small className="text-danger">
                                                        *
                                                      </small>
                                                    )}
                                                    <p className="DismiText  mb-0 d-flex">
                                                      {item.DismissalText.replace(
                                                        /[^\w\s]/g,
                                                        ""
                                                      ).trim()}
                                                    </p>
                                                  </div>
                                                </td>

                                                {item.RunsScored !== null && (
                                                  <td c>
                                                    <strong>
                                                      {item.RunsScored ?? "0"}
                                                    </strong>
                                                  </td>
                                                )}
                                                {item.BallsFaced !== null && (
                                                  <td>
                                                    {item.BallsFaced ?? "0"}
                                                  </td>
                                                )}
                                                {item.SixesScored !== null && (
                                                  <td>
                                                    {item.SixesScored ?? "0"}
                                                  </td>
                                                )}
                                                {item.FoursScored !== null && (
                                                  <td>
                                                    {item.FoursScored ?? "0"}
                                                  </td>
                                                )}
                                                {item.StrikeRate !== null && (
                                                  <td>
                                                    {item.StrikeRate ?? "0"}
                                                  </td>
                                                )}
                                              </tr>
                                            ) : null}
                                          </>
                                        );
                                      })}
                                    </tbody>
                                 
                                  </table>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                       
                      </div>
                    )}

                    {BowlersIndex2.length > 0 && (
                      <div className="bg-white mt-2">
                        <Container fluid>
                          <Row className="statssection">
                            <Col md={12} className="p-0">
                              <div className="PointTable">
                                <div className="pointtableheading d-flex justify-content-between"></div>
                                <div className="table-responsive">
                                  <table className="table mb-0">
                                    <thead className="pointablebg  text-center">
                                      <th
                                        colspen="2"
                                        className="text-left matchtitle"
                                      >
                                        Bowlers
                                      </th>

                                      <th>O</th>

                                      <th>M</th>
                                      <th>R</th>
                                      <th>W</th>
                                      <th>Econ</th>
                                      <th>Wd</th>
                                      <th>NB</th>
                                      <th>DB</th>
                                    </thead>
                                    <tbody className="listpointtable text-center">
                                      {[
                                        ...BowlersIndex2,
                                        ...playerss.slice(0, 11),
                                      ].map((item) => {
                                        const playerInfo = playerss.find(
                                          (player) =>
                                            player.Id === item.PlayerId
                                        );

                                        if (!playerInfo) {
                                        
                                          return null;  
                                        }

                                        return (
                                          <tr
                                            key={item.PlayerId}
                                            className="verticalalgin bolwing"
                                          >
                                            <td className="d-flex align-items-center DismiText">
                                              <img
                                                src={
                                                  playerInfo.ImageUrl
                                                    ? playerInfo.ImageUrl
                                                    : Photo_Missing
                                                }
                                                alt=""
                                                style={{
                                                  height: "40px",
                                                  borderRadius: "50%",
                                                }}
                                              />
                                              <p className=" verticalalgin text-left    text-primary  margin-left-15 mb-0">
                                                {" "}
                                                {playerInfo.DisplayName}{" "}
                                                {item.IsOnStrike && (
                                                  <small className="">*</small>
                                                )}
                                              </p>
                                            </td>

                                        
                                            {item.OversBowled !== null && (
                                              <td>
                                                {item.BallsBowled === 0
                                                  ? item.OversBowled ?? "0"
                                                  : `${
                                                      item.OversBowled ?? "0"
                                                    }.${item.BallsBowled}`}
                                              </td>
                                            )}
                                            {item.MaidensBowled !== null && (
                                              <td>
                                                {item.MaidensBowled ?? "0"}
                                              </td>
                                            )}
                                            {item.RunsConceded !== null && (
                                              <td>
                                                {item.RunsConceded ?? "0"}
                                              </td>
                                            )}
                                            {item.WicketsTaken !== null && (
                                              <td>
                                                <strong>
                                                  {" "}
                                                  {item.WicketsTaken ?? "0"}
                                                </strong>
                                              </td>
                                            )}
                                            {item.Economy !== null && (
                                              <td>{item.Economy ?? "0"}</td>
                                            )}
                                            {item.WideBalls !== null && (
                                              <td>{item.WideBalls ?? "0"}</td>
                                            )}
                                            {item.NoBalls !== null && (
                                              <td>{item.NoBalls ?? "0"}</td>
                                            )}
                                            {item.DotBalls !== null && (
                                              <td>{item.DotBalls ?? "0"}</td>
                                            )}
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    )}
                    {bestman1.length > 0 && (
                      <>
                    {Commentryplayers.Fixture.Innings[1].IsDeclared === true ?(
                      <div className="bg-white mt-2">
                    

                        <Container fluid>
                          <Row className="statssection">
                            <Col md={12} className="p-0">
                              <div className="PointTable">
                                <div className="pointtableheading d-flex justify-content-between">
                           
                                </div>
                                <div className="table-responsive">
                                  <table className="table mb-0">
                                    <thead className="pointablebg text-center">
                                      <th
                                        colspan="2"
                                        className="text-left matchtitle"
                                      >
                                        Batter{" "}
                                      </th>

                                      <th>R</th>

                                      <th>B</th>
                                      <th>4S</th>
                                      <th>6S</th>
                                      <th>SR</th>
                                    </thead>
                                    <tbody className="listpointtable text-center">
                                      {[
                                        ...bestman1,
                                        ...playerss.slice(12, 22),
                                      ].map((item) => {
                                        const playerInfo = playerss.find(
                                          (player) =>
                                            player.Id === item.PlayerId
                                        );

                                        if (!playerInfo) {
                                 
                                          return null;
                                        }

                                        return (
                                          <>
                                             {item.IsBatting === true  ? (
                                              <tr
                                                key={item.PlayerId}
                                                
                                              >
                                                <td>
                                                  <img
                                                    src={
                                                      playerInfo.ImageUrl
                                                        ? playerInfo.ImageUrl
                                                        : Photo_Missing
                                                    }
                                                    alt=""
                                                    style={{
                                                      height: "40px",
                                                      borderRadius: "50%",
                                                    }}
                                                  />
                                                </td>

                                                <td className=" verticalalgin text-left text-primary ">
                                                  {playerInfo.DisplayName}
                                                  {item.IsOnStrike && (
                                                    <small className="text-danger">
                                                      *
                                                    </small>
                                                  )}
                                                  <br></br>
                                                  <p className="DismiText">
                                                    {item.DismissalText.replace(
                                                      /[^\w\s]/g,
                                                      ""
                                                    ).trim()}
                                                  </p>{" "}
                                                </td>

                                                {item.RunsScored !== null && (
                                                  <td>
                                                    <strong>
                                                      {item.RunsScored ?? "0"}
                                                    </strong>
                                                  </td>
                                                )}
                                                {item.BallsFaced !== null && (
                                                  <td>
                                                    {item.BallsFaced ?? "0"}
                                                  </td>
                                                )}
                                                {item.SixesScored !== null && (
                                                  <td>
                                                    {item.SixesScored ?? "0"}
                                                  </td>
                                                )}
                                                {item.FoursScored !== null && (
                                                  <td>
                                                    {item.FoursScored ?? "0"}
                                                  </td>
                                                )}
                                                {item.StrikeRate !== null && (
                                                  <td>
                                                    {item.StrikeRate ?? "0"}
                                                  </td>
                                                )}
                                              </tr>
                                            ) : null}
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  
                                  </table>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                     
                       
                      </div>
                     ):null}
                      </>
                    )}
{Bowlerss1.length > 0 && (
  <>
    {Bowlerss1.some((item) => item.IsOnStrike === true || item.IsNonStrike === true) && (
      <div className="bg-white mt-2">
        <Container fluid>
          <Row className="statssection">
            <Col md={12} className="p-0">
              <div className="PointTable">
                <div className="pointtableheading d-flex justify-content-between"></div>
                <div className="table-responsive">
                  <table className="table mb-0">
                  <thead className="pointablebg  text-center">
                                      <th
                                        colspen="2"
                                        className="text-left matchtitle"
                                      >
                                        Bowlers
                                      </th>

                                      <th>O</th>

                                      <th>M</th>
                                      <th>R</th>
                                      <th>W</th>
                                      <th>Econ</th>
                                      <th>Wd</th>
                                      <th>NB</th>
                                      <th>DB</th>
                                    </thead>
                    <tbody className="listpointtable text-center">
                      {[
                        ...Bowlerss1,
                        ...playerss.slice(0, 11),
                      ].filter((item) => item.IsOnStrike === true || item.IsNonStrike === true).map((item) => {
                        const playerInfo = playerss.find((player) => player.Id === item.PlayerId);

                        if (!playerInfo) {
                        
                          return null;
                        }

                        return (
                          <tr
                          key={item.PlayerId}
                          className="verticalalgin bolwing"
                        >
                          <td className="d-flex align-items-center DismiText">
                            <img
                              src={
                                playerInfo.ImageUrl
                                  ? playerInfo.ImageUrl
                                  : Photo_Missing
                              }
                              alt=""
                              style={{
                                height: "40px",
                                borderRadius: "50%",
                              }}
                            />
                            <p className=" verticalalgin text-left    text-primary  margin-left-15 mb-0">
                              {" "}
                              {playerInfo.DisplayName}{" "}
                              {item.IsOnStrike && (
                                <small className="">*</small>
                              )}
                            </p>
                          </td>

                          {item.OversBowled !== null && (
                            <td>
                              {item.BallsBowled === 0
                                ? item.OversBowled ?? "0"
                                : `${
                                    item.OversBowled ?? "0"
                                  }.${item.BallsBowled}`}
                            </td>
                          )}
                          {item.MaidensBowled !== null && (
                            <td>
                              {item.MaidensBowled ?? "0"}
                            </td>
                          )}
                          {item.RunsConceded !== null && (
                            <td>
                              {item.RunsConceded ?? "0"}
                            </td>
                          )}
                          {item.WicketsTaken !== null && (
                            <td>
                              <strong>
                                {" "}
                                {item.WicketsTaken ?? "0"}
                              </strong>
                            </td>
                          )}
                          {item.Economy !== null && (
                            <td>{item.Economy ?? "0"}</td>
                          )}
                          {item.WideBalls !== null && (
                            <td>{item.WideBalls ?? "0"}</td>
                          )}
                          {item.NoBalls !== null && (
                            <td>{item.NoBalls ?? "0"}</td>
                          )}
                          {item.DotBalls !== null && (
                            <td>{item.DotBalls ?? "0"}</td>
                          )}
                        </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )}
  </>
)}

                   
                    {bestman.length > 0 && (
                      <>
                       {Commentryplayers.Fixture.Innings[0].IsDeclared === true ?(
     
                      <div className="bg-white">
                 

                        <Container fluid>
                          <Row className="statssection">
                            <Col md={12} className="p-0">
                              <div className="PointTable">
                                <div className="pointtableheading d-flex justify-content-between"></div>
                                <div className="table-responsive">
                                  <table className="table mb-0">
                                    <thead className="pointablebg">
                                      <th colspan="2" className="matchtitle">
                                        Batter{" "}
                                      </th>

                                      <th className="text-center">R</th>

                                      <th className="text-center">B</th>
                                      <th className="text-center">4S</th>
                                      <th className="text-center">6S</th>
                                      <th className="text-center">SR</th>
                                    </thead>
                                    <tbody className="listpointtable text-center">
                                      {[
                                        ...bestman,
                                        ...playerss.slice(0, 11),
                                      ].map((item) => {
                                        const playerInfo = playerss.find(
                                          (player) =>
                                            player.Id === item.PlayerId
                                        );

                                        if (!playerInfo) {
                                          return null;
                                        }

                                        return (
                                          <>
                                              {item.IsBatting === true  ? (
                                              <tr
                                              
                                              >
                                                <td className="">
                                                  <img
                                                    src={
                                                      playerInfo.ImageUrl
                                                        ? playerInfo.ImageUrl
                                                        : Photo_Missing
                                                    }
                                                    alt=""
                                                    style={{
                                                      height: "40px",
                                                      borderRadius: "50%",
                                                      width: "100%!important;",
                                                      objectFit: "contain",
                                                    }}
                                                  />
                                                </td>
                                                <td className=" verticalalgin text-left text-primary ">
                                                  <p className="pb-0 mb-0 playernamescoreboard">
                                                    {playerInfo.DisplayName}
                                                  </p>

                                                  <div className="d-flex">
                                                    {item.IsOnStrike && (
                                                      <small className="text-danger">
                                                        *
                                                      </small>
                                                    )}
                                                    <p className="DismiText  mb-0 d-flex">
                                                      {item.DismissalText.replace(
                                                        /[^\w\s]/g,
                                                        ""
                                                      ).trim()}
                                                    </p>
                                                  </div>
                                                </td>

                                                {item.RunsScored !== null && (
                                                  <td c>
                                                    <strong>
                                                      {item.RunsScored ?? "0"}
                                                    </strong>
                                                  </td>
                                                )}
                                                {item.BallsFaced !== null && (
                                                  <td>
                                                    {item.BallsFaced ?? "0"}
                                                  </td>
                                                )}
                                                {item.SixesScored !== null && (
                                                  <td>
                                                    {item.SixesScored ?? "0"}
                                                  </td>
                                                )}
                                                {item.FoursScored !== null && (
                                                  <td>
                                                    {item.FoursScored ?? "0"}
                                                  </td>
                                                )}
                                                {item.StrikeRate !== null && (
                                                  <td>
                                                    {item.StrikeRate ?? "0"}
                                                  </td>
                                                )}
                                              </tr>
                                            ) : null}
                                          </>
                                        );
                                      })}
                                    </tbody>
                                   
                                  </table>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      
                      </div>
                      ):null}
                 
                      </>
                    )}
                    {Bowlerss.length > 0 && (
                      <>
                        {Bowlerss.some((item) => item.IsOnStrike === true && item.IsNonStrike) && (
                      <div className="bg-white mt-2">
                        <Container fluid>
                          
                          <Row className="statssection">
                            <Col md={12} className="p-0">
                              <div className="PointTable">
                                <div className="pointtableheading d-flex justify-content-between"></div>
                                <div className="table-responsive">
                                  <table className="table mb-0">
                                    <thead className="pointablebg  text-center">
                                      <th
                                        colspen="2"
                                        className="text-left matchtitle"
                                      >
                                        Bowlers
                                      </th>

                                      <th>O</th>

                                      <th>M</th>
                                      <th>R</th>
                                      <th>W</th>
                                      <th>Econ</th>
                                      <th>Wd</th>
                                      <th>NB</th>
                                      <th>DB</th>
                                    </thead>
                                    <tbody className="listpointtable text-center">
                                      {[
                                        ...Bowlerss,
                                        ...playerss.slice(0, 11),
                                      ].map((item) => {
                                        const playerInfo = playerss.find(
                                          (player) =>
                                            player.Id === item.PlayerId
                                        );

                                        if (!playerInfo) {
                                        
                                          return null; 
                                        }

                                        return (
                                          <>
                            
                                            {item.IsOnStrike === true && item.IsNonStrike ? (
                                              <tr
                                                key={item.PlayerId}
                                                className="verticalalgin bolwing"
                                              >
                                                <td className="d-flex align-items-center DismiText">
                                                  <img
                                                    src={
                                                      playerInfo.ImageUrl
                                                        ? playerInfo.ImageUrl
                                                        : Photo_Missing
                                                    }
                                                    alt=""
                                                    style={{
                                                      height: "40px",
                                                      borderRadius: "50%",
                                                    }}
                                                  />
                                                  <p className=" verticalalgin text-left    text-primary  margin-left-15 mb-0">
                                                    {" "}
                                                    {
                                                      playerInfo.DisplayName
                                                    }{" "}
                                                    {item.IsOnStrike && (
                                                      <small className="">
                                                        *
                                                      </small>
                                                    )}
                                                  </p>
                                                </td>

                                           
                                                {item.OversBowled !== null && (
                                                  <td>
                                                    {item.BallsBowled === 0
                                                      ? item.OversBowled ?? "0"
                                                      : `${
                                                          item.OversBowled ??
                                                          "0"
                                                        }.${item.BallsBowled}`}
                                                  </td>
                                                )}
                                                {item.MaidensBowled !==
                                                  null && (
                                                  <td>
                                                    {item.MaidensBowled ?? "0"}
                                                  </td>
                                                )}
                                                {item.RunsConceded !== null && (
                                                  <td>
                                                    {item.RunsConceded ?? "0"}
                                                  </td>
                                                )}
                                                {item.WicketsTaken !== null && (
                                                  <td>
                                                    <strong>
                                                      {" "}
                                                      {item.WicketsTaken ?? "0"}
                                                    </strong>
                                                  </td>
                                                )}
                                                {item.Economy !== null && (
                                                  <td>{item.Economy ?? "0"}</td>
                                                )}
                                                {item.WideBalls !== null && (
                                                  <td>
                                                    {item.WideBalls ?? "0"}
                                                  </td>
                                                )}
                                                {item.NoBalls !== null && (
                                                  <td>{item.NoBalls ?? "0"}</td>
                                                )}
                                                {item.DotBalls !== null && (
                                                  <td>
                                                    {item.DotBalls ?? "0"}
                                                  </td>
                                                )}
                                              </tr>
                                            ) : null}
                                          </>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                        )}
                      </>
                    )}

                  
                  </Col>
                </>
              )} */}

              {activeButton === "scorecard" && (
                <>
                  <Col md={8}>
                    <table class="table">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="  text-light"
                            style={{ background: "#d32f2f" }}
                          >
                            INFO
                          </th>
                          <th
                            scope="col"
                            className=" "
                            style={{ background: "#d32f2f" }}
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="w-40">Match</td>
                          <td>
                            {" "}
                            {MatechDetail.team1 &&
                              formatTeamName(
                                MatechDetail.team1 && MatechDetail.team1.name
                              )}{" "}
                            <span className="mx-1">vs</span>{" "}
                            {MatechDetail.team2 &&
                              formatTeamName(
                                MatechDetail.team2 && MatechDetail.team2.name
                              )}{" "}
                            <span className="m-1">.</span>
                            {MatechDetail.series && MatechDetail.series.name}
                          </td>
                        </tr>
                        <tr>
                          <td className="w-40">Series</td>

                          <td>
                            {MatechDetail.series && MatechDetail.series.name}
                          </td>
                        </tr>
                        <tr>
                          <td className="w-40">Result</td>

                          <td>
                            {MatechDetail.series &&
                              MatechDetail.series.testSeriesResult}
                          </td>
                        </tr>
                        <tr>
                          <td className="w-40">Date</td>
                          <td>
                            {(() => {
                              const startDate =
                                MatechDetail &&
                                MatechDetail.matchStartTimestamp;
                              const formattedDate = new Date(
                                startDate
                              ).toLocaleDateString("en-US", {
                                weekday: "short",
                                day: "numeric",
                                month: "short",
                              });

                              const currentDate = new Date();
                              const isToday =
                                currentDate.toDateString() ===
                                new Date(startDate).toDateString();

                              return isToday ? "today" : formattedDate;
                            })()}
                          </td>
                        </tr>
                        <tr>
                          <td>Time</td>
                          <td>
                            <span>
                              {new Date(
                                MatechDetail && MatechDetail.matchStartTimestamp
                              ).toLocaleTimeString([], {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              })}
                              <span className="mx-1"></span>LOCAL
                            </span>
                            <span className="m-1">,</span>
                            <span>
                              {new Date(
                                MatechDetail && MatechDetail.matchStartTimestamp
                              ).toLocaleTimeString("en-US", {
                                timeZone: "GMT",
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              })}{" "}
                              GMT
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>Toss</td>

                          <td>
                            {MatechDetail &&
                              MatechDetail.tossResults.tossWinnerName}

                            {MatechDetail && MatechDetail.tossResults.decision}
                          </td>
                        </tr>
                        <tr>
                          <td>Venue</td>

                          <td>
                            {MatechDetail && MatechDetail.venue.name}
                            <span className="m-1">,</span>
                            {MatechDetail && MatechDetail.venue.city}
                            <span className="m-1">,</span>
                            {MatechDetail && MatechDetail.venue.country}
                          </td>
                        </tr>
                        <tr>
                          <td>Stadium</td>

                          <td>{MatechDetail && MatechDetail.venue.name}</td>
                        </tr>
                        <tr>
                          <td>City</td>
                          {/* <td>TossResult</td> */}
                          <td>{MatechDetail && MatechDetail.venue.city}</td>
                        </tr>
                        <tr>
                          <td>
                            {MatechDetail.team1 &&
                              formatTeamName(
                                MatechDetail.team1 && MatechDetail.team1.name
                              )}{" "}
                            Squads
                          </td>

                          {/* <td>TossResult</td> */}

                          <td>
                            {MatechDetail.team1.playerDetails &&
                              MatechDetail.team1.playerDetails.map(
                                (player, index) => (
                                  <React.Fragment key={player.id}>
                                    <span>{player.fullName}</span>
                                    {player.keeper && " (WK)"}
                                    {player.captain && " (C)"}
                                    {/* Add comma after each player except the last one */}
                                    {index <
                                      MatechDetail.team1.playerDetails.length -
                                        1 && <span className="m-1">, </span>}
                                  </React.Fragment>
                                )
                              )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {MatechDetail.team2 &&
                              formatTeamName(
                                MatechDetail.team2 && MatechDetail.team2.name
                              )}{" "}
                            Squads
                          </td>

                          <td>
                            {MatechDetail.team2.playerDetails &&
                              MatechDetail.team2.playerDetails.map(
                                (player, index) => (
                                  <React.Fragment key={player.id}>
                                    <span>{player.fullName}</span>
                                    {player.keeper && " (WK)"}
                                    {player.captain && " (C)"}
                                    {/* Add comma after each player except the last one */}
                                    {index <
                                      MatechDetail.team2.playerDetails.length -
                                        1 && <span className="m-1">, </span>}
                                  </React.Fragment>
                                )
                              )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </>
              )}

              <Col md={4}>
                <Rightnewssection />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
}
