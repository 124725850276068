import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Tabheader from "./Tabheader";
import { Link } from "react-router-dom";
import video1 from "../../src/assets/image/video1.png";
import profile from "../../src/assets/image/profile.png";
import latestnews2 from "../../src/assets/image/latestnews2.png";
import axios from "axios";
import YouTube from "react-youtube";
import dateFormat from "dateformat";
import Rightnewssection from "./Rightnewssection";
import { useParams } from "react-router-dom";
const url = new URL(window.location.href);
const gameid = url.searchParams.get("id");
export default function News_Detail() {
  const [usersvideo, setUservideo] = useState([]);
  const [UserNews, setUserNews] = useState([]);
  const [singleVideo, setSingleVideo] = useState([]);
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState({});
  const playvideo = localStorage.getItem("playvideo");
  const [baseUrlnews, setBaseUrl] = useState("");
  // const baseUrlnews = "https://sportspad.com/Admin/public/admin/uploads/news";
  // alert(gameid)
  // alert(playvideo)
  // function stripHtmlTags(html) {
  //   const strippedHtml = html.replace(/<[^>]*>/g, '');
  //   return strippedHtml;
  // }
  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const renderText = (text) => {
    // Check for <strong> tag
    if (text.includes("<strong>")) {
      return <strong>{text.replace(/<\/?strong>/g, "")}</strong>;
    }

    // Check for emoji syntax (assuming :emoji_name:)
    const emojiRegex = /:(\w+):/g;
    if (text.match(emojiRegex)) {
      return text.split(emojiRegex).map((part, index) => {
        return index % 2 === 0 ? (
          part
        ) : (
          <span key={index} className="emoji">
            {part}
          </span>
        );
      });
    }

    return text;
  };
  const { id } = useParams();

  useEffect(() => {
    playVideo(id);
  }, [id]);

  useEffect(() => {
    playGame();
    playVideowww()
  }, []);

  const playGame = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/video-link`
      );
      const result = response.data.data;
      setUservideo(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getVideoId = (url) => {
    if (!url) {
      return null;
    }

    const match = url.match(
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    return match && match[1];
  };

  const getThumbnailUrl = (url) => {
    const videoId = getVideoId(url);
    return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : "";
  };

  const playVideo = async (videoId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/singleNews?id=${gameid}`
      );
      const videoDetails = response.data.data;

      const resBowlersDetails = JSON.stringify(videoDetails);
      const ResresBowlersDetails = JSON.parse(resBowlersDetails);
      // console.warn(ResresBowlersDetails);
      setSingleVideo([ResresBowlersDetails]);
      setSelectedVideo(videoDetails);
      setBaseUrl("https://sportspad.com/Admin/public/admin/uploads/news");
    } catch (error) {
      console.error("Error fetching video data:", error);
    }
  };
  const playVideowww = async (videoId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URLWWW}/singleNews?id=${gameid}`
      );
      const videoDetails = response.data.data;

      const resBowlersDetails = JSON.stringify(videoDetails);
      const ResresBowlersDetails = JSON.parse(resBowlersDetails);
      // console.warn(ResresBowlersDetails);
      setSingleVideo([ResresBowlersDetails]);
      setSelectedVideo(videoDetails);
      setBaseUrl("https://www.sportspad.com/Admin/public/admin/uploads/news");
    } catch (error) {
      console.error("Error fetching video data:", error);
    }
  };

  return (
    <>
      <Tabheader />
      <section className="Featurevideo" id="Featurevideo">
        <Container>
          <div className="d-flex border-bottom-custom">
            {/* <ul className="d-flex matcheslist">
              <Link to="/Cricketlivescore" className="text-dark">
                {" "}
                <li>LLC 2023</li>
              </Link>

              <Link to="/Cricketlivescore" className="text-dark">
                <li>Women's Big Bash League 2023</li>
              </Link>

              <li>Stats</li>
              <Link to="/Predictiontips" className="text-dark">
                {" "}
                <li>Today Match Prediction</li>
              </Link>
            </ul> */}
          </div>
          {/* <div className="feadturevideoheading">
            <p>Feature Video</p>
          </div> */}
        </Container>
        <div className="cricketscore videopage">
          <Container>
            <div className="Sectab">
              <ul>
                <li className="activetab">
                  <Link to="/Video_Detail">All</Link>
                </li>
                <li className="">
                  <Link to="/Video_Detail">Cricket</Link>
                </li>
                <li>
                  <Link to="/Video_Detail">Football</Link>
                </li>
                <li>
                  <Link to="/Video_Detail">Kabaddi</Link>
                </li>
              </ul>
            </div>
          </Container>
        </div>
        <div className="newvideofeature">
          <Container>
            <Row>
              {/* ........................................All Video side .................. */}
              <Col md={8}>
                <div className="bg-color-new-video">
                  {/* <div className="video1">
                    <div className="haedingview d-flex justify-content-between">
                      <h3>Legends League Cricket</h3>
                      <button className="viewall"><Link to='/News' className="text-light">View All</Link></button>
                    </div>
                  </div> */}

                  <Row>
                    <Col md={12}>
                      {/* ........................................Latest News side .................. */}
                      {singleVideo &&
                        singleVideo.map((set, setIndex) => (
                          <div className="latestnewssec bg-white">
                            <h1 className="">{set.title}</h1>
                            <div className="latestnewssection ">
                              <img
                                // src={set.image}
                                src={`${baseUrlnews}/${set.image}`}
                                alt={`Thumbnail`}
                                style={{
                                margin:"4px",
                                  height: "100%",
                                  width: "100%",
                                }}
                              
                              />
                            </div>
                            <div className="padding_new">
                              <span className="align-item-center">
                                <div>
                                  <div class="mvp-author-info-wrap left relative">
                                    <div class="mvp-author-info-thumb left relative">
                                      <img
                                        alt=""
                                        src={profile}
                                        height="46"
                                        width="46"
                                        loading="lazy"
                                        decoding="async"
                                      />{" "}
                                    </div>
                                    <div class="mvp-author-info-text left relative">
                                      <div class="mvp-author-info-date left relative">
                                        {/* <i className="fa fa-clock" />{" "} */}
                                        <i class="fa fa-calendar" aria-hidden="true"></i>

                                        <span class="mvp-post-date updated mx-1">
                                       
                                          <time
                    class="post-date updated"
                    itemprop="datePublished"
                    datetime={dateFormat(set.updated_at, "isoDateTime")}
                  >
                    {dateFormat(set.updated_at, "mmm dS, yyyy | hh:MMtt")}
                  </time>
                                        </span>
                                      </div>
                                      <div
                                        class="mvp-author-info-name left relative"
                                        itemprop="author"
                                        itemscope=""
                                        // itemtype="https://schema.org/Person"
                                      >
                                        <p>By</p>{" "}
                                        <span
                                          class="author-name vcard fn author"
                                          itemprop="name"
                                        >
                                          <a
                                            href="https://sportspad.com/"
                                            // title="Posts by Zox News Staff"
                                            rel="author"
                                          >
                                            Sportspad
                                          </a>
                                        </span>{" "}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </span>

                              <small
                                className="mb-2]
                            "
                              >
                                <p className="paragraphcolor">
                                  {stripHtmlTags(set.description)
                                    .split("\n")
                                    .map((item, key) => (
                                      <div key={key}>
                                        <p className="">{renderText(item)}</p>
                                      </div>
                                    ))}
                                </p>
                              </small>
                            </div>
                          </div>
                        ))}
                      {/* <Rightnewssection /> */}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md={4}>
             

                <Rightnewssection />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
}
