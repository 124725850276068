import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Login.scss";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import $ from "jquery";
import { Link } from 'react-router-dom';

export default function Login() {
  sessionStorage.setItem("token", "");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorotpMessage, setErrorotpMessage] = useState("");
  const [user, setUserts] = useState({
    mobile: "",
  });

  const [motp, setMotp] = useState({
    mobile: "9983908152",
    otp: "3702",
  });

  const [otpsand, setOtpsend] = useState(null);
  const [isShown, setIsShown] = useState(false);
  const [mobile, mobilenumber] = useState();
  const [otpnumbers, otpnumber] = useState();

  function onInputChange(e) {
    const numericValue = e.target.value;

    mobilenumber(numericValue);
    setErrorMessage("");
  }
  function onInputChangeotp(e) {
    const numericValue = e.target.value;

    otpnumber(numericValue);
   
    setErrorotpMessage("");
  }

  const onSubmit = async (e) => {
    e.preventDefault();

  
    const isValidMobileNumber = /^\d{10}$/.test(mobile);

    if (!isValidMobileNumber) {
    
      setErrorMessage("Please enter a valid 10-digit mobile number.");
      return;
    }
  

  try {
    let response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
      method: "POST",
      phone_number: mobile,
    });

    if (response.status) {
      Swal.fire({
        title:"OTP Send Successfully",
        timer: 1500,
        icon: 'success',
      });

      setIsShown(true);
    } else {
      setIsShown(false);
    }
  } catch (error) {
    // Handle the error and show a SweetAlert
    console.error('Error in login request:', error);
    Swal.fire({
      title: 'Error',
      text: 'An error occurred while sending the OTP. Please try again.',
      icon: 'error',
      timer: 1500,
    });
  }
  
  };
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const loginverfiy = async (e) => {
    e.preventDefault();
    
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/otpverify`,
        {
          method: "POST",
          phone_number: mobile,
          otp: otpnumbers,
        }
      );

      if (response.status === 200) {
      
        Swal.fire({
          title:"Login Successfully",
          timer: 1500,
          icon: 'success',
        }).then((result) => {
          
          localStorage.setItem("token_store_cricket", response.data.data.token);
          sessionStorage.setItem("username", response.data.data.user.username);
          localStorage.setItem("username", response.data.data.user.username);
          localStorage.setItem("userid", response.data.data.user.id);
          localStorage.setItem("mobile", response.data.data.user.mobile);
          setTimeout(() => {
            window.location.href = "/";
          }, 1500);
        });
    
      } else {
        Swal.fire({
          title: <strong>Invalid OTP!</strong>,
          timer: 1500,
          icon: 'success',
        })
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <div id="Login">
        <div class="contact-wrapper">
          <header class="login-cta">
            <h2>Account Login</h2>
          </header>
          <form method="post" id="user_login" onSubmit={onSubmit}>
         
     
            <div className="w-100  mx-auto">
              <div className="d-flex font-15 ">Log In </div>
              <div className=" cxy flex-column" id="incheight">
                <div id="sign_up_success" />
                <div
                  className="input-group"
                  style={{ transition: "top 0.5s ease 0s" }}
                >
                  <div className="input-group-prepend">
                    <div
                      className="input-group-text border-radius"
                      style={{ width: "80px", height: "40px" }}
                    >
                      +91
                    </div>
                  </div>
               
                    <input
                      className="form-control"
                      name="mobile"
                      id="mobile"
                      type="number"
                      placeholder="Mobile number"
                      value={mobile}
                      style={{ transition: "all 3s ease-out 0s" }}
                      onChange={onInputChange}
                    />
                 <div className="error-message text-danger" >{errorMessage}</div>
                </div>
                {loading && (
                  <div className="loader-container">
                    <div className="loader"></div>
                  </div>
                )}


                {isShown ? (
                  <div
                    className="input-group pt-2 otp"
                    style={{ transition: "left 0.5s ease 0s" }}
                  >
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text border-radius"
                        style={{ width: "80px", height: "48px" }}
                      >
                        OTP
                      </div>
                    </div>                   
                      <input
                        className="form-control"
                        name="otp"
                        id="otp"
                        type="tel"
                        onChange={onInputChangeotp}
                        placeholder="Enter OTP"
                        autoComplete="off"
                        required
                      />                 
                    <div className="invalid-feedback">Enter a correct OTP</div>
                  </div>
                ) : null}
              </div>
              {isShown ? (
                <button
                  type="button"
                  onClick={loginverfiy}
                  className="bg-green refer-button w-100 text-white btn-primary cxy btn  otp_login submit_data mt-2"
                  id="submit_data"
                  name="submit_data"
                >
                    <Link to="/" className=' text-light'> Login</Link> 
              
                </button>
              ) : (
                <button
                  type="submit"
                  className="w-100 bg-green refer-button cxy  send-otp btn btn-primary text-white mt-2"
                  id="send_ottp"
                >
              Send OTP
                </button>
              )}
            </div>
          </form>
          <div class="socials-wrapper">
            <header>
              <h2>Login with your Social Account</h2>{" "}
            </header>
            <ul>
              <li>
                <a href="#" class="facebook">
                  <i class="fab fa-facebook-square"></i>
                </a>
              </li>
              <li>
                <a href="#" class="twitter">
                  <i class="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#" class="twitch">
                  <i class="fab fa-twitch"></i>
                </a>
              </li>
              <li>
                <a href="#" class="youtube">
                  <i class="fab fa-youtube"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
