import React ,{useState,useEffect}from 'react'
import axios from 'axios';
import { Container } from 'react-bootstrap';
import {Link} from 'react-router-dom';
export default function Tabheader() {
  const [games, setGames] = useState([]);
  const [set, setSet] = useState([]);
  const sportId = 4; 
  useEffect(() => {
    playGame4();
  }, []);

  const playGame4 = async () => {
    try {
      const response = await axios.GET(
        `${process.env.REACT_APP_API_URL}/match-list?sport_id=${sportId}`,
        {}
        );
   
      alert(response)
      const result = response.data.data.matches;
      const matchname = response.data.data.matches.map(
        (series) => series.name
      );
      setSet(matchname);
      console.warn(matchname);
      console.warn(result)
      setGames(result);
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }; 
  return (
   <>
     <div className="bg-white">
     <Container>
    <ul className="Tabheader d-flex justify-content-between">
    {games.map(( series,index)=>(
      <li key={index}> 
        <Link to="/">{series.name}</Link>
      </li>
      ))}
      {/* <li>
        <Link to="/">LLC 2023</Link>
      </li>
      <li>
        <Link to="/">Pakistan Cup</Link>
      </li>
      <li>
        <Link to="/">Women's Big Bash League 2023</Link>
      </li>
      <li>
        <Link to="/">Saudi Pro League</Link>
      </li>
      <li>
        <Link to="/">Stats</Link>
      </li>
      <li>
        <Link to="/">Today Match Prediction</Link>
      </li> */}
      
   </ul>
   </Container>
     </div>
   </>
  )
}

