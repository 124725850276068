import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import Logo from '../../assets/image/logo.png';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import '../Header/Header.scss';
import axios from "axios";
// import { REACT_APP_API_URL } from '../../env';
function Header() {
  const [set, setSet] = useState([]);
  const [usersrank, setUsersrank] = useState([]);
  const [date, setDate] = useState();
  const [isNavbarMenuActive, setNavbarMenuActive] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const navbarMenu = document.getElementById("navbar");
  const burgerMenu = document.getElementById("burger");
  const overlayMenu = document.querySelector(".overlay");
  // const fixedbottom = document.querySelector(".fixed-bottom");
  //  useEffect(() => {
  //     burgerMenu.addEventListener("click", toggleMenu);
  //     overlayMenu.addEventListener("click", toggleMenu);
  //     navbarMenu.addEventListener("click", toggleSubMenu);
  //     window.addEventListener("resize", resizeWindow);

  //     // Cleanup Event Listeners on Component Unmount
  //     return () => {
  //       burgerMenu.removeEventListener("click", toggleMenu);
  //       overlayMenu.removeEventListener("click", toggleMenu);
  //       navbarMenu.removeEventListener("click", toggleSubMenu);
  //       window.removeEventListener("resize", resizeWindow);
  //     };
  //   }
  //   , [isNavbarMenuActive]);
  // Show and Hide Navbar Function
  const handleButtonClickName = (seriesNam) => {
    localStorage.setItem("selectedSeriesName", seriesNam);

  };
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const options = {
        method: 'GET',
        url: 'https://cricbuzz-cricket.p.rapidapi.com/matches/v1/recent',

        headers: {
          'X-RapidAPI-Key': '8a09779cc5msh8d06b8be0a9d59bp19c20fjsn64a51b7d7f64',
    'X-RapidAPI-Host': 'cricbuzz-cricket.p.rapidapi.com'
        }
      };

      try {
        const response = await axios.request(options);
        const result = response.data.typeMatches
     
        console.warn(result);
        
        setData(result)

      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);


  const toggleMenu = () => {
    setNavbarMenuActive(!isNavbarMenuActive);
    navbarMenu.classList.toggle("active");
    overlayMenu.classList.toggle("active");
    // fixedbottom.classList.toggle("fixed-bottom");

  };

  useEffect(() => {
    // Check if the user is logged in (you may need to adjust this logic based on your application)
    const storedUserId = localStorage.getItem("userid");
    setLoggedIn(!!storedUserId);
   

    const StaticURL = window.location.origin;
    // alert(window.location.origin);
  }, []);



  const logout = (e) => {
    e.preventDefault();
    localStorage.removeItem("userid");
    setLoggedIn(false);
    window.location.href = '/';
  };
  // Collapsible Mobile Submenu Function
  const collapseSubMenu = () => {
    navbarMenu
      .querySelector(".menu-dropdown.active .submenu")
      .removeAttribute("style");
    navbarMenu.querySelector(".menu-dropdown.active").classList.remove("active");
  };

  // Toggle Mobile Submenu Function
  const toggleSubMenu = (e) => {
    if (e.target.hasAttribute("data-toggle") && window.innerWidth <= 992) {
      e.preventDefault();
      const menuDropdown = e.target.parentElement;

      // If Dropdown is Expanded, then Collapse It
      if (menuDropdown.classList.contains("active")) {
        collapseSubMenu();
      } else {
        // Collapse Existing Expanded Dropdown
        if (navbarMenu.querySelector(".menu-dropdown.active")) {
          collapseSubMenu();

        }

        // Expanded the New Dropdown
        menuDropdown.classList.add("active");
        const subMenu = menuDropdown.querySelector(".submenu");
        subMenu.style.maxHeight = subMenu.scrollHeight + "px";

      }
    }
  };

  // Fixed Resize Window Function
  const resizeWindow = () => {
    if (window.innerWidth > 992) {
      if (isNavbarMenuActive) {
        toggleMenu();
      }
      if (navbarMenu.querySelector(".menu-dropdown.active")) {
        collapseSubMenu();
      }
    }
  };

  // Initialize Event Listeners
  // useEffect(() => {
  //   window.addEventListener("resize", resizeWindow);
  //   burgerMenu.addEventListener("click", toggleMenu);
  //   overlayMenu.addEventListener("click", toggleMenu);
  //   navbarMenu.addEventListener("click", toggleSubMenu);

  // Cleanup Event Listeners on Component Unmount
  // return () => {
  //   burgerMenu.removeEventListener("click", toggleMenu);
  //   overlayMenu.removeEventListener("click", toggleMenu);
  //   navbarMenu.removeEventListener("click", toggleSubMenu);
  //   window.removeEventListener("resize", resizeWindow);
  // };
  // }
  // , [isNavbarMenuActive]);
  const handleButtonClick = (id) => {
    localStorage.setItem("selectedMatchId", id);
  };
  return (
    <>
      <header className="header" id="header">
        <section className="wrapper container">
          <div className="burger" id="burger" onClick={toggleMenu}>
            <span className="burger-line"></span>
            <span className="burger-line"></span>
            <span className="burger-line"></span>
          </div>
          <div className="logo">
            <Link to="/">
              <img src={Logo} className="img-fluid" alt="Logo" />

            </Link>
          </div>


          <span className="overlay" onClick={toggleMenu}></span>
          <nav className="navbar" id="navbar">
            <div className="mobilelogo">
              <img src={Logo} className="img-fluid" alt="Logo" />
            </div>
            <ul className="menu" id="menu">
              <li className="menu-item menu-dropdown" onClick={toggleSubMenu}>
                <span className="menu-link" data-toggle="submenu">Live Score<i class="bi bi-caret-down-fill"></i></span>
                <ul className="submenu">
                  <li onClick={toggleMenu} className="submenu-item"><Link to="/LiveScore" className='submenu-link'>Cricket Live Scores</Link></li>
                  {/* <li onClick={toggleMenu} className="submenu-item"><Link to="/Llclivescrore" className='submenu-link'>LLC Live Sore</Link></li> */}
                  {/* <li onClick={toggleMenu} className="submenu-item"><Link to="/Sharjahhundredleague" className='submenu-link'>Sharjah 100</Link></li>
                  <li onClick={toggleMenu} className="submenu-item"><Link to="/footballlivescore" className='submenu-link'>Football Live Scores</Link></li>
                  <li onClick={toggleMenu} className="submenu-item"><Link to="/Kabaddilivescore" className='submenu-link'>Kabaddi Live Score</Link></li>
                  <li onClick={toggleMenu} className="submenu-item"><Link to="/Pkl" className='submenu-link'>PKL 10 2023</Link></li> */}

                </ul>
              </li>
              <li className="menu-item menu-dropdown" onClick={toggleSubMenu}>
                <span className="menu-link" data-toggle="submenu">Schedule<i class="bi bi-caret-down-fill"></i></span>
                <ul className="submenu height_submenu">
                {data && data.map((gameType, index) => (
                      gameType.seriesMatches.map((series, seriesIndex) => (
                  
                  <li key={seriesIndex} className="submenu-item"><a href="/Series"
                      onClick={() => handleButtonClickName(series.seriesAdWrapper && series.seriesAdWrapper.seriesId)}
                      className='submenu-link' >{series.seriesAdWrapper && series.seriesAdWrapper.seriesName}</a></li>
                      
                 
              ))
              ))}
     
                  {/* <li onClick={toggleMenu} className="submenu-item"><Link to="/Series" className='submenu-link'>Cricket Schedule</Link></li> */}
                  {/* <li onClick={toggleMenu} className="submenu-item"><Link to="/LiveScore" className='submenu-link'>Cricket Live Score </Link></li> */}
                  {/* <li onClick={toggleMenu} className="submenu-item"><Link to="/Sharjahhundredleague" className='submenu-link'> LLC Live Score </Link></li> */}
                  {/* <li onClick={toggleMenu} className="submenu-item"><Link to="/Footballschedule" className='submenu-link'> Football Schedule </Link></li> */}
                  {/* <li onClick={toggleMenu} className="submenu-item"><Link to="/Kabaddischedule" className='submenu-link'> Kabaddi schedule </Link></li> */}
                  {/* <li onClick={toggleMenu} className="submenu-item"><Link to="/Pkl" className='submenu-link'> PKL 10 2023 </Link></li> */}

                </ul>
              </li>
              {/* <li className="menu-item menu-dropdown" onClick={toggleSubMenu}>
                <span className="menu-link" data-toggle="submenu">Series<i class="bi bi-caret-down-fill"></i></span>
                <ul className="submenu">
                  <li onClick={toggleMenu} className="submenu-item"><Link to="/Legendsleaguecricket" className='submenu-link'> LLC Live Score </Link></li>
                </ul>
              </li> */}
                  {/* <li onClick={toggleMenu} className="submenu-item"><Link to="/Legendsleaguecricket" className='submenu-link'> LLC Live Score </Link></li> */}
              <li onClick={toggleMenu} className="menu-item"><Link to="/Series" className='menu-link'>Series</Link></li>
              <li onClick={toggleMenu} className="menu-item"><Link to="/Archive" className='menu-link'>Archive</Link></li>
              <li className="menu-item menu-dropdown" onClick={toggleSubMenu}>
                <span className="menu-link" data-toggle="submenu">
                  Ranking<i class="bi bi-caret-down-fill"></i>
                </span>
                <ul className="submenu">
                  <li className="submenu-item">
                    <Link to="/MenRanking" className="submenu-link">
                      Men Ranking
                    </Link>
                  </li>
                  <li className="submenu-item">
                    <a href="/WomanRanking" className="submenu-link">
                      Women Ranking
                    </a>
                  </li>
                </ul>
              </li>
              <li onClick={toggleMenu} className="menu-item"><Link to="/News" className='menu-link'>News</Link></li>
              <li onClick={toggleMenu} className="menu-item"><Link to="/Featurevideo" className='menu-link'>Video</Link></li>
              {/* <li onClick={toggleMenu} className="menu-item"><Link to="/" className='menu-link'>Ranking</Link></li> */}
              <li onClick={toggleMenu} className="menu-item"><Link to="/Predictiontips" className='menu-link'>Fantasy</Link></li>



              <div className="fixed-bottom">
              <h3>Follow Us</h3>
              <div className="d-flex socialmedia">
                <div className="socialicon">
                  <Link to="">
                  <i class="bi bi-facebook"></i>
                  </Link>
                </div>
                <div className="socialicon">
                <Link to="https://x.com/sportspad_?t=Uz81j2rJRVkkL2bUjRaQNQ&s=08">
                  <i class="bi bi-twitter-x"></i>
                  </Link>
                </div>
                <div className="socialicon">
                <Link to="https://www.instagram.com/sportspad.in?igsh=MWwxc2E1cDJ2ZWUxdg==">
                  <i class="bi bi-instagram"></i>
                  </Link>
                </div>
                <div className="socialicon">
                <Link to="https://t.me/sportspadin">
                  <i class="bi bi-telegram"></i>
                  </Link>
                </div>
                <div className="socialicon">
                  <i class="bi bi-youtube"></i>
                </div>
                <div className="socialicon">
                  <i class="bi bi-whatsapp"></i>
                </div>
              </div>
            </div>
            </ul>
            
          </nav>
          {/* <div className="d-flex align-items-center mr-2">
         <div className="loginandmenu ">
    
     {isLoggedIn ? (
            <button onClick={logout}>
              <i className="bi bi-box-arrow-right"></i> Logout
            </button>
          ) : (
            <button>
              <Link to="/Login" className='menu-link text-light '>Login</Link>
            </button>
          )}
     
     </div>
     
         </div> */}
        </section>
      </header>
      {/* <header>
    <nav>
    <div className="logo">
        <img src={Logo} className="img-fluid" alt="Logo"/>
      </div>
     
      <input type="checkbox" id="menubrop"/>
      <ul className="NavMenu">
        <li><a to="javascript:void(0)"><label for="droplist1" className="toggle">Live Score</label></a>
          <input type="checkbox" id="droplist1"/>
          <ul>
            <li><a to="#">Cricket Live Scores</a></li>
            <li><a to="#">LLC Live Sore</a></li>
            <li><a to="#">Football Live Scores</a></li>
            <li><a to="#">Kabaddi Live Score</a></li>
            <li><a to="#">PKL 10 2023</a></li> */}
      {/* <li>
              <a to="javascript:void(0)"><label for="droplist2" className="toggle">Service C</label></a>
              <input type="checkbox" id="droplist2"/>
              <ul>
                <li><a to="#">Sub Service 1</a></li>
                <li><a to="#">Sub Service 2</a></li>
                <li><a to="#">Sub Service 3</a></li>
                <li><a to="#">Sub Service 4</a></li>
              </ul>
            </li> */}
      {/* </ul>
        </li>
        <li><a to="#">Schedule</a></li>
        <li><a to="#">Series</a></li>
        <li><a to="#">Archive</a></li>
        <li><a to="#">News</a></li>
        <li><a to="#">Video</a></li>
        <li><a to="#">Ranking</a></li>
        
        <li><a to="#">Predication & Tips</a></li>
        
      </ul>
      <div className="loginandmenu">
     <button>Login</button>
     
     </div>
     <label for="menubrop" className="bartoggle">≡</label>
    </nav>
  </header> */}
      {/* 
     <Navbar expand="lg" className="bg-body-tertiary">
      <Container fluid>
        <Navbar.Brand to="#home">
        <div className="logo">
        <img src={Logo} className="img-fluid" alt="Logo"/>
      </div>
        </Navbar.Brand>

        <div className="loginandtoggle">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <div className="loginandmenu login">
     <button>Login</button>
     
     </div>
        </div>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link to="#home">Live Score</Nav.Link>
            <Nav.Link to="#link">Schedule</Nav.Link>
            <Nav.Link to="#link">Series</Nav.Link>
            <Nav.Link to="#link">Archive</Nav.Link>
            <Nav.Link to="#link">News</Nav.Link>
            <Nav.Link to="#link">Video</Nav.Link>
            <Nav.Link to="#link">Ranking</Nav.Link>
            <Nav.Link to="#link">Predication & Tips</Nav.Link>
            <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item to="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item to="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item to="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item to="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
        <div className="loginandmenu">
     <button>Login</button>
     
     </div>
      </Container>
    </Navbar>
   */}
    </>
  )
}

export default Header;



// https://codepen.io/a7rarpress/pen/BaOqYwb