import './Style.css';
import './Style.scss';
import './assets/font/stylesheet.css';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js'
import Header from './Component/Header/Header';
import Footer from './Component/Footer/Footer';
import Home from './Component/Home'
import Cricketlivescore from './Component/Cricketlivescore'
import Llclivescrore from './Component/Llclivescrore'
import Sharjahhundredleague from './Component/Sharjahhundredleague'
import Icccricketworldcup from './Component/Icccricketworldcup'
import Footballlivescore from './Component/Footballlivescore'
import Kabaddilivescore from './Component/Kabaddilivescore'
import Cricketschedule from './Component/Cricketschedule'
import Footballschedule from './Component/Footballschedule'
import Featurevideo from './Component/Featurevideo'
import Kabaddischedule from './Component/Kabaddischedule'
import Abouts from './Component/Abouts'
import Predictiontips from './Component/Predictiontips'
import News from './Component/News'
import News_Detail from './Component/News_Detail'
import Archive from './Component/Archive'
import Pkl from './Component/Pkl'
import Series from './Component/Series'

import LiveScore from './Component/LiveScore.jsx'
import Legendsleaguecricket from './Component/Legendsleaguecricket'
import MenRanking from './Component/MenRanking.jsx';
import WomanRanking from './Component/WomanRanking.jsx';
import Video_Detail from './Component/Video_Detail.jsx';
import Login from './Component/Login/Login.jsx';
function App() {
  return (
   <>
    <BrowserRouter>
      <Routes>
      <Route  
        path="/*"
        element={
        <div>
       <Header/>
          <Routes>
              <Route path="/" element={<Home/>}/>    
              <Route path="/Icccricketworldcup" element={<Icccricketworldcup/>}/>        
              <Route path="/Login" element={<Login/>}/>      
              <Route path="/LiveScore" element={<LiveScore/>}/>      
              <Route path="/Cricketlivescore" element={<Cricketlivescore/>}/>        
              <Route path="/Llclivescrore" element={<Llclivescrore/>}/>        
              <Route path="/Sharjahhundredleague" element={<Sharjahhundredleague/>}/>        
              <Route path="/Footballlivescore" element={<Footballlivescore/>}/>        
              <Route path="/Kabaddilivescore" element={<Kabaddilivescore/>}/>        
              <Route path="/Cricketschedule" element={<Cricketschedule/>}/>        
              <Route path="/Kabaddischedule" element={<Kabaddischedule/>}/>        
              <Route path="/Archive" element={<Archive/>}/>        
              <Route path="/Featurevideo" element={<Featurevideo/>}/>        
              <Route path="/Series" element={<Series/>}/>        
            
              <Route path="/Video_Detail" element={<Video_Detail />} />     
              <Route path="/Footballschedule" element={<Footballschedule/>}/> 
              <Route path="/MenRanking" element={<MenRanking/>}/>        
              <Route path="/WomanRanking" element={<WomanRanking/>}/>       
              <Route path="/Pkl" element={<Pkl/>}/>        
              <Route path="/News" element={<News/>}/>        
              <Route path="/News_Detail" element={<News_Detail/>}/>        
              <Route path="/Predictiontips" element={<Predictiontips/>}/>        
              <Route path="/Legendsleaguecricket" element={<Legendsleaguecricket/>}/>        
              <Route path="/Abouts" element={<Abouts/>}/>        
          </Routes>
        <Footer/>
        </div>
        }
        />
        {/* <Route path="/Login" element={<Login/>}/>         */}
      </Routes>
    </BrowserRouter>
   </>
  );
}

export default App;
