import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Tabheader from '../Component/Tabheader'
import { Link } from 'react-router-dom';
import flag2 from '../../src/assets/image/flag2.png'
import ball from '../../src/assets/image/cricket-ball.png'
import latestnews from '../../src/assets/image/latestnews.png'
import latestnews2 from '../../src/assets/image/latestnews2.png'
import video1 from '../../src/assets/image/video1.png'
import playername from '../../src/assets/image/Ellipse 2.png'
export default function Footballschedule() {
    return (
        <>
            <Tabheader />
            <section className="cricketlivescore" id="cricketlivescore">
                <div className="cricketscore">
                    <Container>
                        <div className="d-flex border-bottom-custom">
                            <ul className="d-flex matcheslist">
                                <li>Matches</li>
                                <li>Current and Future Fixtures</li>
                                <li>Matches By Day</li>
                                <li>Series Archive</li>
                            </ul>
                        </div>
                        <div className="Sectab">
                            <ul>
                                <li>
                                    <Link to="/Cricketschedule">Cricket</Link>
                                </li>
                                <li className="activetab">
                                    <Link to="/Footballschedule">Football</Link>
                                </li>
                                <li>
                                    <Link to="/Kabaddischedule">Kabaddi</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="d-flex border-bottom-custom">
                            <ul className="d-flex matcheslist">
                                <li>All</li>
                                <li>International</li>
                                <li>T20 League</li>
                                <li>Domestic</li>
                            </ul>
                        </div>


                    </Container>

                </div>
                <div>
                    <Container>
                        <Row>
                            <Col md={8}>
                                <div className="leftside">
                                    <div className="tabsnew">
                                        <ul>
                                            <li className="activetabnew">Live</li>
                                            <li>Upcoming</li>
                                            <li>Result</li>
                                        </ul>
                                    </div>
                                    <div className="footballschedule" id="footballschedule">
                                        <div className="matchfootball">
                                            <h6>TUE, NOV 21 2023</h6>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>CONCACAF Nations League</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>Montserrat vs Barbados</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                                <h3>
                                                                    7:00 PM
                                                                    <span> 01:30 PM GMT</span>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>CONCACAF Nations League</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>Montserrat vs Barbados</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                                <h3>
                                                                    7:00 PM
                                                                    <span> 01:30 PM GMT</span>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                        </div>
                                        <div className="matchfootball">
                                            <h6>TUE, NOV 21 2023</h6>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>CONCACAF Nations League</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>Montserrat vs Barbados</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                                <h3>
                                                                    7:00 PM
                                                                    <span> 01:30 PM GMT</span>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>CONCACAF Nations League</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>Montserrat vs Barbados</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                                <h3>
                                                                    7:00 PM
                                                                    <span> 01:30 PM GMT</span>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                        </div>
                                        <div className="matchfootball">
                                            <h6>TUE, NOV 21 2023</h6>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>CONCACAF Nations League</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>Montserrat vs Barbados</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                                <h3>
                                                                    7:00 PM
                                                                    <span> 01:30 PM GMT</span>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>CONCACAF Nations League</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>Montserrat vs Barbados</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                                <h3>
                                                                    7:00 PM
                                                                    <span> 01:30 PM GMT</span>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                        </div>
                                        <div className="matchfootball">
                                            <h6>TUE, NOV 21 2023</h6>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>CONCACAF Nations League</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>Montserrat vs Barbados</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                                <h3>
                                                                    7:00 PM
                                                                    <span> 01:30 PM GMT</span>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>CONCACAF Nations League</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>Montserrat vs Barbados</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                                <h3>
                                                                    7:00 PM
                                                                    <span> 01:30 PM GMT</span>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                        </div>
                                        <div className="matchfootball">
                                            <h6>TUE, NOV 21 2023</h6>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>CONCACAF Nations League</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>Montserrat vs Barbados</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                                <h3>
                                                                    7:00 PM
                                                                    <span> 01:30 PM GMT</span>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>CONCACAF Nations League</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>Montserrat vs Barbados</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                                <h3>
                                                                    7:00 PM
                                                                    <span> 01:30 PM GMT</span>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                        </div>
                                        <div className="matchfootball">
                                            <h6>TUE, NOV 21 2023</h6>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>CONCACAF Nations League</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>Montserrat vs Barbados</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                                <h3>
                                                                    7:00 PM
                                                                    <span> 01:30 PM GMT</span>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>CONCACAF Nations League</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>Montserrat vs Barbados</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                                <h3>
                                                                    7:00 PM
                                                                    <span> 01:30 PM GMT</span>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                        </div>
                                        <div className="matchfootball">
                                            <h6>TUE, NOV 21 2023</h6>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>CONCACAF Nations League</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>Montserrat vs Barbados</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                                <h3>
                                                                    7:00 PM
                                                                    <span> 01:30 PM GMT</span>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>CONCACAF Nations League</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>Montserrat vs Barbados</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                                <h3>
                                                                    7:00 PM
                                                                    <span> 01:30 PM GMT</span>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                        </div>
                                        <div className="matchfootball">
                                            <h6>TUE, NOV 21 2023</h6>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>CONCACAF Nations League</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>Montserrat vs Barbados</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                                <h3>
                                                                    7:00 PM
                                                                    <span> 01:30 PM GMT</span>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>CONCACAF Nations League</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>Montserrat vs Barbados</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                                <h3>
                                                                    7:00 PM
                                                                    <span> 01:30 PM GMT</span>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                        </div>
                                        <div className="matchfootball">
                                            <h6>TUE, NOV 21 2023</h6>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>CONCACAF Nations League</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>Montserrat vs Barbados</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                                <h3>
                                                                    7:00 PM
                                                                    <span> 01:30 PM GMT</span>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>CONCACAF Nations League</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>Montserrat vs Barbados</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                                <h3>
                                                                    7:00 PM
                                                                    <span> 01:30 PM GMT</span>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="newssection">
                                    <div className="lastestnews">
                                        <h3>Latest News</h3>
                                    </div>
                                    <div className="latestnewssec">
                                        <div className="latestnewssection">
                                            <img src={latestnews} alt="latestnews" />
                                        </div>
                                        <Link to="/">ICC ODI World Cup 2023: India vs Australia Stats Review - Check out</Link>
                                    </div>
                                    <div className="newssection2">
                                        <div className='d-flex align-items-center'>
                                            <div className="imagenews">
                                                <img src={latestnews2} alt="latestnews2" />
                                            </div>
                                            <div className='linkdiv'>
                                                <span>Updated - 02 Nov 2023 06:12 PM</span>
                                                <Link to="/">
                                                    ODI World Cup 2023: England vs Australia:
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="newssection2">
                                        <div className='d-flex align-items-center'>
                                            <div className="imagenews">
                                                <img src={latestnews2} alt="latestnews2" />
                                            </div>
                                            <div className='linkdiv'>
                                                <span>Updated - 02 Nov 2023 06:12 PM</span>
                                                <Link to="/">
                                                    ODI World Cup 2023: England vs Australia:
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="newssection2">
                                        <div className='d-flex align-items-center'>
                                            <div className="imagenews">
                                                <img src={latestnews2} alt="latestnews2" />
                                            </div>
                                            <div className='linkdiv'>
                                                <span>Updated - 02 Nov 2023 06:12 PM</span>
                                                <Link to="/">
                                                    ODI World Cup 2023: England vs Australia:
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="newssection">
                                    <div className="lastestnews">
                                        <h3>Latest Video</h3>
                                    </div>
                                    <div className="latestnewssec">
                                        <div className="latestnewssection videoplaybtn">
                                            <img src={video1} alt="video1" />
                                        </div>
                                        <Link to="/">ICC ODI World Cup 2023: India vs Australia Stats Review - Check out</Link>
                                        <div className="d-flex justify-content-between">
                                            <p className="mb-0">19 Nov 2023</p>
                                            <p className="mb-0"><strong><i class="bi bi-eye-fill"></i>View</strong></p>

                                        </div>
                                    </div>
                                    <div className="latestnewssec">
                                        <div className="latestnewssection videoplaybtn">
                                            <img src={video1} alt="video1" />
                                        </div>
                                        <Link to="/">ICC ODI World Cup 2023: India vs Australia Stats Review - Check out</Link>
                                        <div className="d-flex justify-content-between">
                                            <p className="mb-0">19 Nov 2023</p>
                                            <p className="mb-0"><strong><i class="bi bi-eye-fill"></i>View</strong></p>

                                        </div>
                                    </div>
                                    <div className="latestnewssec">
                                        <div className="latestnewssection videoplaybtn">
                                            <img src={video1} alt="video1" />
                                        </div>
                                        <Link to="/">ICC ODI World Cup 2023: India vs Australia Stats Review - Check out</Link>
                                        <div className="d-flex justify-content-between">
                                            <p className="mb-0">19 Nov 2023</p>
                                            <p className="mb-0"><strong><i class="bi bi-eye-fill"></i>View</strong></p>

                                        </div>
                                    </div>

                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </>
    )
}
