import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Tabheader from "./Tabheader";
import {Link} from 'react-router-dom'
import Pklimage from "../../src/assets/image/pkl.jpg";
import playerimagevirat from "../../src/assets/image/playerimagevirat.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import flag from "../../src/assets/image/flag2.png";
import flagnew from "../../src/assets/image/flagnew.png";
import playername from "../../src/assets/image/Ellipse 2.png";
import Rightnewssection from "./Rightnewssection";
import axios from "axios";
import YouTube from "react-youtube";
import dateFormat from "dateformat";
export default function Legendsleaguecricket() {
  const [usersvideo, setUservideo] = useState([]);
  const [UserNews, setUserNews] = useState([]);
  useEffect(() => {
    playGame();
    playGame2();
  }, []);

  const playGame = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/video-link`
      );
      const result = response.data.data;
      setUservideo(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getVideoId = (url) => {
    const match = url.match(
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    return match && match[1];
  };

  const getThumbnailUrl = (url) => {
    const videoId = getVideoId(url);
    return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : "";
  };
  const playGame2 = async () => {
    try {
      const response = await axios(
        // (`${process.env.REACT_APP_API_URL}/home.php`)
        `${process.env.REACT_APP_API_URL}/news-list`,
        {}
      );

      // console.warn(response.data.dtat);
      const res = response.data.data;
      setUserNews(res);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getNewsId = (url) => {
    const match = url.match(
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    return match && match[1];
  };
  const getThumbnailUrlnews = (url) => {
    const videoId = getNewsId(url);
    return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : "";
  };
  const state = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 2,
      },
      1200: {
        items: 2,
      },
    },
    navText: [
      '<i class="bi bi-arrow-left"></i>',
      '<i class="bi bi-arrow-right"></i>',
    ],
  };

  return (
    <>
      <Tabheader />
      <section id="legendleaguecricket">
        <div>
          <Container>
            <Row>
              <Col md={12}>
                <div className="pkl">
                  <ul className="pl-0 d-flex">
                    <li className="Home">Home</li>
                    <li>
                      <i class="bi bi-chevron-double-right"></i>
                    </li>
                    &nbsp;
                    <li> Pro Kabaddi Season 10</li>
                  </ul>
                  <div className="card box-card p-2">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center ">
                        <div className="imageforleague">
                          <img src={Pklimage} alt="pkl" />
                        </div>
                        <div className="detailsleague">
                          <h4 className="">Pro Kabaddi Season 10</h4>
                          <h6 className="">02 Dec 2023 - 21 Feb 2024</h6>
                          <div className="d-flex matchesbutton">
                            <button className="activebutton">
                              132 Matches
                            </button>
                            <button>48 Matches</button>
                            <button>6 Team</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={8}>
                <div className="legendscricket">
                  <div className="Rightsidecricket">
                    <h4 className="mb-0">Matchs</h4>
                  </div>
                  <div className="d-flex matchesbutton justify-content-between">
                    <button className="activebutton">Overview</button>
                    <button>Matches</button>
                    <button>Video</button>
                    <button>News</button>
                    <button>Point Table</button>
                    <button>Stats</button>
                    <button>Squads</button>
                    <button>Fantasy Tips</button>
                  </div>
                  <OwlCarousel
                    className="owl-theme livescoreowl"
                    {...state}
                    items={2}
                    autoplay={false}
                    margin={5}
                    nav={false}
                    dots={false}
                  >
                    <div class="item">
                      <div className="cardlive">
                        <h4>
                         <span style={{Color:"green"}}> Upcoming{" "}</span>
                          <span>
                            Gujarat Giants vs India Capitals, Eliminator{" "}
                          </span>
                        </h4>
                        <p className="">
                          India vs New Zealand, 1st Semi-Final (1st v 4th)
                        </p>
                        <div className="matchone">
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex matchgame">
                              <div className="counteryflag mr-2">
                                <img src={flag} className="img-fluid" />
                              </div>
                              <p>Bangladesh</p>
                            </div>
                            <div className="over">
                              <p>
                                (50 ov)<strong>169/9</strong>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="matchone">
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex matchgame">
                              <div className="counteryflag mr-2">
                                <img src={flag} className="img-fluid" />
                              </div>
                              <p>Bangladesh</p>
                            </div>
                            <div className="over">
                              <p>
                                (50 ov)<strong>169/9</strong>
                              </p>
                            </div>
                          </div>
                        </div>
                        <p className="mb-0 text-primary">
                          Bangladesh Won By 80 Run
                        </p>

                        <button className="scoreboard">Match Info</button>
                      </div>
                    </div>
                    <div class="item">
                      <div className="cardlive">
                        <h4>
                          Live{" "}
                          <span>
                            Gujarat Giants vs India Capitals, Eliminator{" "}
                          </span>
                        </h4>
                        <p className="">
                          India vs New Zealand, 1st Semi-Final (1st v 4th)
                        </p>
                        <div className="matchone">
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex matchgame">
                              <div className="counteryflag mr-2">
                                <img src={flag} className="img-fluid" />
                              </div>
                              <p>Bangladesh</p>
                            </div>
                            <div className="over">
                              <p>
                                (50 ov)<strong>169/9</strong>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="matchone">
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex matchgame">
                              <div className="counteryflag mr-2">
                                <img src={flag} className="img-fluid" />
                              </div>
                              <p>Bangladesh</p>
                            </div>
                            <div className="over">
                              <p>
                                (50 ov)<strong>169/9</strong>
                              </p>
                            </div>
                          </div>
                        </div>
                        <p className="mb-0 text-primary">
                          Bangladesh Won By 80 Run
                        </p>
                        <div className="playername">
                          <div className="d-flex align-items-center">
                            <div className="d-flex ">
                              <img src={playername} className="img-fluid" />
                            </div>
                            <div className="playerofthematch">
                              <p className="mb-0 text-white">Player of the Match</p>
                              <h5 className="text-white">Rajeshwari Gayakwad</h5>
                            </div>
                          </div>
                          {/* <button className="scoreboard">
                                                            Scoreboard
                                                        </button> */}
                        </div>
                      </div>
                    </div>
                  </OwlCarousel>
                  <div className="bg-white mt-2">
                       <div className="d-flex justify-content-between statssection">
                        <div className="stats">
                            STATS
                            </div>
                            <Link>View All</Link>
                        </div>
                        <hr/>
                        <Container fluid>

                        <Row className="statssection">
                            <Col lg={3} md={6} xs={6} sm={6} >
                                <div className="teamname">
                                    <p>India</p>
                                    <div className="imgplayer">
                                        <img src={playerimagevirat} alt="player Image" />
                                    </div>
                                    <h5>Virat kohli</h5>
                                    <h3>711</h3>
                                    <small>Most Run</small>
                                </div>

                            </Col>
                            <Col lg={3} md={6} xs={6} sm={6} >
                            <div className="teamname">
                                    <p>India</p>
                                    <div className="imgplayer">
                                        <img src={playerimagevirat} alt="player Image" />
                                    </div>
                                    <h5>Virat kohli</h5>
                                    <h3>711</h3>
                                    <small>Most Run</small>
                                </div>

                            </Col>
                            <Col lg={3} md={6} xs={6} sm={6} >
                            <div className="teamname">
                                    <p>India</p>
                                    <div className="imgplayer">
                                        <img src={playerimagevirat} alt="player Image" />
                                    </div>
                                    <h5>Virat kohli</h5>
                                    <h3>711</h3>
                                    <small>Most Run</small>
                                </div>

                            </Col>
                            <Col lg={3} md={6} xs={6} sm={6} >
                            <div className="teamname">
                                    <p>India</p>
                                    <div className="imgplayer">
                                        <img src={playerimagevirat} alt="player Image" />
                                    </div>
                                    <h5>Virat kohli</h5>
                                    <h3>711</h3>
                                    <small>Most Run</small>
                                </div>

                            </Col>
                        </Row>
                        </Container>
                  </div>
                  <div className="bg-white mt-2">
                       <div className="d-flex justify-content-between statssection">
                        <div className="stats">
                            TEAM
                            </div>
                            <Link>View All</Link>
                        </div>
                        <hr/>
                        <Container fluid>

                        <Row className="teamflag">
                            <Col lg={3} md={6} xs={6} sm={6}>
                            <div className="teamname">
                                   
                                   <div className="imgplayer">
                                       <img src={flagnew} alt="player Image" />
                                   </div>
                                   <h5>Afghanistan</h5>
                                  
                               </div>

                            </Col>
                            <Col lg={3} md={6} xs={6} sm={6}>
                            <div className="teamname">
                                   
                                   <div className="imgplayer">
                                       <img src={flagnew} alt="player Image" />
                                   </div>
                                   <h5>Afghanistan</h5>
                                  
                               </div>
                            </Col>
                            <Col lg={3} md={6} xs={6} sm={6}>
                            <div className="teamname">
                                   
                                   <div className="imgplayer">
                                       <img src={flagnew} alt="player Image" />
                                   </div>
                                   <h5>Afghanistan</h5>
                                  
                               </div>

                            </Col>
                            <Col lg={3} md={6} xs={6} sm={6}>
                            <div className="teamname">
                                   
                                    <div className="imgplayer">
                                        <img src={flagnew} alt="player Image" />
                                    </div>
                                    <h5>Afghanistan</h5>
                                   
                                </div>

                            </Col>
                        </Row>
                        </Container>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <Rightnewssection />
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
}
