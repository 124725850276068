
import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import axios from "axios";
import dateFormat from 'dateformat';
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Latestvideo() {
  const [usersvideo, setUservideo] = useState([]);
  const [carouselKey, setCarouselKey] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    playGame();
    playGamewww();
  }, []);

  useEffect(() => {
    // Reinitialize the carousel when the data changes
    setCarouselKey((prevKey) => prevKey + 1);
  }, [usersvideo]);

  const playGame = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/video-link`);
      const result = response.data.data;
      setUservideo(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const playGamewww = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URLWWW}/video-link`);
      const result = response.data.data;
      setUservideo(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getVideoId = (url) => {
    const match = url.match(/(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/);
    return match && match[1];
  };

  const getThumbnailUrl = (url) => {
    const videoId = getVideoId(url);
    return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : '';
  };

  const playVideo = async (link) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/video-single?video_id=${link}`);
      const videoDetails = response.data.data;

      // Perform any video playback logic here
      // console.log('Playing video:', videoDetails);

      // Optionally, you can navigate to the Video_Detail page
      navigate(`/Video_Detail`);
      localStorage.setItem("playvideo", link);
    } catch (error) {
      console.error('Error fetching video data:', error);
    }
  };
  const playVideoWWW = async (link) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URLWWW}/video-single?video_id=${link}`);
      const videoDetails = response.data.data;

      // Perform any video playback logic here
      // console.log('Playing video:', videoDetails);

      // Optionally, you can navigate to the Video_Detail page
      navigate(`/Video_Detail`);
      localStorage.setItem("playvideo", link);
    } catch (error) {
      console.error('Error fetching video data:', error);
    }
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="Latestvideo" id="Latestvideo">
      <Container>
        <div className="headingpage">
        <div className="headingnews"> <Link to='/Featurevideo' className='text-light'>Latest Video</Link></div>
          <Link to='/Video_Detail' className="allvideo d-small-none">View All Video</Link>
        </div>
        <Slider className="slick-theme livescoreowl" {...sliderSettings}>
          {usersvideo.map((set, setIndex) => (
            <div key={setIndex} className="item" >
              <div className="latestvideobox">
                <div className="thumbnailimage ">
                  <div className="latestnewssection videoplaybtn">
                    <img  onClick={() => { playVideo(set.link); playVideoWWW(set.link); }} src={getThumbnailUrl(set.link)} alt={`Thumbnail for`} />
                  </div>
                </div>
                <div className="d-flex justify-content-evenly eventname">
                  <p className="mb-0"><i class="fa fa-calculator" aria-hidden="true"></i>  {dateFormat(set.updated_at, "mmmm dS, yyyy")}</p>
                </div>
                <div className="linkvideo position-realtive" onClick={() => { playVideo(set.link); playVideoWWW(set.link); }}>
                  <Link className='text-dark' >{set.title}</Link>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <Link to="/Video_Detail" className="allvideo mobileresponsive">View All Video</Link>
      </Container>
    </div>
  );
}
