import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col } from 'react-bootstrap'
import Tabheader from '../Component/Tabheader'
import { Link } from 'react-router-dom';
import flag2 from '../../src/assets/image/flag2.png'
import ball from '../../src/assets/image/cricket-ball.png'
import latestnews from '../../src/assets/image/latestnews.png'
import latestnews2 from '../../src/assets/image/latestnews2.png'
import video1 from '../../src/assets/image/video1.png'
import playername from '../../src/assets/image/Ellipse 2.png'
import dateFormat from 'dateformat';
import Rightnewssection from "./Rightnewssection";
export default function Kabaddischedule() {
    const [users, setUsers] = useState([]);
    const [user, setUsers1] = useState([]);
    const [usersrank, setUsersrank] = useState([]);
  
    const imagess =
      "https://static-files.cricket-australia.pulselive.com/flag/54/11.png";
    useEffect(() => {
      // playGame();
      playGameMatchlist();
    }, [1000]);
  
    const handleButtonClick = (id) => {
     
      localStorage.setItem("selectedMatchId", id);
  
    };
  
    const playGameMatchlist = async () => {
      try {
        const response = await axios.get(
          // `https://apiv2.cricket.com.au/web/matchticker/fixtures`,
          `https://apiv2.cricket.com.au/web/fixtures/yearfilter?isCompleted=false&startDateTime=2023-01-01T00%3A00%3A00.000Z&limit=35&isInningInclude=true&jsconfig=eccn%3Atrue&format=json`,
          {}
        );
        const result = response.data.fixtures;
        const ids = result.map((fixture) => fixture.id);
  
        // Storing the array of ids in local storage
        localStorage.setItem("match", JSON.stringify(ids));
        console.warn(result);
        // localStorage.setItem("playvideo", id);
        setUsersrank(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    return (
        <>
            <Tabheader />
            <section className="cricketlivescore" id="cricketlivescore">
                <div className="cricketscore">
                    <Container>
                        <div className="d-flex border-bottom-custom">
                            <ul className="d-flex matcheslist">
                                <li>Matches</li>
                                <li>Current and Future Fixtures</li>
                                <li>Matches By Day</li>
                                <li>Series Archive</li>
                            </ul>
                        </div>
                        <div className="Sectab">
                            <ul>
                                <li>
                                <Link to="/Cricketschedule">Cricket</Link>
                                </li>
                                <li>
                                <Link to="/Footballschedule">Football</Link>
                                </li>
                                <li className="activetab">
                                <Link to="/Kabaddischedule">Kabaddi</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="d-flex border-bottom-custom">
                            <ul className="d-flex matcheslist">
                                <li>All</li>
                                <li>International</li>
                                <li>T20 League</li>
                                <li>Domestic</li>
                            </ul>
                        </div>


                    </Container>

                </div>
                <div>
                    <Container>
                        <Row>
                            <Col md={8}>
                                <div className="leftside">
                                    <div className="tabsnew">
                                        <ul>
                                            <li className="activetabnew">Live</li>
                                            <li>Upcoming</li>
                                            <li>Result</li>
                                        </ul>
                                    </div>
                                    <div className="footballschedule" id="footballschedule">
                                        
                                        {usersrank &&
              usersrank.length > 0 &&
              usersrank.map((set, setIndex) => (
                <Link to="/LiveScore" className="text-dark" onClick={() => handleButtonClick(set.id)} >
                                        <div className="matchfootball">
                                         
                                            <h6>{dateFormat(
                                          set.startDateTime,
                                          "d- mmm - yyyy"
                                        )}</h6>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>{set.name}</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>{set.competition.name}</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>{set.homeTeam.shortName}</p>
                                                                        <div className="flagimage">
                                                                           <img
                            src={`https://static-files.cricket-australia.pulselive.com/flag/54/${set.homeTeam.id}.png`}
                            className="img-fluid  "
                            style={{
                              border: "0.25px solid #adada8",
                              height: "50px",
                              width: "50px",
                            }} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                    <p className="" style={{}}>
                        {set.awayTeam.shortName}
                        </p>
                                                                        
                                                                        <div className="flagimage">
                                                                        <img
                      src={`https://static-files.cricket-australia.pulselive.com/flag/54/${set.awayTeam.id}.png`}

                            style={{
                              border: "0.25px solid #adada8",
                              height: "50px",
                              width: "50px",
                            }}
                            className="img-fluid  "
                            alt={"hfhgfh"}
                          />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                            <h3>
  {new Date(set.startDateTime).toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: true })}
  <span>{new Date(set.startDateTime).toLocaleTimeString('en-US', { timeZone: 'GMT', hour: 'numeric', minute: 'numeric', hour12: false })} GMT</span>
</h3>

                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                           
                                        </div>
                                      </Link>
              ))}
                                        {/* <div className="matchfootball">
                                            <h6>TUE, NOV 21 2023</h6>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>CONCACAF Nations League</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>Montserrat vs Barbados</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                                <h3>
                                                                    7:00 PM
                                                                    <span> 01:30 PM GMT</span>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>CONCACAF Nations League</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>Montserrat vs Barbados</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                                <h3>
                                                                    7:00 PM
                                                                    <span> 01:30 PM GMT</span>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                        </div>
                                        <div className="matchfootball">
                                            <h6>TUE, NOV 21 2023</h6>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>CONCACAF Nations League</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>Montserrat vs Barbados</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                                <h3>
                                                                    7:00 PM
                                                                    <span> 01:30 PM GMT</span>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>CONCACAF Nations League</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>Montserrat vs Barbados</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                                <h3>
                                                                    7:00 PM
                                                                    <span> 01:30 PM GMT</span>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                        </div>
                                        <div className="matchfootball">
                                            <h6>TUE, NOV 21 2023</h6>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>CONCACAF Nations League</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>Montserrat vs Barbados</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                                <h3>
                                                                    7:00 PM
                                                                    <span> 01:30 PM GMT</span>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>CONCACAF Nations League</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>Montserrat vs Barbados</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                                <h3>
                                                                    7:00 PM
                                                                    <span> 01:30 PM GMT</span>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                        </div>
                                        <div className="matchfootball">
                                            <h6>TUE, NOV 21 2023</h6>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>CONCACAF Nations League</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>Montserrat vs Barbados</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                                <h3>
                                                                    7:00 PM
                                                                    <span> 01:30 PM GMT</span>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>CONCACAF Nations League</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>Montserrat vs Barbados</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                                <h3>
                                                                    7:00 PM
                                                                    <span> 01:30 PM GMT</span>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                        </div>
                                        <div className="matchfootball">
                                            <h6>TUE, NOV 21 2023</h6>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>CONCACAF Nations League</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>Montserrat vs Barbados</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                                <h3>
                                                                    7:00 PM
                                                                    <span> 01:30 PM GMT</span>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>CONCACAF Nations League</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>Montserrat vs Barbados</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                                <h3>
                                                                    7:00 PM
                                                                    <span> 01:30 PM GMT</span>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                        </div>
                                        <div className="matchfootball">
                                            <h6>TUE, NOV 21 2023</h6>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>CONCACAF Nations League</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>Montserrat vs Barbados</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                                <h3>
                                                                    7:00 PM
                                                                    <span> 01:30 PM GMT</span>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                            <div className="flex-wrap match2football">
                                                <Container fluid className="h-100">
                                                    <Row className="h-100">
                                                        <Col md={3}>
                                                            <div className="footballleaguename">
                                                                <h3>CONCACAF Nations League</h3>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="border_new">
                                                            <div className="teamvsteam">
                                                                <p>Montserrat vs Barbados</p>
                                                                <div className="d-flex justify-content-between align-items-center teammatch">
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="vs">
                                                                        <p>VS</p>
                                                                    </div>
                                                                    <div className="teammatchname">
                                                                        <p>MSR</p>
                                                                        <div className="flagimage">
                                                                            <img src={flag2} alt="flag2" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={3}>
                                                            <div className='timeanddate'>
                                                                <h3>
                                                                    7:00 PM
                                                                    <span> 01:30 PM GMT</span>
                                                                </h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Container>


                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                            <Rightnewssection />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </>
    )
}
