import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import leaderimage from "../../src/assets/image/leadername.png";
import Photo_Missing from "../../src/assets/image/Photo-Missing.png";
import OwlCarousel from "react-owl-carousel";
import $ from "jquery";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function Cwcleaders() {
  const [ranking, setRanking] = useState([]);
  const [baseUrl, setBaseUrl] = useState("");


  // https://sportspad.com//Admin/api/v1
  useEffect(() => {
    Ranking_List();
    Ranking_Listwww()
  
  }, []);

  
  const Ranking_List = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/ranking-list`
      );
      const result = response.data.data;
      setRanking(result);

      // Set baseUrl based on the response
      setBaseUrl("https://sportspad.com/Admin/public/admin/uploads/top_player");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const Ranking_Listwww = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URLWWW}/ranking-list`
      );
      const result = response.data.data;
      setRanking(result);

      // Set baseUrl based on the response
      setBaseUrl("https://www.sportspad.com/Admin/public/admin/uploads/top_player");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const state = {
  //     responsive: {
  //         0: {
  //             items: 2,
  //         },
  //         450: {
  //             items: 2,
  //         },
  //         600: {
  //             items: 3,
  //         },
  //         1000: {
  //             items: 6,
  //         },
  //     },
  //     navText: [
  //         '<i class="bi bi-arrow-right"></i>',
  //         '<i class="bi bi-arrow-left"></i>'

  //     ]
  // }
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,

    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="leadermargin">
      <Container className="ledersnew">
        <div className="headingnews">Top Player</div>

        {/* <OwlCarousel className='owl-theme livescoreowl' {...state} items={3} autoplay={false} loop margin={5} nav dots={false} > */}
        <Slider className="slick-theme livescoreowl" {...sliderSettings}>
          {ranking
            .filter((player) => player.rank === "1")
            .map((player, index) => (
              <div key={index}>
                <div className="playernameleader">
                  <div className="d-flex align-items-center justify-content-between leaderimage">
                    <div className="imageplayer">
                      <img
                        src={`${baseUrl}/${player.player_image}`}
                        alt="playerimage"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = Photo_Missing;
                          e.target.style.backgroundColor = "white";
                        }}
                        style={{ height: "100%", width: "100%" }}
                      />
                    </div>

                    <div className="Nameplayer">
                      <p>{player.player_name}</p>
                      
                      {/* <p>{player.ranking}</p> */}
                      <h5>{player.team.charAt(0).toUpperCase() +
                                  player.team.slice(1) ===
                                "Bating"
                                  ? "Batter"
                                  : player.team.charAt(0).toUpperCase() +
                                      player.team.slice(1) ===
                                    "Bowling"
                                  ? "Bowler"
                                  : player.team.charAt(0).toUpperCase() +
                                      player.team.slice(1) ===
                                    "Allrounder"
                                  ? "All Rounder"
                                  : null}</h5>
                    </div>
                  </div>
                  <div className="d-flex align-items-center runs">
                    <h3> {player.points}</h3>
                    <h6>Rating</h6>
                  </div>
                </div>
              </div>
            ))}

        
        </Slider>
      </Container>
    </div>
  );
}


