import React, { useState, useEffect } from "react";
// import { Tabs } from "antd";
import { Container, Row, Col } from "react-bootstrap";
import Icccricketworldcup from "./Icccricketworldcup";
import { Link } from "react-router-dom";
import leftimage from "./../assets/image/image1.png";
import centerimage from "./../assets/image/image2.png";
import LatestNews from "../Component/LatestNews";
import Latestvideo from "../Component/Latestvideo";
import Fancytips from "../Component/Fancytips";
import Othervideo from "../Component/Othervideo";
import Topplayer from "../Component/Topplayer";
import Cwcleaders from "../Component/Cwcleaders";
import banner from "../../src/assets/image/banner.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// Replace the import statement based on the new library
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";

import dateFormat from "dateformat";
export default function Home() {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [user, setUsers1] = useState([]);
  const [Userslength, setUserslength] = useState([]);
  const [set, setSet] = useState([]);
  const [usersvideo, setUservideo] = useState([]);
  const [usersvideoss, setUservideosss] = useState([]);
  const [baseUrlnews, setBaseUrl] = useState("");
  const sportId = 4;
  const [games, setGames] = useState([]);

  useEffect(() => {
    playGame4();
    playVideo();
    playVideowww();
  }, []);
  useEffect(() => {
    playGame();
    playGamewww();
    // playGame1();
    playGame2();
    playGame4www();
    playGame2www();
    
    // loaduser();
  }, []);
  useEffect(() => {
    playGameMatchlist();
    
    // loaduser();
  }, [1000]);
  const playGame4 = async () => {
    // console.log("Inside playGame4");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/match-list?sport_id=4`,
        {}
      );

      const result = response.data.data.matches;

      const matchname = response.data.data.matches.map((series) => series.name);
      setSet(matchname);

      console.warn(result);

      setGames(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const playGame4www = async () => {
    // console.log("Inside playGame4");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URLWWW}/match-list?sport_id=4`,
        {}
      );

      const result = response.data.data.matches;

      const matchname = response.data.data.matches.map((series) => series.name);
      setSet(matchname);

      console.warn(result);

      setGames(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const playGame = async () => {
    try {
      const response = await axios.get(
        `https://sportspad.com/Admin/api/v1/video-link`
        
      );
      const result = response.data.data;

      setUservideosss(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const playGamewww = async () => {
    try {
      const response = await axios.get(
        `https://www.sportspad.com/Admin/api/v1/video-link`
        
      );
      const result = response.data.data;

      setUservideosss(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getVideoId = (url) => {
    const match = url.match(
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    return match && match[1];
  };

  const getThumbnailUrl = (url) => {
    const videoId = getVideoId(url);
    return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : "";
  };

  const playGameMatchlist = async () => {
    try {
   


     
      const response = await axios.get(
        `https://apiv2.cricket.com.au/web/matchticker/fixtures?jsconfig=eccn%3Atrue&format=json`,
        {}
      );
      
      const result = response.data.fixtures.length;
 
      // localStorage.setItem("playvideo", id);
      setUserslength(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const playVideo = async (link) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/video-single?video_id=${link}`
      );
      const videoDetails = response.data.data;

      // Perform any video playback logic here
      // console.log('Playing video:', videoDetails);

      // Optionally, you can navigate to the Video_Detail page
      // navigate(`/Video_Detail`);
      localStorage.setItem("playvideo", link);
    } catch (error) {
      console.error("Error fetching video data:", error);
    }
  };
  const playVideowww = async (link) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URLWWW}/video-single?video_id=${link}`
      );
      const videoDetails = response.data.data;

      // Perform any video playback logic here
      // console.log('Playing video:', videoDetails);

      // Optionally, you can navigate to the Video_Detail page
      // navigate(`/Video_Detail`);
      localStorage.setItem("playvideo", link);
    } catch (error) {
      console.error("Error fetching video data:", error);
    }
  };
  const playGame2 = async () => {
    try {
      const response = await axios(
        // (`${process.env.REACT_APP_API_URL}/home.php`)
        `${process.env.REACT_APP_API_URL}/news-list`,
        {}
      );

      // console.warn(response.data.dtat);
      const res = response.data.data;
      setUservideo(res);
      setBaseUrl("https://sportspad.com/Admin/public/admin/uploads/news");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const playGame2www = async () => {
    try {
      const response = await axios(
        // (`${process.env.REACT_APP_API_URL}/home.php`)
        `${process.env.REACT_APP_API_URLWWW}/news-list`,
        {}
      );

      // console.warn(response.data.dtat);
      const res = response.data.data;
      setUservideo(res);
      setBaseUrl("https://www.sportspad.com/Admin/public/admin/uploads/news");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getNewsId = (url) => {
    const match = url.match(
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    return match && match[1];
  };
  const getThumbnailUrlnews = (url) => {
    const videoId = getNewsId(url);
    return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : "";
  };
  const onChange = (key) => {
    // console.log(key);
  };
  const items = Array.from(set).map((l_sname, index) => ({
    key: `${index + 1}`,
    label: index === 0 ? <p>ALL SPORTS</p> : <p>{l_sname}</p>,
    children: index === 0 ? <Icccricketworldcup /> : <Icccricketworldcup />,
  }));
  const handleClick = (slug, name) => {
    window.location.href = `/News_Detail?id=${slug}`;
  };
  return (
    <>
      <div className="">
    {/* {Userslength > 0 &&( */}
        <div className="tabsheader">
          {/* <Tabs defaultIndex={0} onSelect={(index) => onChange(index)}>
            <div className="tabsnew">
              <TabList className="container ">
                {items.map((item, index) => (
                  <Tab key={index}>{item.label}</Tab>
                ))}
              </TabList>
            </div>
            {items.map((item, index) => (
              <TabPanel key={index}>{item.children}</TabPanel>
            ))}
          </Tabs> */}
           <Icccricketworldcup /> 
        </div>
    {/* )} */}

        <Container>
          <div className="margin50">
            <Row>
              <Col md={4}>
                <div className="height450">
                  <div className="headingnews">Trending News</div>

                  <div className="row">
                    {/* {usersvideo.slice(0, 2).map((set, setIndex) => ( */}
                      {usersvideo
                        .filter(
                          (set) =>
                            set.is_tranding &&
                            set.is_tranding &&
                            set.is_tranding === 1
                        )
                        .slice(0, 2)
                        .map((set, setIndex) => (
                      <div
                        className="col-md-12"
                        onClick={() => {
                          handleClick(set.slug);
                        }}
                      >
                        <div className="leftside height_video_home">
                          <div className="imgleft">
                            <img
                              // src={set.image}
                              src={`${baseUrlnews}/${set.image}`}
                              alt={`Thumbnail  `}
                              className="img-fluid"
                            />
                          </div>
                          <div className="linkdiv">
                            <p className="mb-0">
                              {" "}
                            
                              <i class="fa fa-calendar" aria-hidden="true"></i>
                              <span className="mx-1">
                                {dateFormat(set.updated_at, "mmm dS, yyyy")}
                              </span>
                            </p>

                            <h1
                              className="title"
                              onClick={() => {
                                handleClick(set.slug);
                              }}
                            >
                              {set.title}
                            </h1>
                          </div>
                        </div>
                      </div>
                    ))}
                    {/* {usersvideo.slice(0, 2).map((set, setIndex) => (
                      <div className="col-md-12">
                        <div className="leftside">
                          <div className="imgleft">
                            <img
                              src={getThumbnailUrlnews(set.video_link)}
                              alt={`Thumbnail for ${set.title}`}
                              className="img-fluid"
                            />
                          </div>
                          <div className="linkdiv">
                            <p className="mb-0">
                              {" "}
                              Updated On -{" "}
                              {dateFormat(set.updated_at, "mmm dS, yyyy")}{" "}
                            </p>

                            <h4 className="title">
                              <Link to="/News" className="text-dark">
                                {set.title}
                              </Link>
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))} */}
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="border-new height450">
                  <div className="headingnews">Fantasy</div>

                  {/* {usersvideo.slice(0, 6).map((set, setIndex) => ( */}
                  {usersvideo
                    .filter(
                      (set) =>
                        set.games &&
                        set.games[0] &&
                        set.games[0].name === "Fantasy"
                    ).slice(0, 6).map((set, setIndex) => (
                      <div
                        className="d-flex align-items-start"
                        onClick={() => {
                          handleClick(set.slug);
                        }}
                      >
                        <div className="imgcenter">
                          {/* <img src={centerimage} className='img-fluid' /> */}
                          <img
                            // src={getThumbnailUrlnews(set.video_link)}
                            src={`${baseUrlnews}/${set.image}`}
                            alt={`Thumbnail`}
                            className="img-fluid"
                          />
                        </div>
                        <div className="linkforgame">
                          <p className="mb-0">
                            {" "}
                          
                            <i class="fa fa-calendar" aria-hidden="true"></i>
                            <span className="mx-1">
                              {dateFormat(set.updated_at, "mmm dS, yyyy")}
                            </span>
                          </p>

                          <h1
                            className="title mb-0"
                            onClick={() => {
                              handleClick(set.slug);
                            }}
                          >
                            {set.title}
                          </h1>
                        </div>
                      </div>
                    ))}
                </div>
              </Col>
              <Col md={4}>
                <div className="latestnews height450">
                  <div className="headingnews">Latest News</div>
                  {usersvideo.slice(0, 6).map((set, setIndex) => (
                    <div
                      className="d-flex latestnewss"
                      onClick={() => {
                        handleClick(set.slug);
                      }}
                    >
                      <h4>{setIndex + 1}.</h4>
                      <h4 className="title mb-0">
                        <h1
                          onClick={() => {
                            handleClick(set.slug);
                          }}
                          className="text-dark mb-0"
                        >
                          {set.title}
                        </h1>

                        {/* <p className="descrpt">{set.description}</p> */}
                        <span className="d-flex">
                        <i class="fa fa-calendar" aria-hidden="true"></i>
                          <span className="mx-1">
                            {dateFormat(set.updated_at, "mmm dS, yyyy")}
                          </span>
                        </span>
                      </h4>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <LatestNews />
        <Latestvideo />
        <Fancytips />
        <Othervideo />
        <Topplayer />
        <div className="banner my-2">
          <img src={banner} alt="banner.png" className="img-fluid" />
        </div>
        <Cwcleaders />
      </div>
    </>
  );
}
