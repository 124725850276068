import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Footer.scss";
import footer from "../../assets/image/logo.png";
import axios from "axios";

import { Link } from "react-router-dom";
export default function Footer() {
  const [games, setGames] = useState([]);
  const [date, setDate] = useState();
  const [set, setSet] = useState([]);
  const [usersrank, setUsersrank] = useState([]);
  const sportId = 4;
  useEffect(() => {
    playGame4();
  }, []);

  const playGame4 = async () => {
    try {
      const response = await axios.GET(
        `${process.env.REACT_APP_API_URL}/match-list?sport_id=${sportId}`,
        {}
      );

      alert(response);
      const result = response.data.data.matches;
      const matchname = response.data.data.matches.map((series) => series.name);
      setSet(matchname);
      console.warn(matchname);
      console.warn(result);
      setGames(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const options = {
        method: 'GET',
        url: 'https://cricbuzz-cricket.p.rapidapi.com/matches/v1/recent',

        headers: {
          'X-RapidAPI-Key': '8a09779cc5msh8d06b8be0a9d59bp19c20fjsn64a51b7d7f64',
    'X-RapidAPI-Host': 'cricbuzz-cricket.p.rapidapi.com'
        }
      };

      try {
        const response = await axios.request(options);
        const result = response.data.typeMatches
     
        console.warn(result);
        
        setData(result)

      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);
  const uniqueNames = Array.from(
    new Set(usersrank.map((set) => set.competition.name, set.id))
  );
  uniqueNames.reverse();
  const handleButtonClick = (id) => {
    localStorage.setItem("selectedMatchId", id);
    // alert(id)
  };
  const handleButtonClickName = (seriesNam) => {
    localStorage.setItem("selectedSeriesName", seriesNam);

  };
  return (
    <>
      <section className="Footer">
        <Container fluid>
          <Row>
            <Col md={4}>
              <div className="footerlogo">
                <Link to="/">
                  <img src={footer} alt="footer logo" />
                </Link>
              </div>
              <p className="footerpara">
                Welcome to SportsPad, your ultimate destination for everything
                cricket! Dive into the exciting world of the gentleman's game
                with us. From live match scores and expert analyses to player
                profiles and cricketing trivia, we've got it all covered. Stay
                updated with the latest news, highlights, and in-depth articles.
                Join our vibrant community of cricket enthusiasts. Let's share
                the passion for the sport together. Play on!
              </p>
            </Col>
            <Col md={4}>
              <div className="quicklinks">
                <div className="Links">
                  <h3>Quick Links</h3>
                </div>
                <ul id="myList" className="quicklinksnew">
              
                {data && data.map((gameType, index) => (
                      gameType.seriesMatches.map((series, seriesIndex) => (
                    <a
                      href="/Series"
                      className="text-light"
                      onClick={() => handleButtonClickName(series.seriesAdWrapper && series.seriesAdWrapper.seriesId)}
                    >
                      <li  >{series.seriesAdWrapper && series.seriesAdWrapper.seriesName}</li>
                    </a>
                 ))
                 ))}

                  <li>
                 
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={4}>
              <div className="quicklinks">
                <div className="Links">
                  <h3>Info Links</h3>
                </div>
                <ul>
                  <li>Contact Us</li>
                  <li>
                    <Link className="Links" to="/Abouts">
                      About Us
                    </Link>
                  </li>
                  <li>Advertise With Us</li>
                  <li>Write With Us</li>
                  <li>Disclaimer</li>
                  <li>DMCA</li>
                  <li>Privacy Policy</li>
                  <li>Terms of Use</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="footer-bottom">
        <Container fluid>
          <div className="d-flex justify-content-between flexwrap">
            <div className="follow d-flex">
              <p className="text-dark  ">Follow Us :</p>
              <div className="d-flex socialmedia">
                <div className="socialicon">
                  <Link to="">
                    <i class="bi bi-facebook"></i>
                  </Link>
                </div>
                <div className="socialicon">
                  <Link to="https://x.com/sportspad_?t=Uz81j2rJRVkkL2bUjRaQNQ&s=08">
                    <i class="bi bi-twitter-x"></i>
                  </Link>
                </div>
                <div className="socialicon">
                  <Link to="https://www.instagram.com/sportspad.in?igsh=MWwxc2E1cDJ2ZWUxdg==">
                    <i class="bi bi-instagram"></i>
                  </Link>
                </div>
                <div className="socialicon">
                  <Link to="https://t.me/sportspadin">
                    <i class="bi bi-telegram"></i>
                  </Link>
                </div>
                <div className="socialicon">
                  <i class="bi bi-youtube"></i>
                </div>
                <div className="socialicon">
                  <i class="bi bi-whatsapp"></i>
                </div>
              </div>
            </div>
            <div className="copyright">
              <p>© 2023 SportsPad. All rights reserved</p>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
