import React,{useEffect} from 'react'
import image from '../assets/image/image.png';
import founderimage from '../assets/image/founderimage.PNG';
import {Link} from 'react-router-dom'
export default function Abouts() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
   <>
    <section id="about">
    <div
  className="section-title bg-aboutus big-title">
  <div className="container">
    <div className="row">
      <div className="col-md-8">
        <h1>About Us</h1>
      </div>
      {/* <div className="col-md-4">
        <div className="breadcrumbs">
          <ul>
            <li className='activehome'>
              <a href="/">Home</a>
            </li>
            <li><i class="bi bi-chevron-double-right"></i></li>
            <li>Inner Page</li>
          </ul>
        </div>
      </div> */}
    </div>
  </div>
</div>
<section className="content-info">
  {/* White Section */}
  <div className="white-section paddings">
    <div className="container">
      {/* <div className="aboutustitle position-relative">
      <h2 className="text-center mb-3">About Us</h2>
      </div> */}
      <div className="row">
        <div className="col-lg-6">
           <div className="ceoimage">
                <img src={founderimage} alt="image" />
           </div>
        </div>
        <div className="col-lg-6 contentforabout">
          <h4 className="subtitle">
          <span> Ambassador</span>
          Anurag Dwivedi
          </h4>
          <p>
          Welcome to Sports Pad, where passion for sports meets cutting-edge coverage. Founded by Anurag Dwivedi, our visionary founder, and led by Rahul Faujdar, serving as the CEO and Co-founder, Sports Pad stands as a premier sports news website.
          </p>
          <p>
          Dive into a world of in-depth articles and real-time updates on cricket matches, complete with videos, live scorecards, team rankings, fantasy predictions, and much more.
          </p>
          <p>
          As we push the boundaries of sports journalism, we're excited to introduce our mobile app, Sports Pad. This app is designed to bring the excitement and insight directly to your fingertips.
          </p>
          <p> At Sports Pad, we are driven by a passion for sports and a dedication to keeping you informed and entertained. Join us in celebrating the spirit of sports through our engaging platform. Stay tuned for the latest buzz, insights, and excitement in the world of sports, right here at Sports Pad.</p>
           <div className="d-flex">
            <h3>Follow:</h3>
           <div className="d-flex socialmedia align-items-center">
                {/* <div className="socialicon">
                  <Link to="">
                    <i class="bi bi-facebook"></i>
                  </Link>
                </div> */}
                {/* <div className="socialicon">
                  <Link to="https://x.com/sportspad_?t=Uz81j2rJRVkkL2bUjRaQNQ&s=08">
                    <i class="bi bi-twitter-x"></i>
                  </Link>
                </div> */}
                <div className="socialicon">
                  <Link to="https://www.instagram.com/anuragxcricket/?igsh=dmVyM2VxcnczeTFl">
                    <i class="bi bi-instagram"></i>
                  </Link>
                </div>
                <div className="socialicon">
                  <Link to="https://t.me/AnuragxCricket">
                    <i class="bi bi-telegram"></i>
                  </Link>
                </div>
                <div className="socialicon">
                  <Link to="https://www.youtube.com/@AnuragDwivedi">
                  <i class="bi bi-youtube"></i>
                  </Link>
                </div>
                {/* <div className="socialicon">
                  <Link>
                  <i class="bi bi-whatsapp"></i>
                  </Link>
                </div> */}
              </div>
        </div>
           </div>
      </div>
      <div className="row margin_top_60">
        
        <div className="col-lg-6 contentforabout">
          <h4 className="subtitle">
          <span>CEO  And Co-Founder</span>
          
          Rahul Faujdar   
          </h4>
          <p>Embark on a journey with us as we unfold the stories behind the games, not just reporting the scores but delving into the narratives that make each match memorable. Our team of experienced journalists and sports enthusiasts collaborates to deliver a diverse range of perspectives, ensuring you get a comprehensive view of the sporting world.</p>
          <p>The Sports Pad experience extends beyond news consumption – it's an interactive platform where your voice matters. Engage in discussions, polls, and exclusive interviews with athletes. Our goal is not just to inform but to create a space where the passion for sports is celebrated.</p>
          <p>In the ever-evolving realm of sports, we are committed to staying ahead of the curve. Our dedicated team is constantly exploring innovative ways to enhance your experience, ensuring that Sports Pad remains your go-to source for everything sports-related.</p>
          <div className="d-flex">
            <h3>Follow:</h3>
           <div className="d-flex socialmedia align-items-center">
                {/* <div className="socialicon">
                  <Link to="">
                    <i class="bi bi-facebook"></i>
                  </Link>
                </div> */}
                {/* <div className="socialicon">
                  <Link to="https://x.com/sportspad_?t=Uz81j2rJRVkkL2bUjRaQNQ&s=08">
                    <i class="bi bi-twitter-x"></i>
                  </Link>
                </div> */}
                <div className="socialicon">
                  <Link to="https://www.instagram.com/rahulfaujdar18/?igsh=MTlmZmFtbzd4OWlwMw%3D%3D">
                    <i class="bi bi-instagram"></i>
                  </Link>
                </div>
                {/* <div className="socialicon">
                  <Link to="https://t.me/sportspadin">
                    <i class="bi bi-telegram"></i>
                  </Link>
                </div> */}
                {/* <div className="socialicon">
                  <Link to="">
                  <i class="bi bi-youtube"></i>
                  </Link>
                </div> */}
                {/* <div className="socialicon">
                  <Link>
                  <i class="bi bi-whatsapp"></i>
                  </Link>
                </div> */}
              </div>
        </div>
        </div>
        <div className="col-lg-6">
           <div className="ceoimage">
                <img src={image} alt="image" />
           </div>
        </div>
      </div>
  
     
  
    </div>
  </div>
  {/* End White Section */}
  
 
 
     
  
</section>


    </section>
       
       </>
  )
}
