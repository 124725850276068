import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import image3 from "../assets/image/image3.png";
import news1 from "../assets/image/news1.png";
import india from "../assets/image/india.png";
import dateFormat from "dateformat";
import OwlCarousel from "react-owl-carousel";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
export default function LatestNews() {
  const [usersvideo, setUservideo] = useState([]);
  const navigate = useNavigate();
  const [baseUrlnews, setBaseUrl] = useState("");
  // const baseUrlnews =
  // "https://sportspad.com/Admin/public/admin/uploads/news";
  const [UserNews, setUserNews] = useState([]);
  useEffect(() => {
    playGame();
    playGame2();
    playGame2www();
  }, []);

  const playGame = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/video-link`
      );
      const result = response.data.data;
      setUservideo(result);
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getVideoId = (url) => {
    const match = url.match(
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    return match && match[1];
  };

  const getThumbnailUrl = (url) => {
    const videoId = getVideoId(url);
    return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : "";
  };
  const playGame2 = async () => {
    try {
      const response = await axios(
        // (`${process.env.REACT_APP_API_URL}/home.php`)
        `${process.env.REACT_APP_API_URL}/news-list`,
        {}
      );

      // console.warn(response.data.dtat);
      const res = response.data.data;
      setUserNews(res);
      setBaseUrl("https://sportspad.com/Admin/public/admin/uploads/news");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const playGame2www = async () => {
    try {
      const response = await axios(
        // (`${process.env.REACT_APP_API_URL}/home.php`)
        `${process.env.REACT_APP_API_URLWWW}/news-list`,
        {}
      );

      // console.warn(response.data.dtat);
      const res = response.data.data;
      setUserNews(res);
      setBaseUrl("https://www.sportspad.com/Admin/public/admin/uploads/news");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getNewsId = (url) => {
    const match = url.match(
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    return match && match[1];
  };
  const getThumbnailUrlnews = (url) => {
    const videoId = getNewsId(url);
    return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : "";
  };
 
  const handleClick = (slug, name) => {
  
    window.location.href = `/News_Detail?id=${slug}`;
  };
  const state = {
    responsive: {
      0: {
        items: 1,
      },
      450: {
        items: 1,
      },
      600: {
        items: 1,
      },
      991: {
        items: 3,
      },
      1200: {
        items: 4,
      },
    },
    navText: [
      '<i class="bi bi-arrow-left"></i>',
      '<i class="bi bi-arrow-right"></i>',
    ],
  };
 
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,

    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <section className="news" id="news">
        <Container>
          <div className="newsmargin">
            <section className="utf_featured_post_area pt-4 no-padding">
              <div className="container">
                <div className="row">
                  {UserNews.slice(0, 1).map((set, setIndex) => (
                  
                    <div className="col-lg-7 col-md-12 pad-r"  onClick={() => {handleClick(set.slug);}}>
                      <div
                        id="utf_featured_slider"
                        className="utf_featured_slider"
                      >
                        <div className="newsleftsside">
                        <img  
                                  // src={set.image}
                                  src={`${baseUrlnews}/${set.image}`}
                                  alt={`Thumbnail`}
                                />
                          <div className="utf_featured_post">
                            <div className="utf_post_content">
                              {" "}
                              <a className="utf_post_cat" href="#">
                                Sports
                              </a>
                              <h1 className="utf_post_title title-extra-large"onClick={() => {handleClick(set.slug);}}>
                                {" "}
                              {set.title}
                              </h1>
                             
                              <span className="utf_post_date">
                              <i class="fa fa-calendar" aria-hidden="true"></i>
                                {dateFormat(set.updated_at, "mmm dS, yyyy")}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="col-lg-5 col-md-12 ">
                    <div className="row newssections">
                      <div className="col-md-12 pad-r">
                        {UserNews.slice(1,2).map((set, setIndex) => (
                          <div className="utf_post_overaly_style contentTop hot-post-top clearfix" onClick={() => {handleClick(set.slug);}}>
                            <div className="utf_post_thumb">
                              {" "}
                              <Link to="/News_Detail">
                                <img
                                  // src={set.image}
                                  src={`${baseUrlnews}/${set.image}`}
                                  alt={`Thumbnail`}
                                />
                              </Link>{" "}
                            </div>
                            <div className="utf_post_content">
                              {" "}
                              {/* <a className="utf_post_cat" href="#">
                                Sports
                              </a> */}
                              <h1 className="utf_post_title title-large"  onClick={() => {handleClick(set.slug);}}>
                                {" "}
                              {set.title}
                              </h1>
                              {/* <span className="utf_post_author">
                                <i className="fa fa-user" />{" "}
                                <a href="#">Rohit Kumar</a>
                              </span> */}
                              <span className="utf_post_date">
                              <i class="fa fa-calendar" aria-hidden="true"></i>
                                {dateFormat(set.updated_at, "mmm dS, yy")}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>

                      {UserNews.slice(3, 5).map((set, setIndex) => (
                        
                        <div className="col-md-6 pad-r" onClick={() => {handleClick(set.slug);}}>
                          <div className="utf_post_overaly_style contentTop utf_hot_post_bottom clearfix">
                            <div className="utf_post_thumb">
                              {" "}
                              <Link to="/News_Detail">
                                <img
                                  // src={set.image}
                                  src={`${baseUrlnews}/${set.image}`}
                                  alt={`Thumbnail`}
                                />
                              </Link>{" "}
                            </div>
                            <div className="utf_post_content">
                              {" "}
                              <a className="utf_post_cat" href="#">
                                Sports
                              </a>
                              <h1 className="utf_post_title title-medium" onClick={() => {handleClick(set.slug);}}>
                                {" "}
                              {set.title}
                              </h1>
                              <div className="utf_post_meta">
                                {" "}
                                {/* <span className="utf_post_author">
                                  <i className="fa fa-user" />{" "}
                                  <a href="#">Rohit Kumar</a>
                                </span>{" "} */}
                                <span className="utf_post_date">
                                <i class="fa fa-calendar" aria-hidden="true"></i>
                                {dateFormat(set.updated_at, "mmm dS, yy")}
                              </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Featured Post Area End */}
            {/* Cricket News  start*/}
            {UserNews.some((set) => set.games && set.games[0] && set.games[0].name === "Cricket") && (
            <section className="utf_latest_new_area pb-bottom-20">
              <div className="container">
                <div className="utf_latest_news block color-red">
                <div className="headingnews">Cricket News</div>

                  {/* <h3 className="utf_block_title">
                    <span>Cricket News</span>
                  </h3> */}
                  <div
                    id="utf_latest_news_slide"
                    className="utf_latest_news_slide owl-loaded"
                  >
                     <Slider className="slick-theme livescoreowl" {...sliderSettings}>
                     {UserNews.filter((set) => set.games && set.games[0] && set.games[0].name === "Cricket").map((set, setIndex) => (
                        <div className="latestsectionfornews" onClick={() => {handleClick(set.slug);}}>
                          <div className="utf_list_post">
                            <div className="clearfix">
                              <div className="utf_post_block_style clearfix">
                                <div className="utf_post_thumb">
                                  {" "}
                                  <Link href="/News_Detail">
                                    <img
                                      src={`${baseUrlnews}/${set.image}`}
                                      style={{ height: "196px" }}
                                      alt={`Thumbnail `}
                                    />
                                  </Link>{" "}
                                </div>
                                <div className="padding_15">
                                  <a className="utf_post_cat" href="#">
                                    {set.games[0].name}
                                  </a>
                                  <div className="utf_post_content">
                                    {/* <h2 className="utf_post_title title-medium">
                                      {" "}
                                      <Link href="/News_Detail">{set.title}</Link>{" "}
                                    </h2> */}
                                     <h1 className="title text-dark" onClick={() => {handleClick(set.slug);}}>
                              {/* <Link to="/News_Detail" className="text-dark"> */}
                                {set.title}
                          
                            </h1>
                                    <div className="utf_post_meta">
                                      {" "}
                                      {/* <span className="utf_post_author">
                                        <i className="fa fa-user" />{" "}
                                        <a href="#">Rohit Kumar</a>
                                      </span>{" "} */}
                                      <span className="utf_post_date">
                                      <i class="fa fa-calendar" aria-hidden="true"></i>
                                        {dateFormat(
                                          set.updated_at,
                                          "mmm dS, yyyy"
                                        )}
                                      </span>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </section>
            )}
             {UserNews.some((set) => set.games && set.games[0] && set.games[0].name === "Football") && (
            <section className="utf_latest_new_area pb-bottom-20">
              <div className="container">
                <div className="utf_latest_news block color-red">
                <div className="headingnews">Football News</div>


                  {/* <h3 className="utf_block_title">
                    <span>Cricket News</span>
                  </h3> */}
                  <div
                    id="utf_latest_news_slide"
                    className="utf_latest_news_slide owl-loaded"
                  >
                     <Slider className="slick-theme livescoreowl" {...sliderSettings}>
                     {UserNews.filter((set) => set.games && set.games[0] && set.games[0].name === "Football").map((set, setIndex) => (
                        <div className="latestsectionfornews" onClick={() => {handleClick(set.slug);}}>
                          <div className="utf_list_post">
                            <div className="clearfix">
                              <div className="utf_post_block_style clearfix">
                                <div className="utf_post_thumb">
                                  {" "}
                                  <Link href="/News_Detail">
                                    <img
                                      src={`${baseUrlnews}/${set.image}`}
                                      style={{ height: "196px" }}
                                      alt={`Thumbnail `}
                                    />
                                  </Link>{" "}
                                </div>
                                <div className="padding_15">
                                  <a className="utf_post_cat" href="#">
                                    {set.games[0].name}
                                  </a>
                                  <div className="utf_post_content">
                                    {/* <h2 className="utf_post_title title-medium">
                                      {" "}
                                      <Link href="/News_Detail">{set.title}</Link>{" "}
                                    </h2> */}
                                     <h1 className="title text-dark" onClick={() => {handleClick(set.slug);}}>
                              {/* <Link to="/News_Detail" className="text-dark"> */}
                                {set.title}
                          
                            </h1>
                                    <div className="utf_post_meta">
                                      {" "}
                                      {/* <span className="utf_post_author">
                                        <i className="fa fa-user" />{" "}
                                        <a href="#">Rohit Kumar</a>
                                      </span>{" "} */}
                                      <span className="utf_post_date">
                                      <i class="fa fa-calendar" aria-hidden="true"></i>
                                        {dateFormat(
                                          set.updated_at,
                                          "mmm dS, yyyy"
                                        )}
                                      </span>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </section>
            )}
              {UserNews.some((set) => set.games && set.games[0] && set.games[0].name === "Kabaddi") && (
            <section className="utf_latest_new_area pb-bottom-20">
              <div className="container">
                <div className="utf_latest_news block color-red">
                <div className="headingnews">Kabaddi News</div>


                  {/* <h3 className="utf_block_title">
                    <span>Cricket News</span>
                  </h3> */}
                  <div
                    id="utf_latest_news_slide"
                    className="utf_latest_news_slide owl-loaded"
                  >
                     <Slider className="slick-theme livescoreowl" {...sliderSettings}>
                     {UserNews.filter((set) => set.games && set.games[0] && set.games[0].name === "Kabaddi").map((set, setIndex) => (
                        <div className="latestsectionfornews" onClick={() => {handleClick(set.slug);}}>
                          <div className="utf_list_post">
                            <div className="clearfix">
                              <div className="utf_post_block_style clearfix">
                                <div className="utf_post_thumb">
                                  {" "}
                                  <Link href="/News_Detail">
                                    <img
                                      src={`${baseUrlnews}/${set.image}`}
                                      style={{ height: "196px" }}
                                      alt={`Thumbnail `}
                                    />
                                  </Link>{" "}
                                </div>
                                <div className="padding_15">
                                  <a className="utf_post_cat" href="#">
                                    {set.games[0].name}
                                  </a>
                                  <div className="utf_post_content">
                                    {/* <h2 className="utf_post_title title-medium">
                                      {" "}
                                      <Link href="/News_Detail">{set.title}</Link>{" "}
                                    </h2> */}
                                     <h1 className="title text-dark" onClick={() => {handleClick(set.slug);}}>
                              {/* <Link to="/News_Detail" className="text-dark"> */}
                                {set.title}
                          
                            </h1>
                                    <div className="utf_post_meta">
                                      {" "}
                                      {/* <span className="utf_post_author">
                                        <i className="fa fa-user" />{" "}
                                        <a href="#">Rohit Kumar</a>
                                      </span>{" "} */}
                                      <span className="utf_post_date">
                                      <i class="fa fa-calendar" aria-hidden="true"></i>
                                        {dateFormat(
                                          set.updated_at,
                                          "mmm dS, yyyy"
                                        )}
                                      </span>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </section>
            )}
              {UserNews.some((set) => set.games && set.games[0] && set.games[0].name === "Tennis") && (
            <section className="utf_latest_new_area pb-bottom-20">
              <div className="container">
                <div className="utf_latest_news block color-red">
                <div className="headingnews">Kabaddi News</div>

                  {/* <h3 className="utf_block_title">
                    <span>Cricket News</span>
                  </h3> */}
                  <div
                    id="utf_latest_news_slide"
                    className="utf_latest_news_slide owl-loaded"
                  >
                     <Slider className="slick-theme livescoreowl" {...sliderSettings}>
                     {UserNews.filter((set) => set.games && set.games[0] && set.games[0].name === "Tennis").map((set, setIndex) => (
                        <div className="latestsectionfornews" onClick={() => {handleClick(set.slug);}}>
                          <div className="utf_list_post">
                            <div className="clearfix">
                              <div className="utf_post_block_style clearfix">
                                <div className="utf_post_thumb">
                                  {" "}
                                  <Link href="/News_Detail">
                                    <img
                                      src={`${baseUrlnews}/${set.image}`}
                                      style={{ height: "196px" }}
                                      alt={`Thumbnail `}
                                    />
                                  </Link>{" "}
                                </div>
                                <div className="padding_15">
                                  <a className="utf_post_cat" href="#">
                                    {set.games[0].name}
                                  </a>
                                  <div className="utf_post_content">
                                    {/* <h2 className="utf_post_title title-medium">
                                      {" "}
                                      <Link href="/News_Detail">{set.title}</Link>{" "}
                                    </h2> */}
                                     <h1 className="title text-dark" onClick={() => {handleClick(set.slug);}}>
                              {/* <Link to="/News_Detail" className="text-dark"> */}
                                {set.title}
                          
                            </h1>
                                    <div className="utf_post_meta">
                                      {" "}
                                      {/* <span className="utf_post_author">
                                        <i className="fa fa-user" />{" "}
                                        <a href="#">Rohit Kumar</a>
                                      </span>{" "} */}
                                      <span className="utf_post_date">
                                      <i class="fa fa-calendar" aria-hidden="true"></i>
                                        {dateFormat(
                                          set.updated_at,
                                          "mmm dS, yyyy"
                                        )}
                                      </span>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </section>
            )}
              {UserNews.some((set) => set.games && set.games[0] && set.games[0].name === "Soccer") && (
            <section className="utf_latest_new_area pb-bottom-20">
              <div className="container">
                <div className="utf_latest_news block color-red">
                <div className="headingnews">Soccer News</div>

                  {/* <h3 className="utf_block_title">
                    <span>Cricket News</span>
                  </h3> */}
                  <div
                    id="utf_latest_news_slide"
                    className="utf_latest_news_slide owl-loaded"
                  >
                     <Slider className="slick-theme livescoreowl" {...sliderSettings}>
                     {UserNews.filter((set) => set.games && set.games[0] && set.games[0].name === "Soccer").map((set, setIndex) => (
                        <div className="latestsectionfornews" onClick={() => {handleClick(set.slug);}}>
                          <div className="utf_list_post">
                            <div className="clearfix">
                              <div className="utf_post_block_style clearfix">
                                <div className="utf_post_thumb">
                                  {" "}
                                  <Link href="/News_Detail">
                                    <img
                                      src={`${baseUrlnews}/${set.image}`}
                                      style={{ height: "196px" }}
                                      alt={`Thumbnail `}
                                    />
                                  </Link>{" "}
                                </div>
                                <div className="padding_15">
                                  <a className="utf_post_cat" href="#">
                                    {set.games[0].name}
                                  </a>
                                  <div className="utf_post_content">
                                    {/* <h2 className="utf_post_title title-medium">
                                      {" "}
                                      <Link href="/News_Detail">{set.title}</Link>{" "}
                                    </h2> */}
                                     <h1 className="title text-dark" onClick={() => {handleClick(set.slug);}}>
                              {/* <Link to="/News_Detail" className="text-dark"> */}
                                {set.title}
                          
                            </h1>
                                    <div className="utf_post_meta">
                                      {" "}
                                      {/* <span className="utf_post_author">
                                        <i className="fa fa-user" />{" "}
                                        <a href="#">Rohit Kumar</a>
                                      </span>{" "} */}
                                      <span className="utf_post_date">
                                      <i class="fa fa-calendar" aria-hidden="true"></i>
                                        {dateFormat(
                                          set.updated_at,
                                          "mmm dS, yyyy"
                                        )}
                                      </span>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </section>
            )}
              {UserNews.some((set) => set.games && set.games[0] && set.games[0].name === "Fantasy") && (
            <section className="utf_latest_new_area pb-bottom-20">
              <div className="container">
                <div className="utf_latest_news block color-red">
                <div className="headingnews">Other News</div>

                  {/* <h3 className="utf_block_title">
                    <span>Cricket News</span>
                  </h3> */}
                  <div
                    id="utf_latest_news_slide"
                    className="utf_latest_news_slide owl-loaded"
                  >
                     <Slider className="slick-theme livescoreowl" {...sliderSettings}>
                     {UserNews.filter((set) => set.games && set.games[0] && set.games[0].name === "Fantasy").map((set, setIndex) => (
                        <div className="latestsectionfornews" onClick={() => {handleClick(set.slug);}}>
                          <div className="utf_list_post">
                            <div className="clearfix">
                              <div className="utf_post_block_style clearfix">
                                <div className="utf_post_thumb">
                                  {" "}
                                  <Link href="/News_Detail">
                                    <img
                                      src={`${baseUrlnews}/${set.image}`}
                                      style={{ height: "196px" }}
                                      alt={`Thumbnail `}
                                    />
                                  </Link>{" "}
                                </div>
                                <div className="padding_15">
                                  <a className="utf_post_cat" href="#">
                                    {set.games[0].name}
                                  </a>
                                  <div className="utf_post_content">
                                    {/* <h2 className="utf_post_title title-medium">
                                      {" "}
                                      <Link href="/News_Detail">{set.title}</Link>{" "}
                                    </h2> */}
                                     <h1 className="title text-dark" >
                              {/* <Link to="/News_Detail" className="text-dark"> */}
                                {set.title}
                          
                            </h1>
                                    <div className="utf_post_meta">
                                      {" "}
                                      {/* <span className="utf_post_author">
                                        <i className="fa fa-user" />{" "}
                                        <a href="#">Rohit Kumar</a>
                                      </span>{" "} */}
                                      <span className="utf_post_date">
                                      <i class="fa fa-calendar" aria-hidden="true"></i>
                                        {dateFormat(
                                          set.updated_at,
                                          "mmm dS, yyyy"
                                        )}
                                      </span>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </section>
            )}
              {UserNews.some((set) => set.games && set.games[0] && set.games[0].name === "Other") && (
            <section className="utf_latest_new_area pb-bottom-20">
              <div className="container">
                <div className="utf_latest_news block color-red">
                <div className="headingnews">Other News</div>

                  {/* <h3 className="utf_block_title">
                    <span>Cricket News</span>
                  </h3> */}
                  <div
                    id="utf_latest_news_slide"
                    className="utf_latest_news_slide owl-loaded"
                  >
                     <Slider className="slick-theme livescoreowl" {...sliderSettings}>
                     {UserNews.filter((set) => set.games && set.games[0] && set.games[0].name === "Other").map((set, setIndex) => (
                        <div className="latestsectionfornews" onClick={() => {handleClick(set.slug);}}>
                          <div className="utf_list_post">
                            <div className="clearfix">
                              <div className="utf_post_block_style clearfix">
                                <div className="utf_post_thumb">
                                  {" "}
                                  <Link href="/News_Detail">
                                    <img
                                      src={`${baseUrlnews}/${set.image}`}
                                      style={{ height: "196px" }}
                                      alt={`Thumbnail `}
                                    />
                                  </Link>{" "}
                                </div>
                                <div className="padding_15">
                                  <a className="utf_post_cat" href="#">
                                    {set.games[0].name}
                                  </a>
                                  <div className="utf_post_content">
                                    {/* <h2 className="utf_post_title title-medium">
                                      {" "}
                                      <Link href="/News_Detail">{set.title}</Link>{" "}
                                    </h2> */}
                                     <h1 className="title text-dark" >
                              {/* <Link to="/News_Detail" className="text-dark"> */}
                                {set.title}
                          
                            </h1>
                                    <div className="utf_post_meta">
                                      {" "}
                                      {/* <span className="utf_post_author">
                                        <i className="fa fa-user" />{" "}
                                        <a href="#">Rohit Kumar</a>
                                      </span>{" "} */}
                                      <span className="utf_post_date">
                                      <i class="fa fa-calendar" aria-hidden="true"></i>
                                        {dateFormat(
                                          set.updated_at,
                                          "mmm dS, yyyy"
                                        )}
                                      </span>{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </section>
            )}
   
            
           
          </div>
        </Container>
      </section>
    </>
  );
}
