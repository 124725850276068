import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import latestnews from "../../src/assets/image/latestnews.png";
import latestnews2 from "../../src/assets/image/latestnews2.png";
import video1 from "../../src/assets/image/video1.png";
import axios from "axios";
import dateFormat from "dateformat";
import { useNavigate } from "react-router-dom";
export default function Rightnewssection() {
  const [usersvideo, setUservideo] = useState([]);
  const [UserNews, setUserNews] = useState([]);
  const [baseUrlnews, setBaseUrl] = useState("");
  // const baseUrlnews =
  // "https://sportspad.com/Admin/public/admin/uploads/news";
  const navigate = useNavigate();
  useEffect(() => {
    playGame();
    playGame2();
    playNews();
    playNewswww()
    playGame2www();
    playGamewww();
  }, []);

  const playGame = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/video-link`
      );
      const result = response.data.data;
      setUservideo(result);
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const playGamewww = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URLWWW}/video-link`
      );
      const result = response.data.data;
      setUservideo(result);
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getVideoId = (url) => {
    const match = url.match(
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    return match && match[1];
  };

  const getThumbnailUrl = (url) => {
    const videoId = getVideoId(url);
    return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : "";
  };
  const playGame2 = async () => {
    try {
      const response = await axios(
        // (`${process.env.REACT_APP_API_URL}/home.php`)
        `${process.env.REACT_APP_API_URL}/news-list`,
        {}
      );

      console.warn(response.data.dtat);
      const res = response.data.data;
      setUserNews(res);
      setBaseUrl("https://sportspad.com/Admin/public/admin/uploads/news");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const playGame2www = async () => {
    try {
      const response = await axios(
        // (`${process.env.REACT_APP_API_URL}/home.php`)
        `${process.env.REACT_APP_API_URLWWW}/news-list`,
        {}
      );

      console.warn(response.data.dtat);
      const res = response.data.data;
      setUserNews(res);
      setBaseUrl("https://www.sportspad.com/Admin/public/admin/uploads/news");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getNewsId = (url) => {
    const match = url.match(
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    return match && match[1];
  };
  const getThumbnailUrlnews = (url) => {
    const videoId = getNewsId(url);
    return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : "";
  };
  const playVideo = async (link, id, title) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/video-single?video_id=${link}`
      );
      const videoDetails = response.data.data;

      // console.log('Playing video:', videoDetails);

      navigate(`/Video_Detail`);
      localStorage.setItem("playvideo", link);
    } catch (error) {
      console.error("Error fetching video data:", error);
    }
  };
  const playVideowww = async (link, id, title) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URLWWW}/video-single?video_id=${link}`
      );
      const videoDetails = response.data.data;

      // console.log('Playing video:', videoDetails);

      navigate(`/Video_Detail`);
      localStorage.setItem("playvideo", link);
    } catch (error) {
      console.error("Error fetching video data:", error);
    }
  };
  const playNews = async (link, id, title) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/video-single?video_id=${link}`
      );
      const NewsDetails = response.data.data;

      // console.log('Showing news:', NewsDetails);

      // navigate(`/News`);
      localStorage.setItem("playNews", link);
    } catch (error) {
      console.error("Error fetching video data:", error);
    }
  };
  const playNewswww = async (link, id, title) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URLWWW}/video-single?video_id=${link}`
      );
      const NewsDetails = response.data.data;

      // console.log('Showing news:', NewsDetails);

      // navigate(`/News`);
      localStorage.setItem("playNews", link);
    } catch (error) {
      console.error("Error fetching video data:", error);
    }
  };
  const handleClick = (slug, name) => {
  
    window.location.href = `/News_Detail?id=${slug}`;
  };
  return (
    <div>
      <>
        <Row>
          <Col md={12}>
            {/* ........................................Latest News side .................. */}
            <div className="newssection">
              {UserNews.slice(0, 1).map((set, setIndex) => (
                <div className="latestnewssec"onClick={() => {handleClick(set.slug);}}>
                  <div className="headingnews">Latest News</div>
                  <div className="latestnewssection">
                    <img
                      // onClick={() => playNews(set.video_link)}
                      // src={set.image || latestnews}
                      src={`${baseUrlnews}/${set.image}`}
                      alt={`Thumbnail `}
                    />
                  </div>
                  <span className="align-item-center">
                  <i class="fa fa-calendar" aria-hidden="true"></i>{dateFormat(set.updated_at, "mmmm dS, yyyy")}

                  </span>
                  {/* <Link to={`/News/${set.title}`} className=""></Link> */}
                 
                  <h1 onClick={() => {handleClick(set.slug);}}> {set.title}</h1>
                </div>
              ))}

              {UserNews.slice(3, 5).map((set, setIndex) => (
                <div className="newssection2" onClick={() => {handleClick(set.slug);}}>
                  <div className="d-flex align-items-center">
                    <div className="imagenews">
                      <img
                        // src={getThumbnailUrlnews(set.video_link)}
                          src={`${baseUrlnews}/${set.image}`}
                        alt={`Thumbnail`}
                      />
                    </div>
                    <div className="linkdiv">
                      <span className="align-item-center">
                      <i class="fa fa-calendar" aria-hidden="true"></i> {dateFormat(set.updated_at, "mmmm dS, yyyy")}
                      </span>
                      <h1 onClick={() => {handleClick(set.slug);}} className="text-dark">
                       {set.title}
                      </h1>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* ........................................Latest Video side .................. */}
            <div className="newssection">
              {usersvideo.slice(1, 2).map((set, setIndex) => (
                <div className="latestnewssec">
                  <div className="headingnews">Latest Video</div>
                  <div className="latestnewssection ">
                  {getThumbnailUrl(set.link) ? (
                        <img
                        onClick={() => { playVideo(set.link); playVideowww(set.link); }}
                          src={getThumbnailUrl(set.link)}
                          alt={`Thumbnail `}
                        />
                      ) : (
                        <iframe
                          width="360"
                          height="150"
                          src={getThumbnailUrl(
                            "https://www.youtube.com/embed/vdNrPdeEuYQ?autoplay=0"
                          )}
                          
                          allowFullScreen
                          title={set.title}
                        ></iframe>
                      )}
                  </div>
                  <div className="imagedata">
                    <span className="align-item-center">
                    <i class="fa fa-calendar" aria-hidden="true"></i> <span className="mx-1">{dateFormat(set.updated_at, "mmmm dS, yyyy")}</span>
                    </span>
                    <Link to="/Video_Detail" className="text-dark ">
                      <p  onClick={() => { playVideo(set.link); playVideowww(set.link); }}>{set.title}</p>
                    </Link>
                  </div>
                </div>
              ))}

              {usersvideo.slice(1, 5).map((set, setIndex) => (
                <div className="newssection2">
                  <div className="d-flex align-items-center">
                    <div className="imagenews ">
                      {getThumbnailUrl(set.link) ? (
                        <img
                        onClick={() => { playVideo(set.link); playVideowww(set.link); }}
                          src={getThumbnailUrl(set.link)}
                          alt={`Thumbnail `}
                        />
                      ) : (
                        <iframe
                          width="360"
                          height="150"
                          src={getThumbnailUrl(
                            "https://www.youtube.com/embed/vdNrPdeEuYQ?autoplay=0"
                          )}
                         
                          allowFullScreen
                          // title={set.title}
                        ></iframe>
                      )}
                    </div>
                    <div className="linkdiv">
                      <span className="align-item-center">
                      <i class="fa fa-calendar" aria-hidden="true"></i> <span className="mx-1">{dateFormat(set.updated_at, "mmmm dS, yyyy")}</span>
                      </span>
                      <Link to="/Video_Detail" className="text-dark">
                        <p  onClick={() => { playVideo(set.link); playVideowww(set.link); }}>{set.title}</p>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </>
    </div>
  );
}
